import { Outlet } from "react-router-dom";
import LeftMenu from "../LeftMenu";
import AppBar from "../AppBar";
import React from "react";
import { useMainContext } from "../../contexts/MainContext";

const Layout = () => {
  const { state, setState } = useMainContext();

  return (
    <div>
      <LeftMenu />
      <div className="main-panel">
        <AppBar />
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
