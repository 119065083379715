import { get, post, put, deleteData } from "./core/Requests";

export const getBranchTypes = async () => {
  return await get(`/BranchType`);
};

// export const getBranch = async (id) => {
//   return await get(`/Branch/${id}`);
// };

// export const addBranch = async (data) => {
//   return await post(`/Branch`, data);
// };

// export const updateBranch = async (data) => {
//     return await put(`/Branch`, data);
//   };

// export const deleteBranch = async (id) => {
//   return await deleteData(`/Branch/${id}`);
// };
