import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as postService from "./../../services/PostService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export default function Index() {
  const { state, setState } = useMainContext();
  const [posts, setPosts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getPosts(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getPosts = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await postService.getPosts(pageIndex, pageSize);
    if (result.success) {
      setPosts(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deletePost = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await postService.deletePost(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getPosts(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idPost) => {
    let result = await postService.changeActive(idPost);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getPosts(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getPosts(pageIndex, localStorage.rowCount);
  };



  return (
    <div className="col-md-12">
            <TawkMessengerReact propertyId="65ca756f0ff6374032cc2eba" widgetId="1hmfdlfk0" />

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">contact_support</i>
        </div>
        <div className="card-content">
          <div>
            <h4 className="card-title">Onlayn dəstək</h4>
            {/* <iframe width="100%" height="100%" src="https://tawk.to/chat/64dd205a94cf5d49dc6ace01/1h7vscojt"></iframe> */}
          </div>
        </div>
      </div>
    </div>
  );
}
