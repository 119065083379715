import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as debtCalculationTrainerService from "../../../services/DebtCalculationTrainerService";
import * as groupService from "../../../services/GroupService";
import * as contractService from "../../../services/ContractService";
import * as Alerts from "../../../utils/Alerts";
import Select from "../../../components/select/Index";
import FormInput from "../../../components/formInput/Index";
import FormTextareaInput from "../../../components/formTextareaInput/Index";
import FormCheckbox from "../../../components/formCheckbox/Index";
import { useOrganization } from "../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "../../../components/commitButton/Index";
import * as date from "../../../utils/DateNow";

export default function AddModal({ isOpen, handleViewModal, trainerId, selectedDebt }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [trainerDebtCalculation, setTrainerDebtCalculation] = useState({ calculationDate: date.Now() });
  const [trainerContracts, setTrainerContracts] = useState([]);

  const alias = useOrganization();
  //const id = useTrainer();
  const location = useLocation();
  const navigate = useNavigate();
  const { idGroupTrainingPlan } = useParams();

  useEffect(() => {
    getTrainerContracts();
    if (selectedDebt != null && selectedDebt != undefined) {
      selectedDebt.fkIdContractTrainer =selectedDebt?.contractTrainer?.idContractTrainer;
      setTrainerDebtCalculation(selectedDebt);
    }
  }, []);

  const getTrainerContracts = async () => {
    let result = await contractService.getContractTrainersByTrainer(0, 0, trainerId);
    if (result.success) {
      setTrainerContracts(result.data?.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const addDebtCalculationTrainer = async () => {
    trainerDebtCalculation.fkIdTrainer = trainerId;
    if (selectedDebt != null && selectedDebt != undefined) {
    await debtCalculationTrainerService.updateDebtCalculationTrainer({ ...trainerDebtCalculation, fkIdBranch: localStorage.branch });
    } else{
      await debtCalculationTrainerService.addDebtCalculationTrainer({ ...trainerDebtCalculation, fkIdBranch: localStorage.branch });
    }
    handleViewModal();
  };

  const handleInput = (e) => {
    setTrainerDebtCalculation({ ...trainerDebtCalculation, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setTrainerDebtCalculation({ ...trainerDebtCalculation, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Yeni təlimçi borcu
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <Select placeholder={"Seç"} style={"col-sm-12"} label={"Təlimçi müqaviləsi"} required={true} selectedValue={trainerDebtCalculation?.fkIdContractTrainer} changeSelectHandler={(e) => handleSelect(e, "fkIdContractTrainer")} options={trainerContracts.map((item) => ({ label: item?.contractNo, value: item?.idContractTrainer }))} isDisabled={isView} />
                  <FormInput style={"col-sm-6"} type={"number"} label={"Borc"} name={"debt"} required={true} value={trainerDebtCalculation?.debt} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Borc tarixi"} required={true} name={"calculationDate"} type={"date"} value={trainerDebtCalculation?.calculationDate} onChange={handleInput} disabled={isView} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addDebtCalculationTrainer} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
