import React from "react";
import * as util from "../../utils/Util";

export default function TrTrainerReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.fullName}</td>
      <td>{item?.contractCount}</td>
      <td>{item?.activeContractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalDetailCount}</td>
      <td>{item?.debt}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.groupName}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.groupPlanDays}</td>
      <td>{item?.groupPlanCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalParticipatedCount}</td>
      <td>{item?.trainingMaterialCount}</td>
    </tr>
  ) : idReport == 6 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.fullName}</td>
      {/* <td>{item?.contractCount}</td> */}
      <td>{item?.activeContractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.debt}</td>
    </tr>
  ) : idReport == 5 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.trainerFullName}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.trainingCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalParticipateCount}</td>
    </tr>
  ) : idReport == 7 ? (
    <>
      <tr style={{ backgroundColor: "white" }}>
        <td rowSpan={item?.groups?.reduce((acc, item) => acc + item?.sumStudent, 0) + item?.groups?.length + 2}>{i}</td>
        <td rowSpan={item?.groups?.reduce((acc, item) => acc + item?.sumStudent, 0) + item?.groups?.length + 2}>{item?.trainerFullName}</td>
        {/* <td rowSpan={item?.groups?.length}>{item?.groupName}</td> */}
        {/* <td rowSpan={item?.groups?.reduce((acc, item) => acc + item?.sumStudent, 0)}>{item?.groupName}</td> */}
        {/* <td>{item?.groups[0].groupName}</td> */}
        {/* <td>{item?.groups[0]?.groupName}</td>
        <td>{item?.groups[0]?.students[0]?.studentFullName}</td>
        <td>{item?.groups[0]?.students[0]?.debtCalculation}</td>
        <td>{item?.groups[0]?.students[0]?.paymentSum}</td>
        <td>{item?.groups[0]?.students[0]?.debt}</td> */}
      </tr>
      {item?.groups?.map((gr, i) => (
        <>
          {gr?.students?.map((stu, j) => (
            <tr>
              {j == 0 && (
                <td style={{ backgroundColor: "white" }} rowSpan={gr.sumStudent + 1}>
                  {gr?.groupName}
                </td>
              )}
              <td>{stu?.studentFullName}</td>
              <td>{stu?.debtCalculation}</td>
              <td>{stu?.paymentSum}</td>
              <td>{stu?.debt}</td>
            </tr>
          ))}
          <tr style={{ backgroundColor: "#F0EFEFFF", fontWeight: "bold", fontStyle: "italic" }}>
            <td>Cəm</td>
            <td>{util.FixedNumber(gr?.students?.reduce((stus, item) => stus + item?.debtCalculation, 0))}</td>
            <td>{util.FixedNumber(gr?.students?.reduce((stus, item) => stus + item?.paymentSum, 0))}</td>
            <td>{util.FixedNumber(gr?.students?.reduce((stus, item) => stus + item?.debt, 0))}</td>
          </tr>
        </>
      ))}
      <tr style={{ backgroundColor: "#E4E4E4FF", fontWeight: "bold", fontStyle: "italic" }}>
        <td rowSpan={item?.sumStudent + 1}>Cəm</td>
        <td></td>
        <td>{util.FixedNumber(item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.debtCalculation, 0), 0))}</td>
        <td>{util.FixedNumber(item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.paymentSum, 0), 0))}</td>
        <td>{util.FixedNumber(item?.groups?.reduce((sumG, itemG) => sumG + itemG?.students?.reduce((sumS, itemS) => sumS + itemS?.debt, 0), 0))}</td>
      </tr>
    </>
  ) : null;
}
