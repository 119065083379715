import React, { useState, useEffect } from "react";
import FormInput from "./../../../components/formInput/Index";
import * as incomeService from "./../../../services/IncomeService";
import { PAGE_INDEX, PAGE_SIZE } from "./../../../constants/Pagination";
import Pagination from "./../../../components/pagination/Index";
import * as Alerts from "./../../../utils/Alerts";
import Loading from "./../../../components/loading/Index";
import { useTokenData } from "../../../helper/useTokenData";
import { Link } from "react-router-dom";
import { useOrganization } from "../../../helper/useOrganization";
import { useMainContext } from "./../../../contexts/MainContext";
import NotFound from "./../../../components/notfound/Index";

export default function SelectModal({ isOpen, handleIncomeSelectModal, setSelectedIncome, organizationId }) {
  const { state, setState } = useMainContext();

  const [incomes, setIncomes] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const tokenData = useTokenData();
  const alias = useOrganization();

  useEffect(() => {
    getIncomes(PAGE_INDEX, localStorage.rowCount);
  }, [isOpen]);

  const getIncomes = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await incomeService.getIncomes(pageIndex, pageSize);
    if (result.success) {
      setIncomes(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const isSelectRow = (rowId, description) => {
    rowId == selectedRow?.rowId ? setSelectedRow(null) : setSelectedRow({ rowId, description });
  };
  const changePage = (pageIndex) => {
    getIncomes(pageIndex, localStorage.rowCount);
  };

  const handleAction = () => {
    setSelectedIncome(selectedRow);
    handleIncomeSelectModal();
  };

  return (
    <div className={isOpen ? "modal  m-block" : "modal"} role="dialog" aria-labelledby="selectEmployeeModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Əlavə gəlir adı seç
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="card">
                <div className="card-content">
                  {loading ? (
                    <Loading />
                  ) : incomes?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>#</th>
                            <th>Əlavə gəlir adı</th>
                          </tr>
                        </thead>
                        <tbody>
                          {incomes.map((item) => (
                            <tr key={item?.idIncome} onClick={() => isSelectRow(item?.idIncome, item?.description)} className={selectedRow?.rowId === item?.idIncome ? "selectedRow" : ""}>
                              <td>
                                <button
                                  style={{ margin: 0 }}
                                  className="btn btn-fill btn-success btn-select"
                                  onClick={() => {
                                    setSelectedIncome({ rowId: item?.idIncome, description: item?.description });
                                    handleIncomeSelectModal();
                                  }}
                                >
                                  Seç
                                </button>
                              </td>
                              <td>{item?.idIncome}</td>
                              <td>{item?.description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={handleAction}>
              Seç
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleAction}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
