import React from "react";
import { Link } from "react-router-dom";

export default function HeadCardItem({ icon, header, count, url, theme, desc }) {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-stats" data-background-color={theme}>
        <div className="card-header" data-background-color={theme}>
          <i className="material-icons">{icon}</i>
        </div>
        <div className="card-content">
          <p className="category">{header}</p>
          <h3 className="card-title">{count}</h3>
        </div>
        <div className="card-footer">
          <div className="stats">
            <i className="material-icons text-warning">info</i>
            <Link to={url}>
              <a href="javascript:void(0)"> {desc}...</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
