import React from "react";
import * as util from "../../utils/Util";

export default function TrIncomeReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.income}</td>
      <td>{item?.count}</td>
      <td>{util.FixedNumber(item?.sum)}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.income}</td>
      <td>{item?.financeOperationDate?.split("T")[0]}</td>
      <td>{item?.financeOperationNo}</td>
      <td>{util.FixedNumber(item?.amount)}</td>
      <td>{item?.note}</td>
      <td>{item?.userName}</td>
      <td>{item?.insertDate?.split("T")[0]}</td>

    </tr>
  ) : null;
}
