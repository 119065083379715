import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as organizationPaymentService from "./../../../services/OrganizationPaymentService";
import * as organizationService from "./../../../services/OrganizationService";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import Select from "./../../../components/select/Index";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [organizationPayment, setOrganizationPayment] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { idOrganizationPayment } = useParams();

  useEffect(() => {
    getOrganizations();
    idOrganizationPayment && getOrganizationPayment();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getOrganizationPayment = async () => {
    let result = await organizationPaymentService.getOrganizationPayment(idOrganizationPayment);
    if (result.success) {
      setOrganizationPayment(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateOrganizationPayment = async () => {
    let result = idOrganizationPayment ? await organizationPaymentService.updateOrganizationPayment(organizationPayment) : await organizationPaymentService.addOrganizationPayment(organizationPayment);
    if (result.success) {
      Alerts.successWithBack(result.message, `/organizationPayment`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const getOrganizations = async () => {
    let orgs = await organizationService.getOrganizationForSelect();
    if (orgs.success) {
      setOrganizations(orgs.data);
    } else {
      Alerts.error(orgs.message);
    }
  };

  const handleInput = (e) => {
    setOrganizationPayment({ ...organizationPayment, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setOrganizationPayment({ ...organizationPayment, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">payments</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idOrganizationPayment ? (isView ? "Təşkilat ödənişi məlumatları" : "Təşkilat ödənişi məlumatlarının redaktəsi") : "Yeni təşkilat ödənişi"}</h4>
                <form method="#" action="#">
                  <div className="row">
                    <Select placeholder={"Seç"} style={"col-sm-12"} label={"Təşkilatın adı"} selectedValue={organizationPayment?.fkIdOrganization} changeSelectHandler={(e) => handleSelect(e, "fkIdOrganization")} options={organizations?.map((org) => ({ label: org.organizationName, value: org.idOrganization }))} isDisabled={isView} />
                    <FormInput style={"col-sm-12"} label={"Ödəniş"} name={"paid"} type={"number"} value={organizationPayment?.paid} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-12"} label={"Qeyd"} name={"note"} value={organizationPayment?.note} onChange={handleInput} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && (
                     <CommitButton onClick={addOrUpdateOrganizationPayment} />
                    )}
                    <Link to={`/organizationPayment`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
