import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as authService from "./../../services/AuthService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [userLogs, setUserLogs] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  useEffect(() => {
    getUserLogs(state.currentPage, localStorage.rowCount);
  }, []);

  const getUserLogs = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await authService.getUserLogs(pageIndex, pageSize);
    if (result.success) {
      setUserLogs(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getUserLogs(pageIndex, localStorage.rowCount);
  };


  return (
    <div className="col-md-12">
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">people_alt</i>
        </div>
        <div className="card-content">
          <div>
            <h4 className="card-title">İstifadəçi loqlarının siyahısı</h4>
            <PageSizeSelect onChange={(e)=> getUserLogs(PAGE_INDEX, e.value)} />
          </div>
          {loading ? (
            <Loading />
          ) : userLogs?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Təşkilat</th>
                    <th>İstifadəçi adı</th>
                    <th>İP</th>
                    <th>Agent</th>
                    <th>Tarix</th>
                  </tr>
                </thead>
                <tbody>
                  {userLogs.map((item, i) => (
                    <tr key={item?.idUserLoginLog}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.organizationName}</td>
                      <td>{item?.userName}</td>
                      <td>{item?.ip}</td>
                      <td>{item?.userAgent}</td>
                      <td>{item?.date?.replace("T", " ").split(".")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
