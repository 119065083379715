import React from "react";
import { useState } from "react";
import Loading from "react-loading";
import * as Alerts from "./../../utils/Alerts";

export default function Index({ onClick, text = "Təsdiq et", disabled = false }) {
  const [isLoadingClick, setIsLoadingClick] = useState(false);

  const onClickButton = async () => {
    let labels = document.querySelectorAll(".required-red");
    if (labels.length <= 0) {
      setIsLoadingClick(true);
      await onClick();
      setIsLoadingClick(false);
    } else {
      Alerts.warning("Zəhmət olmasa vacib xanaları doldurun")
    }
  };

  return (
    <button type="button" disabled={isLoadingClick || disabled} className="btn btn-fill btn-success" onClick={onClickButton}>
      <div style={{ display: "inline-flex" }}>
        <span>{text}</span>
        {isLoadingClick && <Loading type={"spin"} color={"white"} height={17} width={17} className="ml-10" />}
      </div>
    </button>
  );
}
