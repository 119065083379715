import React from "react";
import { Routes, Route } from "react-router-dom";
import { Router, Switch } from "react-router";

import Journal from "./../pages/journal/Index";
import JournalGeneral from "./../pages/journal/general/Index";
import StudentAttendance from "./../pages/journal/StudentIndex";
// import JournalEdit from "./../pages/journal/edit/Index";
// import JournalAdd from "./../pages/journal/add/Index";
import Dashboard from "./../pages/dashboard/Index";
import Organization from "./../pages/organization/Index";
import OrganizationGeneral from "./../pages/organization/general/Index";
// import OrganizationAdd from "./../pages/organization/add/Index";
// import OrganizationEdit from "./../pages/organization/edit/Index";
import OrganizationPayment from "./../pages/organizationPayment/Index";
import OrganizationPaymentGeneral from "./../pages/organizationPayment/general/Index";
import Branch from "./../pages/branch/Index";
import BranchGeneral from "./../pages/branch/general/Index";
import BranchSettings from "./../pages/branch/settings/Index";

// import BranchAdd from "./../pages/branch/add/Index";
// import BranchEdit from "./../pages/branch/edit/Index";
import SysUser from "./../pages/sysUser/Index";
import SysUserGeneral from "./../pages/sysUser/general/Index";
//import SysUserAdd from "./../pages/sysUser/add/Index";
import Student from "./../pages/student/Index";
import StudentGeneral from "./../pages/student/general/Index";
import FinanceOperation from "./../pages/financeOperation/Index";
import FinanceOperationGeneral from "./../pages/financeOperation/general/Index";
// import StudentAdd from "./../pages/student/add/Index";
// import StudentEdit from "./../pages/student/edit/Index";
import User from "./../pages/user/Index";
import UserGeneral from "./../pages/user/general/Index";
import UserPrivilage from "../pages/menuPrivilage/Index";
import UserBranchPrivilage from "../pages/branchPrivilage/Index";
//import UserAdd from "./../pages/user/add/Index";
//import UserEdit from "./../pages/user/edit/Index";
import Department from "./../pages/department/Index";
import DepartmentGeneral from "./../pages/department/general/Index";
import Post from "./../pages/post/Index";
import PostGeneral from "./../pages/post/general/Index";
// import PostAdd from "./../pages/post/add/Index";
import Room from "./../pages/room/Index";
import RoomGeneral from "./../pages/room/general/Index";
// import RoomAdd from "./../pages/room/add/Index";
// import RoomEdit from "./../pages/room/edit/Index";
import Expense from "./../pages/expense/Index";
import ExpenseGeneral from "./../pages/expense/general/Index";
// import ExpenseAdd from "./../pages/expense/add/Index";
// import ExpenseEdit from "./../pages/expense/edit/Index";
import Employee from "./../pages/employee/Index";
import EmployeeGeneral from "./../pages/employee/general/Index";
import Trainer from "./../pages/trainer/Index";
import TrainerGeneral from "./../pages/trainer/general/Index";

//import EmployeeAdd from "./../pages/employee/add/Index";
//import EmployeeEdit from "./../pages/employee/edit/Index";
import Group from "./../pages/group/Index";
import GroupGeneral from "./../pages/group/general/Index";
//import GroupAdd from "./../pages/group/add/Index";
//import GroupEdit from "./../pages/group/edit/Index";
import GroupMember from "./../pages/groupMember/Index";
import GroupMemberGeneral from "./../pages/groupMember/general/Index";
// import GroupMemberAdd from "./../pages/groupMember/add/Index";
// import GroupMemberEdit from "./../pages/groupMember/edit/Index";
import Income from "./../pages/income/Index";
import IncomeGeneral from "./../pages/income/general/Index";

import Application from "./../pages/application/Index";
import ApplicationGeneral from "./../pages/application/general/Index";

import Calendar from "./../pages/calendar/Index";

import StudentReport from "./../pages/report/student/Index";
import StudentReportView from "./../pages/report/student/View";

import EmployeeReport from "./../pages/report/employee/Index";
import EmployeeReportView from "./../pages/report/employee/View";

import TrainerReport from "./../pages/report/trainer/Index";
import TrainerReportView from "./../pages/report/trainer/View";

import IncomeReport from "./../pages/report/income/Index";
import IncomeReportView from "./../pages/report/income/View";

import ExpenseReport from "./../pages/report/expense/Index";
import ExpenseReportView from "./../pages/report/expense/View";

import CaseReport from "./../pages/report/case/Index";
import CaseReportView from "./../pages/report/case/View";

import AppealReport from "./../pages/report/appeal/Index";
import AppealReportView from "./../pages/report/appeal/View";

import JuridicalReport from "./../pages/report/juridical/Index";
import JuridicalReportView from "./../pages/report/juridical/View";

import GroupReport from "./../pages/report/group/Index";
import GroupReportView from "./../pages/report/group/View";

import Review from "./../pages/review/Index";
import ReviewGeneral from "./../pages/review/general/Index";

import Assignment from "./../pages/assignment/Index";
import AssignmentGeneral from "./../pages/assignment/general/Index";

import TrainingMaterial from "./../pages/trainingMaterial/Index";
import TrainingMaterialGeneral from "./../pages/trainingMaterial/general/Index";

import TrainingType from "./../pages/trainingType/Index";
import TrainingTypeGeneral from "./../pages/trainingType/general/Index";

import ContractStudent from "./../pages/contract/student/Index";
import ContractStudentGeneral from "./../pages/contract/student/general/Index";

import ContractTrainer from "./../pages/contract/trainer/Index";
import ContractTrainerGeneral from "./../pages/contract/trainer/general/Index";

import ContractEmployee from "./../pages/contract/employee/Index";
import ContractEmployeeGeneral from "./../pages/contract/employee/general/Index";

import ContractJuridical from "./../pages/contract/juridical/Index";
import ContractJuridicalGeneral from "./../pages/contract/juridical/general/Index";

import Case from "./../pages/case/Index";
import CaseGeneral from "./../pages/case/general/Index";

import JuridicalPerson from "./../pages/juridicalPerson/Index";
import JuridicalPersonGeneral from "./../pages/juridicalPerson/general/Index";

import GroupTrainingPlan from "./../pages/groupTrainingPlan/Index";
import GroupTrainingPlanGeneral from "./../pages/groupTrainingPlan/general/Index";

import Exam from "./../pages/exam/Index";
import ExamGeneral from "./../pages/exam/general/Index";

import Notification from "./../pages/notification/Index";

import UseRules from "./../pages/useRules/Index";

import Download from "./../pages/download/Index";

import UserLog from "./../pages/userLog/Index";

import OnlineChat from "./../pages/onlineChat/index";

import Login from "./../pages/login/Index";
import AdminLogin from "./../pages/adminLogin/Index";
import NotFound from "./../pages/notfound/Index";

import PrivateRoute from "./PrivateRoute";
import PrivateAdminRoute from "./PrivateAdminRoute";
import { useOrganization } from "../helper/useOrganization";
import { useStudent } from "../helper/useStudent";
import Layout from "../containers/layout/Index";
import * as UserTypes from "./../constants/UserType";

export default function Index() {
  const org = useOrganization();
  const id = useStudent();

  return (
    <Routes>
      {/* <Route path="/journal" element={<Journal />} /> */}

      {/* <Route path="/organization" element={<Organization />} /> */}
      <Route path="/" element={<Layout />}>
        <Route path="/branch/:idOrganization" element={<Branch />} />
        <Route path={`/branch/add/:idOrganization`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/branch/edit/:idBranch/:idOrganization`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/branch/view/:idBranch/:idOrganization`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/branch/settings`} element={<PrivateRoute children={<BranchSettings />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`/organization/add`} element={<PrivateAdminRoute children={<OrganizationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/organization/edit/:idOrganization`} element={<PrivateAdminRoute children={<OrganizationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/organization/view/:idOrganization`} element={<PrivateAdminRoute children={<OrganizationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`/sysuser`} element={<PrivateAdminRoute children={<SysUser />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/sysuser/add`} element={<PrivateAdminRoute children={<SysUserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/sysuser/edit/:idSysUser`} element={<PrivateAdminRoute children={<SysUserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/sysuser/view/:idSysUser`} element={<PrivateAdminRoute children={<SysUserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`/organizationPayment`} element={<PrivateAdminRoute children={<OrganizationPayment />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/organizationPayment/add`} element={<PrivateAdminRoute children={<OrganizationPaymentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/organizationPayment/edit/:idOrganizationPayment`} element={<PrivateAdminRoute children={<OrganizationPaymentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`/organizationPayment/view/:idOrganizationPayment`} element={<PrivateAdminRoute children={<OrganizationPaymentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`/userLog`} element={<PrivateAdminRoute children={<UserLog />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        {/* <Route path={`${org}`} element={<PrivateRoute children={<Test />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]}  />} /> */}

        <Route path={`${org}${id}/dashboard`} element={<PrivateRoute children={<Dashboard />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/organization`} element={<PrivateRoute children={<Organization />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/organization/view/:idOrganization`} element={<PrivateRoute children={<OrganizationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/branch`} element={<PrivateRoute children={<Branch />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        {/* <Route path={`${org}/branch/:idOrganization`} element={<PrivateRoute children={<Branch />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]}  />} /> */}
        <Route path={`${org}/branch/add`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/branch/edit/:idBranch`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/branch/view/:idBranch`} element={<PrivateRoute children={<BranchGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/user`} element={<PrivateRoute children={<User />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/user/add`} element={<PrivateRoute children={<UserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/user/edit/:idUser`} element={<PrivateRoute children={<UserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/user/view/:idUser`} element={<PrivateRoute children={<UserGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/user/privilage/:idUser`} element={<PrivateRoute children={<UserPrivilage />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/user/branchPrivilage/:idUser/:idOrganization`} element={<PrivateRoute children={<UserBranchPrivilage />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/department`} element={<PrivateRoute children={<Department />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/department/add`} element={<PrivateRoute children={<DepartmentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/department/edit/:idDepartment`} element={<PrivateRoute children={<DepartmentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/department/view/:idDepartment`} element={<PrivateRoute children={<DepartmentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/post`} element={<PrivateRoute children={<Post />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/post/add`} element={<PrivateRoute children={<PostGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/post/edit/:idPost`} element={<PrivateRoute children={<PostGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/post/view/:idPost`} element={<PrivateRoute children={<PostGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/room`} element={<PrivateRoute children={<Room />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/room/add`} element={<PrivateRoute children={<RoomGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/room/edit/:idRoom`} element={<PrivateRoute children={<RoomGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/room/view/:idRoom`} element={<PrivateRoute children={<RoomGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/expense`} element={<PrivateRoute children={<Expense />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/expense/add`} element={<PrivateRoute children={<ExpenseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/expense/edit/:idExpense`} element={<PrivateRoute children={<ExpenseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/expense/view/:idExpense`} element={<PrivateRoute children={<ExpenseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/employee`} element={<PrivateRoute children={<Employee />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/employee/add`} element={<PrivateRoute children={<EmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/employee/edit/:idEmployee`} element={<PrivateRoute children={<EmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/employee/view/:idEmployee`} element={<PrivateRoute children={<EmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/trainer`} element={<PrivateRoute children={<Trainer />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainer/add`} element={<PrivateRoute children={<TrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainer/edit/:idEmployee`} element={<PrivateRoute children={<TrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainer/view/:idEmployee`} element={<PrivateRoute children={<TrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/student`} element={<PrivateRoute children={<Student />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/student/add`} element={<PrivateRoute children={<StudentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/student/edit/:idStudent`} element={<PrivateRoute children={<StudentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/student/view/:idStudent`} element={<PrivateRoute children={<StudentGeneral />} privilege={"student/detailinfo"} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/group`} element={<PrivateRoute children={<Group />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/group/add`} element={<PrivateRoute children={<GroupGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/group/edit/:idGroup`} element={<PrivateRoute children={<GroupGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/group/view/:idGroup`} element={<PrivateRoute children={<GroupGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/groupMember`} element={<PrivateRoute children={<GroupMember />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/groupMember/add`} element={<PrivateRoute children={<GroupMemberGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/groupMember/edit/:idGroupMember`} element={<PrivateRoute children={<GroupMemberGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/groupMember/view/:idGroupMember`} element={<PrivateRoute children={<GroupMemberGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/journal`} element={<PrivateRoute children={<Journal />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/journal/add`} element={<PrivateRoute children={<JournalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/journal/edit/:idJournal`} element={<PrivateRoute children={<JournalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/journal/view/:idJournal`} element={<PrivateRoute children={<JournalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/journal/attendance`} element={<PrivateRoute children={<StudentAttendance />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/income`} element={<PrivateRoute children={<Income />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/income/add`} element={<PrivateRoute children={<IncomeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/income/edit/:idIncome`} element={<PrivateRoute children={<IncomeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/income/view/:idIncome`} element={<PrivateRoute children={<IncomeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/financeOperation/:idFinanceOperationType`} element={<PrivateRoute children={<FinanceOperation />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/financeOperation/:idFinanceOperationType/add`} element={<PrivateRoute children={<FinanceOperationGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/financeOperation/:idFinanceOperationType/edit/:idFinanceOperation`} element={<PrivateRoute children={<FinanceOperationGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/financeOperation/:idFinanceOperationType/view/:idFinanceOperation`} element={<PrivateRoute children={<FinanceOperationGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/review`} element={<PrivateRoute children={<Review />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/review/add`} element={<PrivateRoute children={<ReviewGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/review/edit/:idReview`} element={<PrivateRoute children={<ReviewGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/review/view/:idReview`} element={<PrivateRoute children={<ReviewGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/groupTrainingPlan`} element={<PrivateRoute children={<GroupTrainingPlan />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/groupTrainingPlan/add`} element={<PrivateRoute children={<GroupTrainingPlanGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/groupTrainingPlan/edit/:idGroupTrainingPlan`} element={<PrivateRoute children={<GroupTrainingPlanGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/groupTrainingPlan/view/:idGroupTrainingPlan`} element={<PrivateRoute children={<GroupTrainingPlanGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/assignment`} element={<PrivateRoute children={<Assignment />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/assignment/add`} element={<PrivateRoute children={<AssignmentGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/assignment/edit/:idAssignment`} element={<PrivateRoute children={<AssignmentGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/assignment/view/:idAssignment`} element={<PrivateRoute children={<AssignmentGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/trainingMaterial`} element={<PrivateRoute children={<TrainingMaterial />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/trainingMaterial/add`} element={<PrivateRoute children={<TrainingMaterialGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/trainingMaterial/edit/:idTrainingMaterial`} element={<PrivateRoute children={<TrainingMaterialGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/trainingMaterial/view/:idTrainingMaterial`} element={<PrivateRoute children={<TrainingMaterialGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/trainingType`} element={<PrivateRoute children={<TrainingType />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainingType/add`} element={<PrivateRoute children={<TrainingTypeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainingType/edit/:idTrainingType`} element={<PrivateRoute children={<TrainingTypeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/trainingType/view/:idTrainingType`} element={<PrivateRoute children={<TrainingTypeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/application`} element={<PrivateRoute children={<Application />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/application/add`} element={<PrivateRoute children={<ApplicationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/application/edit/:idApplication`} element={<PrivateRoute children={<ApplicationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/application/view/:idApplication`} element={<PrivateRoute children={<ApplicationGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}${id}/contract/student`} element={<PrivateRoute children={<ContractStudent />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/student/add`} element={<PrivateRoute children={<ContractStudentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/student/edit/:idContractStudent`} element={<PrivateRoute children={<ContractStudentGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}${id}/contract/student/view/:idContractStudent`} element={<PrivateRoute children={<ContractStudentGeneral />} accesses={[id != "" && UserTypes.STUDENT_USER, UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/contract/juridical`} element={<PrivateRoute children={<ContractJuridical />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/juridical/add`} element={<PrivateRoute children={<ContractJuridicalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/juridical/edit/:idContractJuridical`} element={<PrivateRoute children={<ContractJuridicalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/juridical/view/:idContractJuridical`} element={<PrivateRoute children={<ContractJuridicalGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/contract/trainer`} element={<PrivateRoute children={<ContractTrainer />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/trainer/add`} element={<PrivateRoute children={<ContractTrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/trainer/edit/:idContractTrainer`} element={<PrivateRoute children={<ContractTrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/trainer/view/:idContractTrainer`} element={<PrivateRoute children={<ContractTrainerGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/contract/employee`} element={<PrivateRoute children={<ContractEmployee />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/employee/add`} element={<PrivateRoute children={<ContractEmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/employee/edit/:idContractEmployee`} element={<PrivateRoute children={<ContractEmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/contract/employee/view/:idContractEmployee`} element={<PrivateRoute children={<ContractEmployeeGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/case`} element={<PrivateRoute children={<Case />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/case/add`} element={<PrivateRoute children={<CaseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/case/edit/:idCase`} element={<PrivateRoute children={<CaseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/case/view/:idCase`} element={<PrivateRoute children={<CaseGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/juridical`} element={<PrivateRoute children={<JuridicalPerson />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/juridical/add`} element={<PrivateRoute children={<JuridicalPersonGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/juridical/edit/:idJuridicalPerson`} element={<PrivateRoute children={<JuridicalPersonGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/juridical/view/:idJuridicalPerson`} element={<PrivateRoute children={<JuridicalPersonGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/exam`} element={<PrivateRoute children={<Exam />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/exam/add`} element={<PrivateRoute children={<ExamGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/exam/edit/:idExam`} element={<PrivateRoute children={<ExamGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/exam/view/:idExam`} element={<PrivateRoute children={<ExamGeneral />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/notification`} element={<PrivateRoute children={<Notification />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/calendar`} element={<PrivateRoute children={<Calendar />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        {/* reports */}
        <Route path={`${org}/report/student`} element={<PrivateRoute children={<StudentReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/student/:idReport`} element={<PrivateRoute children={<StudentReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/employee`} element={<PrivateRoute children={<EmployeeReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/employee/:idReport`} element={<PrivateRoute children={<EmployeeReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/trainer`} element={<PrivateRoute children={<TrainerReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/trainer/:idReport`} element={<PrivateRoute children={<TrainerReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/case`} element={<PrivateRoute children={<CaseReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/case/:idReport`} element={<PrivateRoute children={<CaseReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/income`} element={<PrivateRoute children={<IncomeReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/income/:idReport`} element={<PrivateRoute children={<IncomeReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/expense`} element={<PrivateRoute children={<ExpenseReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/expense/:idReport`} element={<PrivateRoute children={<ExpenseReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/application`} element={<PrivateRoute children={<AppealReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/application/:idReport`} element={<PrivateRoute children={<AppealReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/juridical`} element={<PrivateRoute children={<JuridicalReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/juridical/:idReport`} element={<PrivateRoute children={<JuridicalReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/report/group`} element={<PrivateRoute children={<GroupReport />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />
        <Route path={`${org}/report/group/:idReport`} element={<PrivateRoute children={<GroupReportView />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/userules/`} element={<PrivateRoute children={<UseRules />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/download`} element={<PrivateRoute children={<Download />} accesses={[UserTypes.ORGANIZATION_USER, UserTypes.BRANCH_CHIEF_USER, UserTypes.BRANCH_USER, UserTypes.TRAINER_USER]} />} />

        <Route path={`${org}/chat`} element={<OnlineChat />} />
      </Route>

      <Route path={`:alias`} element={<Login />} />
      <Route path={`:alias/:studentId`} element={<Login />} />
      <Route path={`/admin/login`} element={<AdminLogin />} />
      <Route path={`/404`} element={<NotFound />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
