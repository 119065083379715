import React from "react";
import image404 from "./../../assets/img/404.png";

export default function Index() {
 // window.location = "https://e-edu.az";
  return (
    <div className="notfound">
      <img src={image404} />
      <label></label>
    </div>
  );
}
