import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as groupTrainingPlanService from "../../../services/GroupTrainingPlanService";
import * as applicationService from "../../../services/ApplicationService";
import * as helperService from "../../../services/HelperService";
import * as Alerts from "../../../utils/Alerts";
import Select from "../../../components/select/Index";
import FormInput from "../../../components/formInput/Index";
import FormTextareaInput from "../../../components/formTextareaInput/Index";
import CommitButton from "../../../components/commitButton/Index";
import * as date from "../../../utils/DateNow";

export default function AddCallBackModal({ isOpen, handleViewModal, applicationId }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [data, setData] = useState({ callBackDate: date.Now(), callBackTime: date.NowTime() });
  const [callBackResult, setCallbackResult] = useState([]);
  const [terminationReasions, setTerminationReasions] = useState([]);

  useEffect(() => {
    getCallBackResults();
  }, []);

  useEffect(() => {
    let result = callBackResult?.find((x) => x.idApplicationCallBackResult === data?.fkIdApplicationCallBackResult);
    console.log(result);
    console.log(callBackResult);
    console.log(data);

    if (result?.termination == 1) {
      getTerminationReasions();
    } else {
      setTerminationReasions([]);
      setData({ ...data, fkIdTerminationReasion: null });
    }
  }, [data?.fkIdApplicationCallBackResult]);

  const getCallBackResults = async () => {
    let result = await applicationService.getApplicationCallBackResults();
    if (result.success) {
      setCallbackResult(result?.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTerminationReasions = async () => {
    let result = await helperService.getTerminationReasions(1);
    if (result.success) {
      setTerminationReasions(result?.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const AddCallBack = async () => {
    var result = await applicationService.addApplicationCallBack({ ...data, fkIdBranch: localStorage.branch, fkIdApplication: applicationId });

    Alerts.success(result?.message);

    handleViewModal();
  };

  const handleInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setData({ ...data, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Əlaqə məlumatı
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <FormInput style={"col-sm-6"} label={"Tarix"} required={true} name={"callBackDate"} type={"date"} value={data?.callBackDate} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Saat"} name={"callBackTime"} required={true} value={data?.callBackTime} onChange={handleInput} disabled={isView} />
                  <Select placeholder={"Seç"} style={"col-sm-12"} label={"Status"} selectedValue={data?.fkIdApplicationCallBackResult} changeSelectHandler={(e) => handleSelect(e, "fkIdApplicationCallBackResult")} options={callBackResult.map((item) => ({ label: item?.description, value: item?.idApplicationCallBackResult }))} isDisabled={isView} />
                  <FormTextareaInput style={"col-sm-12"} rows={5} label={"Qeyd"} name={"note"} value={data?.note} onChange={handleInput} disabled={isView} />
                  {terminationReasions.length > 0 ? <Select placeholder={"Seç"} style={"col-sm-12"} label={"İmtina səbəbi"} selectedValue={data?.fkIdTerminationReasion} changeSelectHandler={(e) => handleSelect(e, "fkIdTerminationReasion")} options={terminationReasions.map((item) => ({ label: item?.description, value: item?.idTerminationReasion }))} isDisabled={isView} /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={AddCallBack} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
