import axios from "axios";
import { URL } from "./Urls";
import * as Alerts from "./../../utils/Alerts";

const TOKEN = "Bearer " + localStorage.token;

function headerAddToken(headers) {
  headers != null ? (headers.Authorization = TOKEN) : (headers = { Authorization: TOKEN });
  return headers;
}

async function handleResponse(response) {
  // console.log(response.data);
  return await response.data;
}

async function handleError(error) {
  if (error?.response?.status === 401) {
    Alerts.error401();
    setTimeout(() => {}, 2000);
    removeLocalItems();
    const url = window.location.origin; // 'http://localhost:3000'
    const path = window.location.pathname.split("/")[1]; // 'test'
    window.location = `${url}/${path}/login`; // 'http://localhost:3000/test'
    setTimeout(() => {}, 2000);
    return;
  }

  let message = "";
  if (error.response) {
    message = error.response.data.title;
    // Request made and server responded
  } else if (error.request) {
    message = error.request;
  } else {
    message = error.message;
  }

  return {
    data: null,
    success: false,
    message: message,
  };
}

export async function get(endpoint, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .get(URL.BaseURL + endpoint, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function getForReturnFile(endpoint, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .get(URL.BaseURL + endpoint, { responseType: "blob", headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function post(endpoint, data, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .post(URL.BaseURL + endpoint, data, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function postForReturnFile(endpoint, data, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .post(URL.BaseURL + endpoint, data, { responseType: "blob", headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function put(endpoint, data, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .put(URL.BaseURL + endpoint, data, { headers })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteData(endpoint, headData) {
  let headers = headerAddToken(headData);
  return await axios
    .delete(URL.BaseURL + endpoint, { headers })
    .then(handleResponse)
    .catch(handleError);
}

/*

          *********************          with toast         **************************

*/

function removeLocalItems() {
  localStorage.removeItem("token");
  localStorage.removeItem("rowCount");
  localStorage.removeItem("branch");
  localStorage.removeItem("logo");
  localStorage.removeItem("orgName");
  localStorage.removeItem("userName");
  localStorage.removeItem("userType");
  localStorage.removeItem("userId");
  localStorage.removeItem("user");
  localStorage.removeItem("page");
  localStorage.removeItem("studentId");
  localStorage.removeItem("employeeId");
  localStorage.removeItem("userTypeId");
  localStorage.removeItem("menuPrivilages");
}
