import { get, post, put, deleteData } from "./core/Requests";

export const getExpenses = async (pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Expense/getList`, filter, { pageIndex, pageSize });
};

// export const getExpenses = async (pageIndex = 0, pageSize = 0) => {
//   return await get(`/Expense`, { pageIndex, pageSize });
// };

export const getExpensesByOrganization = async ( pageIndex, pageSize) => {
  return await get(`/Expense/getByOrganization`, {
    pageIndex,
    pageSize,
  });
};

export const getExpensesByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Expense/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

export const getExpense = async (id) => {
  return await get(`/Expense/${id}`);
};

export const addExpense = async (data) => {
  return await post(`/Expense`, data);
};

export const updateExpense = async (data) => {
  return await put(`/Expense`, data);
};

export const deleteExpense = async (id) => {
  return await deleteData(`/Expense/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Expense/changeActive/${id}`);
};
