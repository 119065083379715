import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as branchService from "./../../services/BranchService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { useParams } from "react-router-dom";
import { useTokenData } from "../../helper/useTokenData";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [branchs, setBranchs] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();
  const tokenData = useTokenData();
  const { idOrganization } = useParams();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getBranchs(state.currentPage, localStorage.rowCount);
  }, []);

  const getBranchs = async (pageIndex, pageSize) => {
    setLoading(true);
    let branchs = tokenData.isSysUser == "1" && idOrganization != undefined ? await branchService.getBranchsByOrganizationForSysUser(pageIndex, pageSize, idOrganization) : await branchService.getBranchs(pageIndex, pageSize);
    if (branchs.success) {
      setBranchs(branchs.data.datas);
      setPagination({
        pageIndex: branchs.data.pageIndex,
        hasNextPage: branchs.data.hasNextPage,
        hasPreviousPage: branchs.data.hasPreviousPage,
        totalPageCount: branchs.data.totalPageCount,
        totalRecordCount: branchs.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(branchs.message);
    }
  };

  const deleteBranch = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let branch = await branchService.deleteBranch(id);
        if (branch.success) {
          setSelectedRow(0);
          Alerts.success(branch.message);
        } else {
          Alerts.error(branch.message);
        }
        getBranchs(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idBranch) => {
    let result = await branchService.changeActive(idBranch);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getBranchs(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getBranchs(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {/* {tokenData.isSysUser != "1" ? ( */}
      <>
        <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteBranch(selectedRow)}>
          Sil
        </button>
        <Link to={`${alias}/branch/edit/${selectedRow}/${idOrganization ? idOrganization : ""}`}>
          <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
            Redaktə et
          </button>
        </Link>
        <Link to={`${alias}/branch/add/${idOrganization ? idOrganization : ""}`}>
          <button className="btn btn-info f-right">Əlavə et</button>
        </Link>
      </>
      {/* ) : null} */}
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">domain</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Filialların siyahısı</h4>
          <PageSizeSelect onChange={(e) => getBranchs(PAGE_INDEX, e.value)} />

          {loading ? (
            <Loading />
          ) : branchs?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Filialın adı</th>
                    <th>Təşkilatın adı</th>
                    <th>Əlaqə nömrəsi</th>
                    <th>Ünvan</th>
                    <th>Aktivlik</th>
                    <th></th>
                    {/* <th className="text-right">Əməliyyatlar</th> */}
                  </tr>
                </thead>
                <tbody>
                  {branchs.map((branch, i) => (
                    <tr key={branch.idBranch} onClick={() => isSelectRow(branch.idBranch)} className={selectedRow === branch?.idBranch ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{branch.branchName}</td>
                      <td>{branch.organization?.organizationName}</td>
                      <td>{branch.phoneNumber}</td>
                      <td>{branch.address}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={branch?.isActive} onChange={() => changeActive(branch?.idBranch, branch?.isActive == 0 ? 1 : 0)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/branch/view/${branch?.idBranch}/${idOrganization ? idOrganization : ""}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
