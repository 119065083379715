import { get, post, put, deleteData } from "./core/Requests";

export const getDepartments = async (pageIndex, pageSize) => {
  return await get(`/Department`, { pageIndex, pageSize });
};

export const getForSelect = async () => {
  return await get(`/Department/getForSelect`);
};

export const getDepartment = async (id) => {
  return await get(`/Department/${id}`);
};

export const addDepartment = async (data) => {
  return await post(`/Department`, data);
};

export const updateDepartment = async (data) => {
  return await put(`/Department`, data);
};

export const deleteDepartment = async (id) => {
  return await deleteData(`/Department/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Department/changeActive/${id}`);
};
