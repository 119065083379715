import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as expenseService from "./../../../services/ExpenseService";
import * as financeOperationService from "./../../../services/FinanceOperationService";
import * as helperService from "./../../../services/HelperService";
import * as caseService from "./../../../services/CaseService";
import * as groupService from "./../../../services/GroupService";
import * as contractService from "./../../../services/ContractService";
// import * as paymentTypeService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import StudentSelectModal from "./../../student/modal/SelectModal";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import ExpenseSelectModal from "./../../expense/modal/SelectModal";
import IncomeSelectModal from "./../../income/modal/SelectModal";
import JuridicalPersonSelectModal from "./../../juridicalPerson/modal/SelectModal";
import * as date from "./../../../utils/DateNow";
import CommitButton from "./../../../components/commitButton/Index";
import { useStudent } from "../../../helper/useStudent"; 

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [financeOperation, setFinanceOperation] = useState({ financeOperationDate: date.Now() });
  const [groups, setGroups] = useState([]);
  const [cases, setCases] = useState([]);
  const [contractStudents, setContractStudents] = useState([]);
  const [contractJuridicalPersons, setContractJuridicalPersons] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [employeePaymentTypes, setEmployeePaymentTypes] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isOpenStudentSelectModal, setIsOpenStudentSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isOpenExpenseSelectModal, setIsOpenExpenseSelectModal] = useState(false);
  const [selectedIncome, setSelectedIncome] = useState(null);
  const [isOpenIncomeSelectModal, setIsOpenIncomeSelectModal] = useState(false);
  const [selectedJuridicalPerson, setSelectedJuridicalPerson] = useState(null);
  const [isOpenJuridicalPersonSelectModal, setIsOpenJuridicalPersonSelectModal] = useState(false);

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();

  var { idFinanceOperation, idFinanceOperationType } = useParams();
  if (idFinanceOperationType == "1-2") idFinanceOperationType = 1;
  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idFinanceOperation && getFinanceOperation();
  }, []);

  useEffect(() => {
    if (selectedStudent?.rowId != null) getContractStudents(selectedStudent?.rowId);
    else setContractStudents([]);
  }, [selectedStudent]);

  useEffect(() => {
    if (selectedJuridicalPerson?.rowId != null) getContractJuridicalPersons(selectedJuridicalPerson?.rowId);
    else setContractJuridicalPersons([]);
  }, [selectedJuridicalPerson]);

  useEffect(() => {
    (idFinanceOperationType == 3 || idFinanceOperationType == 4) && getGroups(localStorage.branch);
    idFinanceOperationType == 6 && getExpenses();
    (idFinanceOperationType == 4 || idFinanceOperationType == 5) && getEmployeePaymentTypes();
    getCases(localStorage.branch);
  }, [localStorage.branch]);

  useEffect(() => {
    !financeOperation?.fkIdCase && setFinanceOperation({ ...financeOperation, fkIdCase: cases[0]?.idCase });
  }, [cases]);

  const getGroups = async (branchId) => {
    let result = await groupService.getForSelect(branchId);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getEmployeePaymentTypes = async (branchId) => {
    let result = await helperService.getEmployeePaymentTypes(branchId);
    if (result.success) {
      setEmployeePaymentTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractStudents = async (studentId) => {
    let result = await contractService.getContractStudentsByStudent(0, 0, studentId);
    if (result.success) {
      setContractStudents(result?.data?.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const getContractJuridicalPersons = async (juridicalPersonId) => {
    let result = await contractService.getContractJuridicalsByJuridicalPerson(0, 0, juridicalPersonId);
    if (result.success) {
      setContractJuridicalPersons(result?.data?.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const getFinanceOperation = async () => {
    let result = await financeOperationService.getFinanceOperation(idFinanceOperation);
    if (result.success) {
      setFinanceOperation(result.data);
      setSelectedStudent({ rowId: result?.data?.fkIdStudent, fullName: result?.data?.studentFullName });
      setSelectedEmployee({ rowId: result?.data?.fkIdEmployee, fullName: result?.data?.employeeFullName });
      setSelectedExpense({ rowId: result?.data?.fkIdExpense, description: result?.data?.expenseDescription });
      setSelectedIncome({ rowId: result?.data?.fkIdIncome, description: result?.data?.incomeDescription });
      setSelectedJuridicalPerson({ rowId: result?.data?.fkIdJuridicalPerson, fullName: result?.data?.juridicalFullName });
    } else {
      Alerts.error(result.message);
    }
  };

  const getExpenses = async () => {
    let result = await expenseService.getExpensesByBranch(0, 0, localStorage.branch);
    if (result.success) {
      setExpenses(result.data.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCases = async (branchId) => {
    let result = await caseService.getCasesByBranch(0, 0, branchId);
    if (result.success) {
      setCases(result.data.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateFinanceOperation = async () => {
    if (selectedStudent != null) financeOperation.fkIdStudent = selectedStudent?.rowId;
    if (selectedEmployee != null) financeOperation.fkIdEmployee = selectedEmployee?.rowId;
    if (selectedExpense != null) financeOperation.fkIdExpense = selectedExpense?.rowId;
    if (selectedIncome != null) financeOperation.fkIdIncome = selectedIncome?.rowId;
    if (selectedJuridicalPerson != null) financeOperation.fkIdJuridicalPerson = selectedJuridicalPerson?.rowId;

    if (idFinanceOperationType != 1) {
      financeOperation.fkIdFinanceOperationType = idFinanceOperationType;
    }

    if (idFinanceOperationType == 5 || idFinanceOperationType == 6) {
      financeOperation.direction = -1;
    }

    if (financeOperation?.financeOperationNo == "") financeOperation.financeOperationNo = null;

    let result = idFinanceOperation ? await financeOperationService.updateFinanceOperation({ ...financeOperation, fkIdBranch: localStorage.branch }) : await financeOperationService.addFinanceOperation({ ...financeOperation, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/financeOperation/${idFinanceOperationType == 1 ? "1-2" : idFinanceOperationType}`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleStudentSelectModal = () => {
    setIsOpenStudentSelectModal(!isOpenStudentSelectModal);
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  const handleExpenseSelectModal = () => {
    setIsOpenExpenseSelectModal(!isOpenExpenseSelectModal);
  };

  const handleIncomeSelectModal = () => {
    setIsOpenIncomeSelectModal(!isOpenIncomeSelectModal);
  };

  const handleJuridicalPersonSelectModal = () => {
    setIsOpenJuridicalPersonSelectModal(!isOpenJuridicalPersonSelectModal);
  };

  const handleInput = (e) => {
    setFinanceOperation({ ...financeOperation, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setFinanceOperation({ ...financeOperation, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">paid</i>
              </div>
              <div className="card-content">
                {idFinanceOperationType == 3 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Tələbə ödənişi məlumatları" : "Tələbə ödənişi məlumatlarının redaktəsi") : "Yeni tələbə ödənişi"}</h4>
                ) : idFinanceOperationType == 4 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Təlimçi ödənişi məlumatları" : "Təlimçi ödənişi məlumatlarının redaktəsi") : "Yeni təlimçi ödənişi"}</h4>
                ) : idFinanceOperationType == 5 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Əməkdaş ödənişi məlumatları" : "Əməkdaş ödənişi məlumatlarının redaktəsi") : "Yeni əməkdaş ödənişi"}</h4>
                ) : idFinanceOperationType == 6 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Xərc ödənişi məlumatları" : "Xərc ödənişi məlumatlarının redaktəsi") : "Yeni xərc ödənişi"}</h4>
                ) : idFinanceOperationType == 7 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "əlavə gəlir ödənişi məlumatları" : "Əlavə gəlir ödənişi məlumatlarının redaktəsi") : "Yeni əlavə gəlir ödənişi"}</h4>
                ) : idFinanceOperationType == 1 ? (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Kassa əməliyyatı məlumatları" : "Kassa əməliyyatı məlumatlarının redaktəsi") : "Kassa əməliyyatı"}</h4>
                ) : (
                  <h4 className="card-title">{idFinanceOperation ? (isView ? "Hüquqi şəxs ödənişi məlumatları" : "Hüquqi şəxs ödənişi məlumatlarının redaktəsi") : "Yeni hüquqi şəxs ödənişi"}</h4>
                )}
                <div className="row">
                  {idFinanceOperationType == 3 ? (
                    <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Tələbənin tam adı"} value={selectedStudent != null ? selectedStudent?.fullName : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedStudent != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedStudent(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleStudentSelectModal}>
                            Tələbə seç
                          </button>
                        </div>
                      )}
                      {/* <Select placeholder={"Seç"} style={"col-sm-4"} label={"Qrup"} changeSelectHandler={(e) => handleSelect(e, "fkIdGroup")} selectedValue={financeOperation?.fkIdGroup} options={groups?.map((group) => ({ label: group?.groupName, value: group?.idGroup }))} isDisabled={isView} /> */}
                      <Select placeholder={"Seç"} style={"col-sm-4"} label={"Müqavilə"} changeSelectHandler={(e) => handleSelect(e, "fkIdContractStudent")} selectedValue={financeOperation?.fkIdContractStudent} options={contractStudents?.map((item) => ({ label: `${item?.trainingType?.description} (${item?.contractDate?.split("T")[0]})`, value: item?.idContractStudent }))} isDisabled={isView} />
                    </>
                  ) : idFinanceOperationType == 4 || idFinanceOperationType == 5 ? (
                    <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={idFinanceOperationType == 5 ? "Əməkdaşın tam adı" : "Təlimçinin tam adı"} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedEmployee != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                            {idFinanceOperationType == 5 ? "Əməkdaş seç" : "Təlimçi seç"}
                          </button>
                        </div>
                      )}
                      <Select placeholder={"Seç"} style={"col-sm-4"} required={true} label={"Ödəniş növü"} changeSelectHandler={(e) => handleSelect(e, "fkIdEmployeePaymentType")} selectedValue={financeOperation?.fkIdEmployeePaymentType} options={employeePaymentTypes?.map((item) => ({ label: item?.description, value: item?.idEmployeePaymentType }))} isDisabled={isView} />
                    </>
                  ) : idFinanceOperationType == 6 ? (
                    <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Xərc adı"} value={selectedExpense != null ? selectedExpense?.description : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedExpense != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedExpense(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleExpenseSelectModal}>
                            {"Xərc adı seç"}
                          </button>
                        </div>
                      )}
                    </>
                  ) : idFinanceOperationType == 7 ? (
                    <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Əlavə gəlir adı"} value={selectedIncome != null ? selectedIncome?.description : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedIncome != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedIncome(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleIncomeSelectModal}>
                            {"Əlavə gəlir adı seç"}
                          </button>
                        </div>
                      )}
                    </>
                  ) : idFinanceOperationType == 9 ? (
                    <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} label={"Hüquqi şəxs adı"} value={selectedJuridicalPerson != null ? selectedJuridicalPerson?.fullName : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedJuridicalPerson != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedJuridicalPerson(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleJuridicalPersonSelectModal}>
                            {"Hüquqi şəxs seç"}
                          </button>
                        </div>
                      )}
                      <Select placeholder={"Seç"} style={"col-sm-4"} label={"Müqavilə"} changeSelectHandler={(e) => handleSelect(e, "fkIdContractJuridicalPerson")} selectedValue={financeOperation?.fkIdContractJuridicalPerson} options={contractJuridicalPersons?.map((item) => ({ label: `${item?.trainingType?.description} (${item?.contractDate?.split("T")[0]})`, value: item?.idContractJuridicalPerson }))} isDisabled={isView} />
                    </>
                  ) : idFinanceOperationType == 1 ? (
                    <Select
                    required={true}
                      placeholder={"Seç"}
                      style={"col-sm-12"}
                      label={"Əməliyyatın növü"}
                      changeSelectHandler={(e) => handleSelect(e, "fkIdFinanceOperationType")}
                      selectedValue={financeOperation?.fkIdFinanceOperationType}
                      options={[
                        { label: "Kassaya mədaxil", value: 1 },
                        { label: "Kassada çıxarış", value: 2 },
                      ]}
                      isDisabled={isView}
                    />
                  ) : null}

                  <FormInput style={"col-sm-4"} label={"Məbləğ"} name={"amount"} required={true} type={"number"} value={financeOperation?.amount} onChange={handleInput} disabled={isView} />
                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Kassa"} changeSelectHandler={(e) => handleSelect(e, "fkIdCase")} selectedValue={financeOperation?.fkIdCase} options={cases?.map((c) => ({ label: c?.description, value: c?.idCase }))} isDisabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əməliyyatın nömrəsi"} name={"financeOperationNo"} value={financeOperation?.financeOperationNo} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Tarix"} required={true} name={"financeOperationDate"} type={"date"} value={financeOperation?.financeOperationDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Qeyd"} name={"note"} value={financeOperation?.note} onChange={handleInput} disabled={isView} />
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateFinanceOperation} />}
                  <Link to={`${alias}${id}/financeOperation/${idFinanceOperationType == 1 ? "1-2" : idFinanceOperationType}`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null}
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
      {isOpenExpenseSelectModal ? <ExpenseSelectModal isOpen={isOpenExpenseSelectModal} handleExpenseSelectModal={handleExpenseSelectModal} setSelectedExpense={setSelectedExpense} /> : null}
      {isOpenIncomeSelectModal ? <IncomeSelectModal isOpen={isOpenIncomeSelectModal} handleIncomeSelectModal={handleIncomeSelectModal} setSelectedIncome={setSelectedIncome} /> : null}
      {isOpenJuridicalPersonSelectModal ? <JuridicalPersonSelectModal isOpen={isOpenJuridicalPersonSelectModal} handleJuridicalPersonSelectModal={handleJuridicalPersonSelectModal} setSelectedJuridicalPerson={setSelectedJuridicalPerson} /> : null}
    </div>
  );
}
