import { get, post, put, deleteData } from "./core/Requests";

export const getSysUsers = async (pageIndex, pageSize) => {
  return await get(`/SysUser`, { pageIndex, pageSize });
};

export const getSysUser = async (id) => {
  return await get(`/SysUser/${id}`);
};

export const addSysUser = async (data) => {
  return await post(`/SysUser`, data);
};

export const updateSysUser = async (data) => {
    return await put(`/SysUser`, data);
  };

export const deleteSysUser = async (id) => {
  return await deleteData(`/SysUser/${id}`);
};

export const changePassword = async (data) => {
  return await post(`/SysUser/changePassword`, data);
};

export const changeActive = async (id) => {
  return await get(`/SysUser/changeActive/${id}`);
};
export const changeAdmin = async (id) => {
  return await get(`/SysUser/changeAdmin/${id}`);
};

export const changeTableRowCount = async (rowCount) => {
  return await get(`/SysUser/changeTableRowCount/${rowCount}`);
};

export const tableRowCount = async () => {
  return await get(`/SysUser/tableRowCount`);
};