import { Navigate } from "react-router";
import jwt_decode from "jwt-decode";
import NotFound from "./../pages/notfound/Index";

export default function PrivateAdminRoute({ children }) {
  if (localStorage.token != null) {
    var decoded = jwt_decode(localStorage.token);
    return decoded.isSysUser == "1" ? children : <NotFound />;
  }
  return <Navigate to={`/login`} />;
}
