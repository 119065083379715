import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import * as juridicalPersonService from "./../../../services/JuridicalPersonService";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [juridicalPerson, setJuridicalPerson] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idJuridicalPerson } = useParams();

  useEffect(() => {
    idJuridicalPerson && getJuridicalPerson();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getJuridicalPerson = async () => {
    let result = await juridicalPersonService.getJuridicalPerson(idJuridicalPerson);
    if (result.success) {
      setJuridicalPerson(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateJuridicalPerson = async () => {
    let result = idJuridicalPerson ? await juridicalPersonService.updateJuridicalPerson({ ...juridicalPerson, fkIdBranch: localStorage.branch }) : await juridicalPersonService.addJuridicalPerson({ ...juridicalPerson, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/juridical`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setJuridicalPerson({ ...juridicalPerson, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment_ind</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idJuridicalPerson ? (isView ? "Hüquqi şəxs məlumatları" : "Hüquqi şəxs məlumatlarının redaktəsi") : "Yeni hüquqi şəxs"}</h4>
                <div className="row">
                  <FormInput style={"col-sm-4"} label={"VÖEN"} name={"id"} value={juridicalPerson?.id} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Adı*"} name={"name"} value={juridicalPerson?.name} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Qeydiyat tarixi"} name={"registrationDate"} type={"date"} value={juridicalPerson?.registrationDate?.split('T')[0]} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Fəaliyyət sahəsi"} name={"activitieType"} value={juridicalPerson?.activitieType} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={juridicalPerson?.phoneNumber} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Email ünvanı"} name={"emailAddress"} value={juridicalPerson?.emailAddress} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əlaqəli şəxs"} name={"relatedPerson"} value={juridicalPerson?.relatedPerson} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əlaqəli şəxsin əlaqə nömrəsi"} name={"relatedPersonPhoneNumber"} value={juridicalPerson?.relatedPersonPhoneNumber} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əlaqəli şəxsin email ünvanı"} name={"relatedPersonEmailAddress"} value={juridicalPerson?.relatedPersonEmailAddress} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Referans"} name={"referance"} value={juridicalPerson?.referance} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Ünvan"} name={"address"} value={juridicalPerson?.address} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-12"} label={"Qeyd"} name={"note"} value={juridicalPerson?.note} onChange={handleInput} disabled={isView} />
                  <FormCheckbox label={"Aktivlik"} style={"col-sm-6"} name={"isActive"} checked={juridicalPerson?.isActive} onChange={() => setJuridicalPerson({ ...juridicalPerson, isActive: juridicalPerson?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateJuridicalPerson} />}
                  <Link to={`${alias}/juridical`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
