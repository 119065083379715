import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as branchService from "./../../../services/BranchService";
import * as studentService from "./../../../services/StudentService";
import * as Alerts from "./../../../utils/Alerts";

import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import defaultImage from "./../../../assets/img/default-avatar.png";
import { BaseAttachURL, FileRoutes } from "./../../../services/core/Urls";
import * as attachmentService from "./../../../services/AttachmentService";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [student, setStudent] = useState({});
  // const [branchs, setBranchs] = useState([]);
  const [activeTab, setActiveTab] = useState("personal");
  const [isView, setIsView] = useState(false);

  const [image, setImage] = useState(defaultImage);
  const [attach, setAttach] = useState();
  const [isActiveParentInfoAdd, setIsActiveParentInfoAdd] = useState(false);

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idStudent } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idStudent && getStudent();
    // getBranchs();
    checkActiveParentInfoAdd();
  }, []);

  const checkActiveParentInfoAdd = async () => {
    let result = await branchService.isActiveParentInfoAdd(localStorage.branch);
    if (result.success) {
      setIsActiveParentInfoAdd(result?.data);
    }
  };

  const getStudent = async () => {
    let result = await studentService.getStudent(idStudent);
    if (result.success) {
      setStudent(result.data);
      result.data?.profileImage && setImage(BaseAttachURL + FileRoutes.StudentRoute + result.data?.profileImage?.fileName);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateStudent = async () => {
    let result = idStudent ? await studentService.updateStudent({ ...student, fkIdBranch: localStorage.branch }) : await studentService.addStudent({ ...student, fkIdBranch: localStorage.branch });
    if (result?.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 1);
      formData.append("fkIdAttachmentSoruce", result.data?.idStudent);
      formData.append("files", attach);
      await attachmentService.addAttachments(formData, "students");

      Alerts.successWithBack(result.message, `${alias}/student`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  // const getBranchs = async () => {
  //   let result = await branchService.getBranchsByOrganizationForSelect();
  //   if (result.success) {
  //     setBranchs(result.data);
  //   } else {
  //     Alerts.error(result.message);
  //   }
  // };

  const handleInput = (e) => {
    setStudent({ ...student, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setStudent({ ...student, [name]: e.value });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setAttach(event.target.files[0]);
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">person_pin</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idStudent ? (isView ? "Tələbə məlumatları" : "Tələbə məlumatlarının redaktəsi") : "Yeni tələbə"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "personal" ? "active" : ""} onClick={() => setActiveTab("personal")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ŞƏXSİ MƏLUMATLAR
                    </a>
                  </li>
                  {isActiveParentInfoAdd && (
                    <li className={activeTab == "parent" ? "active" : ""} onClick={() => setActiveTab("parent")}>
                      <a href="javascript:void(0)" data-toggle="tab">
                        VALİDEYN MƏLUMATLARI
                      </a>
                    </li>
                  )}
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                  {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/profile")
                    ? idStudent && (
                        <li className={activeTab == "cabinet" ? "active" : ""} onClick={() => setActiveTab("cabinet")}>
                          <a href="javascript:void(0)" data-toggle="tab">
                            ŞƏXSİ KABİNET
                          </a>
                        </li>
                      )
                    : null}
                </ul>
                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "personal" ? "active" : "")}>
                    <div className="row">
                      <div className="col-sm-9">
                        <FormInput style={"col-sm-4"} label={"Ad"} name={"name"} required={true} value={student?.name} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Soyad"} name={"surname"} required={true} value={student?.surname} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Ata adı"} name={"middleName"} value={student?.middleName} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Doğum tarixi"} name={"birthDate"} type={"date"} value={student?.birthDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={student?.phoneNumber} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"Email"} name={"emailAddress"} value={student?.emailAddress} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-4"} label={"FİN"} name={"id"} value={student?.id} onChange={handleInput} disabled={isView} />
                        <FormInput style={"col-sm-8"} label={"Ünvan"} name={"address"} value={student?.address} onChange={handleInput} disabled={isView} />
                        <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={student?.isActive} onChange={() => setStudent({ ...student, isActive: student?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                      </div>
                      <div className="col-sm-3">
                        <span className="btn-file">
                          <div className="thumbnail">
                            <img src={image} style={{ minWidth: 150, maxWidth: 250, maxHeight: 250 }} />
                          </div>
                          <input type="file" accept=".jpg,.jpeg,.png,.tiff" onChange={onImageChange} disabled={isView} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "parent" ? "active" : "")}>
                    <div className="row">
                      <FormInput style={"col-sm-4"} label={"Valideynin adı"} name={"parentName"} value={student?.parentName} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Valideynin soyadı"} name={"parentSurname"} value={student?.parentSurname} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-4"} label={"Valideynin ata adı"} name={"parentMiddleName"} value={student?.parentMiddleName} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-6"} label={"Valideynin əlaqə nömrəsi"} name={"parentPhoneNumber"} value={student?.parentPhoneNumber} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-6"} label={"Valideynin emaili"} name={"parentEmailAddress"} value={student?.parentEmailAddress} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      {/* <Select placeholder={"Seç"} style={"col-sm-12"} label={"Filial"} selectedValue={student?.fkIdBranch} changeSelectHandler={(e) => handleSelect(e, "fkIdBranch")} options={branchs.map((branch) => ({ label: branch?.branchName, value: branch?.idBranch }))}  isDisabled={isView}/> */}
                      <FormInput style={"col-sm-6"} label={"Referans"} name={"referance"} value={student?.referance} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-6"} label={"Qeyd"} name={"note"} value={student?.note} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>
                  {idStudent && (
                    <div className={"tab-pane " + (activeTab === "cabinet" ? "active" : "")}>
                      <div className="row">
                        <FormInput style={"col-sm-6"} label={"İstifadəçi ID"} name={"studentId"} value={student?.idStudent} isBtnCopy={true} disabled />
                        <FormInput style={"col-sm-6"} label={"Şifrə"} name={"password"} value={student?.password} onChange={handleInput} isBtnCopy={true} disabled={isView} />
                        <FormInput style={"col-sm-12"} label={"Keçid"} name={"link"} value={`https://app.e-edu.az${alias}/${student?.idStudent}`} isBtnCopy={true} disabled />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateStudent} />}
                  <Link to={`${alias}/student`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
