import { get, post, put, deleteData } from "./core/Requests";

export const getEmployees = async (pageIndex, pageSize, filter) => {
  return await post(`/Employee/getList`, filter, { pageIndex, pageSize });
};

export const getEmployeesByOrganization = async (pageIndex, pageSize, filter, organizationId) => {
  return await post(`/Employee/getByOrganization/${organizationId}`, filter, {
    pageIndex,
    pageSize,
  });
};


export const getEmployeesByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Employee/getByBranch/${branchId}`, filter ?? {}, { pageIndex, pageSize });
};

export const getTrainersByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Employee/trainers/getByBranch/${branchId}`, filter ?? {}, { pageIndex, pageSize });
};

export const getTrainerDebts = async (pageIndex, pageSize, trainerId) => {
  return await get(`/Employee/trainer/debts/${trainerId}`, { pageIndex, pageSize });
};

export const getForSelect = async () => {
  return await get(`/Employee/GetForSelect`);
};

export const getEmployee = async (id) => {
  return await get(`/Employee/${id}`);
};

export const addEmployee = async (data) => {
  return await post(`/Employee`, data);
};

export const updateEmployee = async (data) => {
  return await put(`/Employee`, data);
};

export const deleteEmployee = async (id) => {
  return await deleteData(`/Employee/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Employee/changeActive/${id}`);
};
