import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import * as caseService from "./../../../services/CaseService";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [casee, setCase] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idCase } = useParams();

  useEffect(() => {
    idCase && getCase();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getCase = async () => {
    let result = await caseService.getCase(idCase);
    if (result.success) {
      setCase(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateCase = async () => {
    casee.fkIdBranch = localStorage.branch;
    let result = idCase ? await caseService.updateCase(casee) : await caseService.addCase(casee);
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/case`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setCase({ ...casee, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">currency_exchange</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idCase ? (isView ? "Kassa məlumatları" : "Kassa məlumatlarının redaktəsi") : "Yeni kassa"}</h4>
                  <div className="row">
                    <FormInput style={"col-sm-6"} label={"Kassa adı"} name={"description"} value={casee?.description} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Valyuta"} name={"currency"} value={casee?.currency} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Ortaq kassa"} style={"col-sm-12"} name={"common"} checked={casee?.common} onChange={() => setCase({ ...casee, common: casee?.common == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Bank hesabı"} style={"col-sm-12"} name={"bankAccount"} checked={casee?.bankAccount} onChange={() => setCase({ ...casee, bankAccount: casee?.bankAccount == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={casee?.isActive} onChange={() => setCase({ ...casee, isActive: casee?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && (
                      <CommitButton onClick={addOrUpdateCase} />
                    )}
                    <Link to={`${alias}/case`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
