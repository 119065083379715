import React from "react";
import * as util from "../../utils/Util";

export default function TrEmployeeReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.fullName}</td>
      <td>{item?.departmentName}</td>
      <td>{item?.postName}</td>
      <td>{util.FixedNumber(item?.ept1)}</td>
      <td>{util.FixedNumber(item?.ept2)}</td>
      <td>{util.FixedNumber(item?.ept3)}</td>
      <td>{util.FixedNumber(item?.ept4)}</td>
      <td>{util.FixedNumber(item?.ept5)}</td>
      <td>{util.FixedNumber(item?.ept6)}</td>
      <td>{util.FixedNumber(item?.sum)}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.employeePaymentType}</td>
      <td>{item?.financeOperationDate?.split('T')[0]}</td>
      <td>{item?.financeOperationNo}</td>
      <td>{util.FixedNumber(item?.amount)}</td>
      <td>{item?.note}</td>
      <td>{item?.userName}</td>
      <td>{item?.insertDate?.split('T')[0]}</td>
    </tr>
  ) : idReport == 3 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.assistantFullName}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.trainingCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalParticipateCount}</td>
    </tr>
  ) : null;
}
