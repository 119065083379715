import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as examService from "./../../../services/ExamService";
import * as groupService from "./../../../services/GroupService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [groups, setGroups] = useState([]);
  const [exam, setExam] = useState({examDate: date.Now()});
  const [ExamTypes, setExamTypes] = useState([]);

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idExam } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idExam && getExam();
  }, []);
  useEffect(() => {
    getGroups(localStorage.branch);
  }, [localStorage.branch]);

  const getExam = async () => {
    let result = await examService.getExam(idExam);
    if (result.success) {
      setExam(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroups = async (branchId) => {
    let result = await groupService.getForSelect(branchId);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateExam = async () => {
    let result = idExam ? await examService.updateExam({ ...exam, fkIdBranch: localStorage.branch }) : await examService.addExam({ ...exam, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/exam`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setExam({ ...exam, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setExam({ ...exam, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">chrome_reader_mode</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idExam ? (isView ? "İmtahan məlumatları" : "İmtahan məlumatlarının redaktəsi") : "Yeni imtahan adı"}</h4>

                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-4"} label={"İmtahanın adı"} required={true} name={"examName"} value={exam?.examName} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-4"} label={"İmtahan tarixi"} required={true} name={"examDate"} type={"date"} value={exam?.examDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-4"} label={"Kod"} name={"examCode"} value={exam?.examCode} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-3"} label={"Sual sayı"} required={true} name={"questionsCount"} type={"number"} value={exam?.questionsCount} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-3"} label={"Açıq sual sayı"} name={"openQuestionsCount"} type={"number"} value={exam?.openQuestionsCount} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-3"} label={"Test tipli sual sayı"} name={"testQuestionsCount"} type={"number"} value={exam?.testQuestionsCount} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-3"} label={"Maksimum bal"} name={"maximumPoint"} type={"number"} value={exam?.maximumPoint} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-12"} label={"Qeyd"} name={"note"} value={exam?.note} onChange={handleInput} disabled={isView} />

                    {exam.createdForGroup == 1 ? (
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-12"}
                        label={"Qrupun adı"}
                        selectedValue={exam?.fkIdGroup}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdGroup");
                        }}
                        options={groups?.map((group) => ({ label: group?.groupName, value: group?.idGroup }))}
                        isDisabled={isView}
                      />
                    ) : null}

                    {/* {exam.createdByTrainer == 1 ? (
                      <div className="row">
                        <>
                          <FormInput style={isView ? "col-sm-12" : "col-sm-7"} label={t("contract.trainerFullname")} value={selectedTrainer != null ? selectedTrainer.fullName : ""} disabled={true} />
                          {!isView && (
                            <div className="form-group col-sm-5">
                              <label className="label-control w-100"></label>
                              {selectedTrainer != null ? (
                                <button className="btn btn-danger" disabled={isView} onClick={() => setSelectedTrainer(null)}>
                                  <span class="material-icons">autorenew</span>
                                </button>
                              ) : null}
                              <button className="btn btn-info" disabled={isView} onClick={handleTrainerSelectModal}>
                                {t("button.selectTrainer")}
                              </button>
                            </div>
                          )}
                        </>
                      </div>
                    ) : null} */}

                    <FormCheckbox label={"Qrup üçün tərtib edilir"} style={"col-sm-12"} name={"createdForGroup"} checked={exam?.createdForGroup} onChange={() => setExam({ ...exam, createdForGroup: exam?.createdForGroup == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Təlimçi tərəfindən tərtib edilir"} style={"col-sm-12"} name={"createdByTrainer"} checked={exam?.createdByTrainer} onChange={() => setExam({ ...exam, createdByTrainer: exam?.createdByTrainer == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"İctimaiyyətə açıqdır"} style={"col-sm-12"} name={"isPublic"} checked={exam?.isPublic} onChange={() => setExam({ ...exam, isPublic: exam?.isPublic == 1 ? 0 : 1 })} disabled={isView} />
                  </div>

                  <div className="row align-right">
                    {!isView && <CommitButton onClick={addOrUpdateExam} />}
                    <Link to={`${alias}/exam`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
