import jwt_decode from "jwt-decode";

export const useStudent = () => {
  var decoded = localStorage.token != null ? jwt_decode(localStorage.token) : null;
  if (decoded != null) {
    return decoded?.studentId != null ? `/${decoded?.studentId}` : "";
  } else return "";

  //   let result = await organizationService.getOrg();
  //   console.log(result);
  //   if (result.success) return `/${result.data}`;
  //   else return "";
};
