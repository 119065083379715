import React, { useState, useEffect } from "react";
import { useOrganization } from "../../../helper/useOrganization";
import FormInput from "../../../components/formInput/Index";
import * as sysUserService from "../../../services/SysUserService";
import * as Alerts from "../../../utils/Alerts";
import { useNavigate } from "react-router";
import { useMainContext } from "../../../contexts/MainContext";

export default function ChangePasswordModal({ isOpen, handleChangePasswordModal, id }) {
  const [data, setData] = useState({});
  const alias = useOrganization();
  const navigate = useNavigate();
  const { state, setState } = useMainContext();

  const changePassword = async () => {
    if (data.NewPassword != data.NewPasswordRepeat) {
      Alerts.error("Şifrə və təkrar şifrə uyğun deyil");
    } else {
      let model = {
        UserId: id,
        NewPassword: data.NewPassword,
        CurrentPassword: data.CurrentPassword,
      };
      let result = await sysUserService.changePassword(model);
      if (result.success) {
        Alerts.successWithBack(result?.message, `${alias}/sysUser`, navigate);
        handleChangePasswordModal();
      } else {
        Alerts.error(result?.message);
      }
    }
  };

  const handleInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="changePasswordUserModalLabel" role="dialog" aria-labelledby="changePasswordUserModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Şifrənin dəyişdirilməsi
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-12"} label={"Mövcud şifrə"} name={"CurrentPassword"} type={"password"} value={data?.CurrentPassword} onChange={handleInput} />
                    <FormInput style={"col-sm-12"} label={"Yeni şifrə"} name={"NewPassword"} type={"password"} value={data?.NewPassword} onChange={handleInput} />
                    <FormInput style={"col-sm-12"} label={"Yeni şifrə(təkrar)"} name={"NewPasswordRepeat"} type={"password"} value={data?.NewPasswordRepeat} onChange={handleInput} />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={changePassword}>
              Təsdiq et
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleChangePasswordModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
