import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as examService from "../../services/ExamService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import ActionButtons from "../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [exams, setExams] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getExams(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getExams = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await examService.getExamsByBranch(pageIndex, pageSize, localStorage.branch);
    if (result.success) {
      setExams(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExam = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await examService.deleteExam(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getExams(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };


  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getExams(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`exam/delete`}
      editPath={`exam/edit`} 
      addPath={`exam/add`} deleteRow={deleteExam}
       selectedRow={selectedRow} /> 

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">chrome_reader_mode</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">İmtahanların siyahısı</h4>
          <PageSizeSelect onChange={(e)=> getExams(PAGE_INDEX, e.value)} />
          {loading ? (
            <Loading />
          ) : exams?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>İmtahanın adı</th>
                    <th>Kod</th>
                    <th>Tarix</th>
                    <th>Qrup adı</th>
                    <th>Sual sayı</th>
                    <th>Test tipli sual sayı</th>
                    <th>Açıq sual sayı</th>
                    <th>Maksimum bal</th>
                    <th>Qeyd</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {exams.map((item,i) => (
                    <tr key={item?.idExam} onClick={() => isSelectRow(item?.idExam)} className={selectedRow === item?.idExam ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.examName}</td>
                      <td>{item?.examCode}</td>
                      <td>{item?.examDate?.split('T')[0]}</td>
                      <td>{item?.group?.groupName}</td>
                      <td>{item?.questionsCount}</td>
                      <td>{item?.testQuestionsCount}</td>
                      <td>{item?.openQuestionsCount}</td>
                      <td>{item?.maximumPoint}</td>
                      <td>{item?.note}</td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/exam/view/${item?.idExam}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
