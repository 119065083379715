import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as postService from "./../../services/PostService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [posts, setPosts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };


  useEffect(() => {
    getPosts(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);


  const getPosts = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await postService.getPosts(pageIndex, pageSize);
    if (result.success) {
      setPosts(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deletePost = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await postService.deletePost(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getPosts(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idPost) => {
    let result = await postService.changeActive(idPost);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getPosts(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getPosts(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`post/delete`}
      editPath={`post/edit`} 
      addPath={`post/add`} deleteRow={deletePost}
       selectedRow={selectedRow} />   

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">cases</i>
        </div>
        <div className="card-content">
          <div>
            <h4 className="card-title">Vəzifələrin siyahısı</h4>
            <PageSizeSelect onChange={(e)=> getPosts(PAGE_INDEX, e.value)} />
          </div>
          {loading ? (
            <Loading />
          ) : posts?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Vəzifənin adı</th>
                    <th>Qeyd</th>
                    <th>Aktivlik</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((item, i) => (
                    <tr key={item?.idPost} onClick={() => isSelectRow(item?.idPost)} className={selectedRow === item?.idPost ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.postName}</td>
                      <td>{item?.description}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idPost)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/post/view/${item?.idPost}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
