import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as sysUserService from "./../../services/SysUserService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import UserChangePasswordModal from "./modal/ChangePasswordModal";
import * as Alerts from "./../../utils/Alerts";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [sysUsers, setSysUsers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getSysUsers(state.currentPage, localStorage.rowCount);
  }, []);

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getSysUsers(pageIndex, localStorage.rowCount);
  };

  const handleChangePasswordModal = () => {
    if (isOpenChangePasswordModal) setSelectedRow(0);
    setIsOpenChangePasswordModal(!isOpenChangePasswordModal);
  };

  const getSysUsers = async (pageIndex, pageSize) => {
    setLoading(true);
    let sysUsers = await sysUserService.getSysUsers(pageIndex, pageSize);
    if (sysUsers.success) {
      setSysUsers(sysUsers.data.datas);
      setPagination({
        pageIndex: sysUsers.data.pageIndex,
        hasNextPage: sysUsers.data.hasNextPage,
        hasPreviousPage: sysUsers.data.hasPreviousPage,
        totalPageCount: sysUsers.data.totalPageCount,
        totalRecordCount: sysUsers.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(sysUsers.message);
    }
  };

  const deleteSysUser = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let sysUser = await sysUserService.deleteSysUser(id);
        if (sysUser.success) {
          setSelectedRow(0);
          Alerts.success(sysUser.message);
        } else {
          Alerts.error(sysUser.message);
        }
        getSysUsers(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idSysUser) => {
    let result = await sysUserService.changeActive(idSysUser);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getSysUsers(pagination.pageIndex, localStorage.rowCount);
  };

  const changeAdmin = async (idSysUser) => {
    let result = await sysUserService.changeAdmin(idSysUser);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getSysUsers(pagination.pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {isOpenChangePasswordModal ? <UserChangePasswordModal isOpen={isOpenChangePasswordModal} handleChangePasswordModal={handleChangePasswordModal} id={selectedRow} /> : null}
      <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteSysUser(selectedRow)}>
        Sil
      </button>
      <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => setIsOpenChangePasswordModal(!isOpenChangePasswordModal)}>
        Şifrəni dəyiş
      </button>
      <Link to={`/sysUser/edit`}>
        <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
          Redaktə et
        </button>
      </Link>
      <Link to={`/sysUser/add`}>
        <button style={{ float: "right" }} className="btn btn-info">
          Əlavə et
        </button>
      </Link>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">İnzibatçıların siyahısı</h4>
          <PageSizeSelect onChange={(e) => getSysUsers(PAGE_INDEX, e.value)} />

          {loading ? (
            <Loading />
          ) : sysUsers?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>İstifadəçi adı</th>
                    <th>Admin</th>
                    <th>Aktivlik</th>
                    {/* <th className="text-right">Əməliyyatlar</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sysUsers.map((sysUser, i) => (
                    <tr key={sysUser.idSysUser} onClick={() => isSelectRow(sysUser.idSysUser)} className={selectedRow === sysUser?.idSysUser ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{sysUser.userName}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={sysUser.isAdmin} onChange={() => changeAdmin(sysUser?.idSysUser)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={sysUser.isActive} onChange={() => changeActive(sysUser?.idSysUser)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
