const _azTranslation = {
  lang_code: "AZ",
  tableRow: "məlumat",
  dashboard: {
    applications: "Müraciətlər",
    students: "Tələbələr",
    groups: "Qruplar",
    employees: "Əməkdaşlar",
    description: "Ətraflı məlumat",
    payments: "Ödənişlər",
    todayPayments: "Bugünkü ödənişlər",
    allPayments: "Ümumi ödənişlər",
    trainings: "Təlimlər",
    todayTrainings: "Bugünkü təlimlər",
    finishTrainings: "Yekunlaşmış təlimlər",
    assignments: "Tapşırıqlar",
    reviews: "Rəylər",
    trainingMaterials: "Təlim materialları",
    contracts: "Müqavilələr",
    trainerContracts: "Təlimçı müqavilələri",
    studentContracts: "Tələbə müqavilələri",
    studentDebt: "Ödənilməli məbləğ"
  },
  contract: {
    studentContractList: "Tələbə müqavilələrinin siyahısı",
    trainerContractList: "Təlimçi müqavilələrinin siyahısı",
    juridicalContractList: "Tələbə müqavilələrinin siyahısı",
    studentFullname: "Tələbənin adı",
    trainerFullname: "Təlimçinin adı",
    juridicalFullname: "Tələbənin adı",
    newContract: "Yeni müqavilə",
    infoContract: "Müqavilə məlumatları",
    editContract: "Müqavilə məlumatlarının redaktəsi",
    coordinatorFullname: "Koordinatorun tam adı",
    contractNumber: "Müqavilə nömrəsi",
    contractDate: "Müqavilə tarixi",
    contractStatus: "Müqavilə statusu",
    trainingType: "Təlim növü",
    contractAmount: "Məbləğ",
    discount: "Endirim",
    startDate: "Başlama tarixi",
    endDate: "Bitmə tarixi",
    note: "Qeyd",
    contractStatusNote: "Status qeydi",
  },
  file: {
    select: "Fayl seç",
    name: "Faylın adı",
    type: "Faylın tipi",
    size: "Faylın ölçüsü",
  },
  button: {
    accept: "Təsdiq et",
    cancel: "Bağla",
    selectStudent: "Tələbə seç",
    selectCoordinator: "Koordinator seç",
    selectTrainer: "Təlimçi seç",
    add: "Əlavə et",
    edit: "Redaktə et",
    delete: "Sil",
  },
};

export default _azTranslation;
