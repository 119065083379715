import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as groupService from "../../../services/GroupService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";

import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, trainerId }) {
  const { state, setState } = useMainContext();
  const [groups, setGroups] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGroups(1, localStorage.rowCount);
  }, []);

  const getGroups = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await groupService.getTrainerGroups(pageIndex, pageSize, trainerId);
    if (result.success) {
      setGroups(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    getGroups(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getGroups(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : groups?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Qrupun adı</th>
                            <th>Otaq</th>
                            <th>Başlama tarixi</th>
                            <th>Bitmə tarixi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groups.map((item, i) => (
                            <tr key={item?.idGroup}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.groupName}</td>
                              <td>{item?.room}</td>
                              <td>{item?.startDate?.split("T")[0]}</td>
                              <td>{item?.expirationDate?.split("T")[0]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
