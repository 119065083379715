import React from "react";
import { useMainContext } from "../../contexts/MainContext";

export default function useRules() {
  const { state, setState } = useMainContext();

  return (
    <div className="col-md-12">
      <div className="card" style={{minHeight:300}} data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">İstifadə qaydaları</h4>
          <div>
            <p style={{ textAlign: "center", fontSize: 25, color: "#666", margin:60, lineHeight:2, fontStyle:'italic'   }}>Hal-hazırda video təlimlər hazırlanır, bir neçə həftə ərzində təlim videoları istifadəçilərə təqdim ediləcək</p>
          </div>
        </div>
      </div>
    </div>
  );
}
