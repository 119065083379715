import { get, post, put, deleteData } from "./core/Requests";

// export const getDebtCalculationTrainers = async (pageIndex = 0, pageSize = 0) => {
//   return await get(`/DebtCalculationTrainer/getList`, { pageIndex, pageSize });
// };

// export const getDebtCalculationTrainersByBranch = async (pageIndex, pageSize, branchId) => {
//   return await get(`/DebtCalculationTrainer/getByBranch/${branchId}`, {
//     pageIndex,
//     pageSize,
//   });
// };


// export const getDebtCalculationTrainer = async (id) => {
//   return await get(`/DebtCalculationTrainer/${id}`);
// };

export const addDebtCalculationTrainer = async (data) => {
  return await post(`/DebtCalculationTrainer`, data);
};

export const updateDebtCalculationTrainer = async (data) => {
  return await put(`/DebtCalculationTrainer`, data);
};

export const deleteDebtCalculationTrainer = async (id) => {
  return await deleteData(`/DebtCalculationTrainer/${id}`);
};


