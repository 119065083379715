import { get, post, put, deleteData } from "./core/Requests";

// export const getDebtCalculationStudents = async (pageIndex = 0, pageSize = 0) => {
//   return await get(`/DebtCalculationStudent/getList`, { pageIndex, pageSize });
// };

// export const getDebtCalculationStudentsByBranch = async (pageIndex, pageSize, branchId) => {
//   return await get(`/DebtCalculationStudent/getByBranch/${branchId}`, {
//     pageIndex,
//     pageSize,
//   });
// };


// export const getDebtCalculationStudent = async (id) => {
//   return await get(`/DebtCalculationStudent/${id}`);
// };

export const addDebtCalculationStudent = async (data) => {
  return await post(`/DebtCalculationStudent`, data);
};

export const updateDebtCalculationStudent = async (data) => {
  return await put(`/DebtCalculationStudent`, data);
};

export const deleteDebtCalculationStudent = async (id) => {
  return await deleteData(`/DebtCalculationStudent/${id}`);
};


