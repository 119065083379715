import { get, post, put, deleteData } from "./core/Requests";

export const getGroupMembers = async (pageIndex, pageSize) => {
  return await get(`/GroupMember`, { pageIndex, pageSize });
};

export const getGroupMembersByGroup = async (pageIndex, pageSize, groupId) => {
  return await get(`/GroupMember/getByGroup?groupId=${groupId}`, { pageIndex, pageSize });
};

export const getGroupMembersByGroupForJournal = async (pageIndex = 0, pageSize = 0, groupId) => {
  return await get(`/GroupMember/getByGroupForJournal?groupId=${groupId}`, { pageIndex, pageSize });
};

export const getGroupsByOrganizationForSelect = async () => {
  return await get(`/Group/getByOrganizationForSelect`);
};

export const getGroupMember = async (id) => {
  return await get(`/GroupMember/${id}`);
};

export const addGroupMember = async (data) => {
  return await post(`/GroupMember`, data);
};

export const updateGroupMember = async (data) => {
  return await put(`/GroupMember`, data);
};

export const deleteGroupMember = async (id) => {
  return await deleteData(`/GroupMember/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/GroupMember/changeActive/${id}`);
};
