import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as expenseService from "./../../../services/ExpenseService";
import * as expenseTypeService from "./../../../services/ExpenseTypeService";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [branchs, setBranchs] = useState([]);
  const [expense, setExpense] = useState({});
  const [expenseTypes, setExpenseTypes] = useState([]);

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idExpense } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idExpense && getExpense();
    getExpenseTypes();
  }, []);

  const getExpense = async () => {
    let result = await expenseService.getExpense(idExpense);
    if (result.success) {
      setExpense(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getExpenseTypes = async () => {
    let result = await expenseTypeService.getExpenseTypes();
    if (result.success) {
      setExpenseTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };
  

  const addOrUpdateExpense = async () => {
    let result = idExpense ? await expenseService.updateExpense({ ...expense, fkIdBranch: localStorage.branch }) : await expenseService.addExpense({ ...expense, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/expense`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setExpense({ ...expense, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setExpense({ ...expense, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">energy_savings_leaf</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idExpense ? (isView ? "Xərc adı məlumatları" : "Xərc adı məlumatlarının redaktəsi") : "Yeni xərc adı"}</h4>

                <form method="#" action="#">
                  <div className="row">
                  <FormInput style={"col-sm-12"} label={"Xərc adı"} name={"description"} value={expense?.description} onChange={handleInput} disabled={isView} />
                    <Select placeholder={"Seç"} style={"col-sm-6"} label={"Xərcin növü"} selectedValue={expense?.fkIdExpenseType} changeSelectHandler={(e) => handleSelect(e, "fkIdExpenseType")} options={expenseTypes.map((expenseType) => ({ label: expenseType?.description, value: expenseType?.idExpenseType }))} isDisabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Məbləğ"} name={"amount"} type={"number"} value={expense?.amount} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Ortaq"} style={"col-sm-12"} name={"common"} checked={expense?.common} onChange={() => setExpense({ ...expense, common: expense?.common == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={expense?.isActive} onChange={() => setExpense({ ...expense, isActive: expense?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                  </div>

                  <div className="row align-right">
                    {!isView && (
                      <CommitButton onClick={addOrUpdateExpense} />
                    )}
                    <Link to={`${alias}/expense`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
