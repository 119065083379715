export const Now = (date) => {
  var today = new Date();
  today.setDate(today.getDate() + (date ?? 0));
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

export const NowTime = (date) => {
  var today = new Date();

  return today.toLocaleTimeString();
};
