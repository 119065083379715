import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import languages from "./../translations/index";

const resources = {
    en: {
        translation: languages.en
    },
    az: {
        translation: languages.az
    },
    ru: {
        translation: languages.ru
    }
};

// Kullanıcının dilini alalım
const getLanguage = () => {
    return "az";
    const ls = localStorage.getItem("lang")
    if (ls !== null) {
        if (Object.keys(resources).find(f => f === ls) !== undefined) {
            return ls
        } else {
            localStorage.setItem("lang", "az")
            return "az"
        }
    } else {
        let parsed = navigator.language
        if (parsed.includes("-")) {
            parsed = parsed.split("-")[0]
        }
        localStorage.setItem("lang", parsed)
        return parsed
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getLanguage(),
        interpolation: {
            escapeValue: false
        },
        saveMissing: true,
        parseMissingKeyHandler: (key) => {
            return `missing translation "${key}"`;
        },
        react: {
            transWrapTextNodes: 'span'
        }
    });
export default i18n;