import { get, post, put, deleteData } from "./core/Requests";

export const getSalaryCalculationTypes = async (salaryCalculationTypeGroup) => {
  return await get(`/SalaryCalculationType/getByCalculationTypeGroup/${salaryCalculationTypeGroup}`);
};

export const getSalaryCalculationType = async (id) => {
  return await get(`/SalaryCalculationType/${id}`);
};

export const addSalaryCalculationType = async (data) => {
  return await post(`/SalaryCalculationType`, data);
};

