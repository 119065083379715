import React, { useState, useEffect } from "react";
import FormInput from "../../../components/formInput/Index";
import FormCheckbox from "../../../components/formCheckbox/Index";
import { useMainContext } from "./../../../contexts/MainContext";
import * as notificationService from "./../../../services/NotificationService";
import * as Alerts from "./../../../utils/Alerts";
import StudentLoginLogModal from "../../student/modal/StudentLoginLogListModal";

export default function StudentPassModal({ isOpen, header, handleViewModal, student, alias }) {
  const { state, setState } = useMainContext();
  const [isOpenStudentLogModal, setIsOpenStudentLogModal] = useState(false);

  const handleStudentLogModal = () => {
    setIsOpenStudentLogModal(!isOpenStudentLogModal);
  };

  const addNotification = async () => {
    let result = await notificationService.addNotification({ fkIdBranch: localStorage.branch, studentId: student?.id });
    if (result?.success) {
      Alerts.success(result?.message);
    } else {
      Alerts.error(result?.message);
    }
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-6"} label={"İstifadəçi ID"} name={"studentId"} value={student?.id} isBtnCopy={true} disabled />

                    <FormInput style={"col-sm-6"} label={"Şifrə"} name={"password"} value={student?.pass} isBtnCopy={true} disabled />
                    <FormInput style={"col-sm-12"} label={"Keçid"} name={"link"} value={`https://app.e-edu.az${alias}/${student?.id}`} isBtnCopy={true} disabled />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer">
          <button className="btn btn-fill btn-warning f-left" onClick={handleStudentLogModal}>
              Daxilolma tarixçəsi
            </button>
            <button className="btn btn-fill btn-info" onClick={addNotification}>
              Sms göndər
            </button>
            <button className="btn btn-fill btn-success">Emailə göndər</button>
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenStudentLogModal ? <StudentLoginLogModal isOpen={isOpenStudentLogModal} handleViewModal={handleStudentLogModal} studentId={student?.id} studentName={student?.studentName}  /> : null}
    </div>
  );
}
