import { get, post, put, deleteData } from "./core/Requests";

export const getTrainingMaterials = async (pageIndex, pageSize) => {
  return await get(`/TrainingMaterial`, { pageIndex, pageSize });
};

export const getTrainingMaterialsByBranch = async (branchId, pageIndex = 0, pageSize = 0, filter) => {
   return await post(`/TrainingMaterial/getByBranch/${branchId}`,filter, { pageIndex, pageSize });
 };

export const getForSelect = async () => {
  return await get(`/TrainingMaterial/getForSelect`);
};

export const getTrainingMaterial = async (id) => {
  return await get(`/TrainingMaterial/${id}`);
};

export const addTrainingMaterial = async (data) => {
  return await post(`/TrainingMaterial`, data);
};

export const updateTrainingMaterial = async (data) => {
  return await put(`/TrainingMaterial`, data);
};

export const deleteTrainingMaterial = async (id) => {
  return await deleteData(`/TrainingMaterial/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/TrainingMaterial/changeActive/${id}`);
};
