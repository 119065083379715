import { get, post, put, deleteData } from "./core/Requests";

export const getExpenses = async (pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Expense/getList`, filter, { pageIndex, pageSize });
};

export const addAttachments = async (data, route) => {
  return await post(`/File/${route}/upload`, data);
};

export const deleteAttachment = async (id) => {
  return await deleteData(`/File/${id}`);
};