import React from "react";
import { useMainContext } from "../../contexts/MainContext";
import { URL } from "./../../services/core/Urls";

export default function Download() {
  const { state, setState } = useMainContext();

  return (
    <div className="col-md-12">
      <div className="card" style={{ minHeight: 300 }} data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">download</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Yükləmələr</h4>
          <div>
            <div className="attachment" onClick={() => (window.location = URL.BaseURL + `/file/download/student_import_template.xlsx`)}>
              <i className="material-icons" style={{ fontSize: 30 }}>
                attach_file
              </i>
              <br />
              <span>Tələbə məlumatlarının sistemə miqrasiyası üçün fayl şablonu</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
