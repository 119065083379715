import { get, post, put, deleteData } from "./core/Requests";

export const getOrganizationPayments = async (pageIndex, pageSize) => {
  return await get(`/OrganizationPayment`, { pageIndex, pageSize });
};

export const getOrganizationPayment = async (id) => {
  return await get(`/OrganizationPayment/${id}`);
};

export const addOrganizationPayment = async (data) => {
  return await post(`/OrganizationPayment`, data);
};

export const updateOrganizationPayment = async (data) => {
    return await put(`/OrganizationPayment`, data);
  };

export const deleteOrganizationPayment = async (id) => {
  return await deleteData(`/OrganizationPayment/${id}`);
};
