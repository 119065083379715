import React from "react";
import ReactLoading from 'react-loading';


export default function Index({type, color, height, width}) {
  return (

    <div className="loading">
    <ReactLoading type={type||"bars"} color={color||"#2899d3"} height={height||40} width={width||40} />
    </div>
  );
}
