import { get, post, put, deleteData } from "./core/Requests";

export const getTrainingTypes = async () => {
  return await get(`/TrainingType`);
};

export const getTrainingTypesList = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/TrainingType/getList`, { pageIndex, pageSize });
};


export const getTrainingType = async (id) => {
  return await get(`/TrainingType/${id}`);
};

export const addTrainingType = async (data) => {
  return await post(`/TrainingType`, data);
};

export const updateTrainingType = async (data) => {
  return await put(`/TrainingType`, data);
};

export const deleteTrainingType = async (id) => {
  return await deleteData(`/TrainingType/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/TrainingType/changeActive/${id}`);
};
