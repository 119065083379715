import React, { useState, useEffect } from "react";
import * as authService from "./../../services/AuthService";
import logo from "./../../assets/logo/edu_w.png";
import Loading from "./../../components/loading/Index";
import { useTokenData } from "../../helper/useTokenData";
import { useLocation } from "react-router";

export default function Index() {
  const [loginData, setLoginData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const tokenData = useTokenData();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.token != null) {
   //   let goToPath = "http://localhost:3000/";
      let goToPath = "https://app.e-edu.az/";
      if (tokenData?.isSysUser == "1" && location?.pathname?.toLowerCase().includes("admin")) window.location = goToPath + "organization";
    }
  }, []);

  const login = async (e) => {
    setLoading(true);
    e.preventDefault();
    let result = await authService.loginAdmin(loginData);
    if (result.success) {
      localStorage.token = result.data.token;
      localStorage.rowCount = result.data.user.tableRowCount;
      window.location = "/organization";
    } else {
      setError(result.message);
    }
    setLoading(false);
  };
  const handleInput = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  return (
    <div className="full-page login-page">
      <div className="content">
        <div className="container">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form onSubmit={login}>
              <div className="card card-login" data-background-color={"blue"}>
                <div className="card-header text-center" data-background-color="blue">
                  <img style={{ width: 90 }} src={logo} />
                  {/* <h5 className="card-title">E-EDU.AZ(ADMIN)</h5> */}
                </div>
                <div className="card-content">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">face</i>
                    </span>
                    <div className="form-group label-floating is-empty">
                      <label>İstifadəçi adı</label>
                      <input autoFocus type="text" name="username" className="form-control" onChange={handleInput} />
                      <span className="material-input" />
                    </div>
                  </div>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">lock_outline</i>
                    </span>
                    <div className="form-group label-floating is-empty">
                      <label>Şifrə</label>
                      <input type="password" className="form-control" name="password" onChange={handleInput} />
                      <span className="material-input" />
                    </div>
                  </div>
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>

                <div className="footer text-center">
                  <button type="submit" className="btn btn-info btn-wd">
                    {loading ? <Loading type={"spinningBubbles"} color={"white"} height={25} width={25} /> : "Daxil ol"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
