import React, { useState } from "react";
import { useMainContext } from "../../contexts/MainContext";

export default function Index({ style, colorClass, label, name, disabled, value, onChange, required, rows }) {
  const { state, setState } = useMainContext();

  return (
    <div className={"form-group is-empty " + style}>
      <label className={`control-label ${required && !value ? "required-red" : ""}`}>
        {label}
        {required && "*"}
      </label>
      <textarea className={"form-control " + colorClass} value={value || ""} rows={rows} data-color={state.theme} name={name} disabled={disabled} onChange={onChange} />
    </div>
  );
}
