import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as applicationService from "../../../services/ApplicationService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";

import * as Alerts from "../../../utils/Alerts";

export default function CallBackListModal({ isOpen, handleViewModal, applicationId }) {
  const { state, setState } = useMainContext();
  const [callBacks, setCallBacks] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCallBacks(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getCallBacks = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await applicationService.getApplicationCallBacks(applicationId, pageIndex, pageSize);
    if (result.success) {
      setCallBacks(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteCallBack = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await applicationService.deleteApplicationCallBack(id);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getCallBacks(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    getCallBacks(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Əlaqə məlumatları
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getCallBacks(PAGE_INDEX, e.value)} />

                  {loading ? (
                    <Loading />
                  ) : callBacks?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tarix</th>
                            <th>Saat</th>
                            <th>Qeyd</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {callBacks.map((item, i) => (
                            <tr key={item?.idApplicationCallBack}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.callBackDate}</td>
                              <td>{item?.callBackTime}</td>
                              <td>{item?.note}</td>
                              <td>{item?.applicationCallBackResultDesc}</td>
                              <td className="td-actions text-right">
                                {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "application/deletecallbackresult") ? (
                                  <button
                                    title="Sil"
                                    className="btn btn-danger"
                                    onClick={(e) => {
                                      deleteCallBack(item?.idApplicationCallBack);
                                    }}
                                  >
                                    <i className="material-icons">delete</i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
