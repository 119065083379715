import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../contexts/MainContext";
import { useOrganization } from "../../helper/useOrganization";
import { useState } from "react";
import { URL } from "./../../services/core/Urls";
import * as helperService from "./../../services/HelperService";

export default function Index({ deletePath, editPath, addPath, changePasswordPath, exportPath, selectedRow, deleteRow, assignmentDetailModal, isOpenAssignmentDetail, changePasswordModal, isChangePassword, isExsistOtherBtn, handleFileChange, exportReport }) {
  const { state, setState } = useMainContext();
  const [isOpenCombo, setIsOpenCombo] = useState(false);

  const alias = useOrganization();
  const isShowEdit = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && editPath?.toLowerCase() == obj.menuName.toLowerCase());
  const isShowAdd = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && addPath?.toLowerCase() == obj.menuName.toLowerCase());
  const isShowDelete = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && deletePath?.toLowerCase() == obj.menuName.toLowerCase());
  // const isShowExport = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && exportPath?.toLowerCase() == obj.menuName.toLowerCase());
  const isChangePasswordPath = alias == "" || -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && changePasswordPath?.toLowerCase() == obj.menuName.toLowerCase());

  const handleExport = async () => {
    if (exportReport) {
      exportReport();
    } else {
      
      let path = `/${exportPath}/${localStorage.branch}`;
      let result = await helperService.getExportExcel(path);

      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `export.xlsx`;
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      {isExsistOtherBtn && (
        <label className="f-right">
          <div className={`dropdown ${isOpenCombo && "open"}`}>
            <button onClick={() => setIsOpenCombo(!isOpenCombo)} className="dropdown-toggle btn btn-success" data-toggle="dropdown">
              Digər <b className="caret" />
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/import") && handleFileChange ? (
                <li>
                  <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                    Məlumatların fayldan import et
                    <input type={"file"} accept=".xls, .xlsx" onChange={handleFileChange} onClick={(e) => (e.target.value = null)} style={{ display: "none" }} />
                  </label>
                </li>
              ) : null}
              {exportPath && (
                <li>
                  <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                    Export
                    <input type={"button"} onClick={(e) => handleExport()} style={{ display: "none" }} />
                  </label>
                </li>
              )}
            </ul>
          </div>
        </label>
      )}
      {isShowDelete && (
        <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteRow(selectedRow)}>
          Sil
        </button>
      )}
      {assignmentDetailModal && (
        <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => assignmentDetailModal(!isOpenAssignmentDetail)}>
          Tapşırığın icra vəziyyəti
        </button>
      )}
      {isChangePasswordPath && (
        <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => changePasswordModal(!isChangePassword)}>
          Şifrəni dəyiş
        </button>
      )}
      {isShowEdit && (
        <Link to={`${alias}/${editPath}/${selectedRow}`}>
          <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
            Redaktə et
          </button>
        </Link>
      )}
      {isShowAdd && (
        <Link to={`${alias}/${addPath}`}>
          <button className="btn btn-info f-right">Əlavə et</button>
        </Link>
      )}
      {/* {isShowExport && (
        <button className="btn btn-success f-right" onClick={() => (window.location = URL.BaseURL + `/${exportPath}/${localStorage.branch}`)}>
          <i className="material-icons">download</i>
          Export
        </button>
      )} */}
    </>
  );
}
