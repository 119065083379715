import { get, post, put, deleteData } from "./core/Requests";

export const getStudents = async (pageIndex, pageSize, filter) => {
  return await post(`/Student/getList`, filter, { pageIndex, pageSize });
};

export const getStudentsByOrganization = async (pageIndex, pageSize, filter, organizationId) => {
  return await post(`/Student/getByOrganization/${organizationId}`, filter, { pageIndex, pageSize });
};

export const getStudentsByBranch = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Student/getByBranch/${branchId}`, filter ?? {}, { pageIndex, pageSize });
};

export const getStudentDebts = async (pageIndex, pageSize, studentId) => {
  return await get(`/Student/debts/${studentId}`, { pageIndex, pageSize });
};

export const getStudent = async (id) => {
  return await get(`/Student/${id}`);
};

export const addStudent = async (data) => {
  return await post(`/Student`, data);
};

export const updateStudent = async (data) => {
  return await put(`/Student`, data);
};

export const deleteStudent = async (id) => {
  return await deleteData(`/Student/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Student/changeActive/${id}`);
};

export const importStudentsbyExcel = async (data, branchId) => {
  return await post(`/Student/importStudentsbyExcel/${branchId}`, data);
};
