import { get, post, put, deleteData } from "./core/Requests";

export const getExams = async (pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Exam/getList`, filter, { pageIndex, pageSize });
};


export const getExamsByOrganization = async ( pageIndex, pageSize) => {
  return await get(`/Exam/getByOrganization`, {
    pageIndex,
    pageSize,
  });
};

export const getExamsByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Exam/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

export const getExam = async (id) => {
  return await get(`/Exam/${id}`);
};

export const addExam = async (data) => {
  return await post(`/Exam`, data);
};

export const updateExam = async (data) => {
  return await put(`/Exam`, data);
};

export const deleteExam = async (id) => {
  return await deleteData(`/Exam/${id}`);
};

