import { Navigate } from "react-router";
import jwt_decode from "jwt-decode";

export default function PrivateRoute({ children, privilege, accesses }) {
  if (localStorage.token != null) {
    var decoded = jwt_decode(localStorage.token);
    
    if (accesses?.includes(decoded?.userTypeId) || decoded?.isSysUser == "1") 
      return children;
    return <Navigate to={`/404`} />;

  }
  return <Navigate to={`/login`} />;
}
