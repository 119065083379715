const _enTranslation = {
  lang_code: "EN",
  tableRow:"row",
  dashboard: {
    applications: "Applications",
    students: "Students",
    groups: "Groups",
    employees: "Employees",
    description: "More info",
    payments: "Payments",
    todayPayments: "Today payments",
    allPayments: "All payments",
    trainings: "Trainings",
    todayTrainings: "Today trainings",
    finishTrainings: "Finished trainings",
  },
  contract:{
    studentContractList:"Student contract list",
    juridicalContractList:"Juridical person contract list",
    studentFullname:"Student fullname",
    juridicalFullname:"Juridical person name",
    newContract:"New Contract",
    infoContract:"Contract info",
    editContract:"Edit contract",
    coordinatorFullname:"Coordinator fullname",
    contractNumber:"Contract number",
    contractDate:"Contract date",
    contractStatus:"Contract status",
    trainingType:"Traning type",
    contractAmount:"Amount",
    discount:"Discount",
    startDate:"Start date",
    endDate:"End date",
    note:"Note"
  },
  file:{
    select:"File select",
    name:"File name",
    type:"File type",
    size:"File size"
  },
  button:{
    accept:"Accept",
    cancel:"Cancel",
    selectStudent:"Student select",
    selectCoordinator:"Coordinator select",
    add:"Add",
    edit:"Edit",
    delete:"Delete"
  }
};

export default _enTranslation;
