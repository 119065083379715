import { get, post, put, deleteData } from "./core/Requests";

export const getUsers = async (pageIndex, pageSize, filter) => {
  return await post(`/User/GetList`, filter, { pageIndex, pageSize });
};

export const getUsersByOrganization = async (pageIndex, pageSize, filter) => {
  return await post(`/User/GetByOrganization`, filter, { pageIndex, pageSize });
};

export const getUser = async (id) => {
  return await get(`/User/${id}`);
};

export const addUser = async (data) => {
  return await post(`/User`, data);
};

export const updateUser = async (data) => {
  return await put(`/User`, data);
};

export const addUserMenuPrivilage = async (data, branchId) => {
  return await post(`/User/privilage/${branchId}`, data);
};

export const getUserMenuPrivilage = async (userId) => {
  return await get(`/User/privilage/${userId}`);
};

export const getUserMenuPrivilages = async () => {
  return await get(`/User/privilages`);
};


export const addUserBranchPrivilage = async (data) => {
  return await post(`/User/branchPrivilage`, data);
};

export const getUserBranchPrivilage = async (userId) => {
  return await get(`/User/branchPrivilage/${userId}`);
};

export const getUserBranchPrivilages = async () => {
  return await get(`/User/branchPrivilages`);
};

export const changePassword = async (data) => {
  return await post(`/User/changePassword`, data);
};

export const deleteUser = async (id) => {
  return await deleteData(`/User/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/User/changeActive/${id}`);
};

export const menuVisibility = async () => {
  return await get(`/User/menuVisibility`);
};

export const changeMenuVisibility = async () => {
  return await get(`/User/changeMenuVisibility`);
};

export const changeTableRowCount = async (rowCount) => {
  return await get(`/User/changeTableRowCount/${rowCount}`);
};

export const changeActiveBranch = async (branchId) => {
  return await get(`/User/changeActiveBranch/${branchId}`);
};

export const tableRowCount = async () => {
  return await get(`/User/tableRowCount`);
};

export const connectUserAndOrganization = async (data) => {
  console.log(data);
  return await post(`/User/connectUserAndOrganization`, data);
};

export const connectUserAndStudent = async (data) => {
  console.log(data);
  return await post(`/User/connectUserAndStudent`, data);
};

export const connectUserAndEmployee = async (data) => {
  return await post(`/User/connectUserAndEmployee`, data);
};
