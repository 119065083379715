import { get, post, put, deleteData } from "./core/Requests";

export const getGroupTrainingPlans = async (pageIndex, pageSize) => {
  return await get(`/GroupTrainingPlan`, { pageIndex, pageSize });
};

export const getGroupTrainingPlansByBranch = async (branchId, pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/GroupTrainingPlan/getByBranch/${branchId}`,filter, { pageIndex, pageSize });
};

export const getGroupTrainingPlanDetails = async (GroupTrainingPlanId, pageIndex, pageSize) => {
  return await get(`/GroupTrainingPlan/GroupTrainingPlanDetail/${GroupTrainingPlanId}`, { pageIndex, pageSize });
};


export const getGroupTrainingPlan = async (id) => {
  return await get(`/GroupTrainingPlan/${id}`);
};

export const addGroupTrainingPlan = async (data) => {
  return await post(`/GroupTrainingPlan`, data);
};

export const updateGroupTrainingPlan = async (data) => {
  return await put(`/GroupTrainingPlan`, data);
};

export const deleteGroupTrainingPlan = async (id) => {
  return await deleteData(`/GroupTrainingPlan/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/GroupTrainingPlan/changeActive/${id}`);
};
