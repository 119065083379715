import React, { useState, useEffect } from "react";
import FormInput from "./../../../components/formInput/Index";
import * as employeeService from "./../../../services/EmployeeService";
import { PAGE_INDEX, PAGE_SIZE } from "./../../../constants/Pagination";
import Pagination from "./../../../components/pagination/Index";
import * as Alerts from "./../../../utils/Alerts";
import Loading from "./../../../components/loading/Index";
import { useTokenData } from "../../../helper/useTokenData";
import { Link } from "react-router-dom";
import { useOrganization } from "../../../helper/useOrganization";
import { useMainContext } from "./../../../contexts/MainContext";
import NotFound from "./../../../components/notfound/Index";

export default function SelectModal({ isOpen, handleEmployeeSelectModal, setSelectedEmployee, organizationId }) {
  const { state, setState } = useMainContext();

  const [employees, setEmployees] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ common: null });

  const tokenData = useTokenData();
  const alias = useOrganization();

  useEffect(() => {
    getEmpolyees(1, localStorage.rowCount);
  }, [isOpen]);

  const getEmpolyees = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = organizationId == null || organizationId == undefined ? await employeeService.getEmployeesByBranch(pageIndex, pageSize, localStorage.branch, filter) : await employeeService.getEmployeesByOrganization(pageIndex, pageSize, filter, organizationId);
    if (result.success) {
      setEmployees(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const isSelectRow = (rowId, fullName) => {
    rowId == selectedRow?.rowId ? setSelectedRow(null) : setSelectedRow({ rowId, fullName });
  };
  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getEmpolyees(pageIndex, localStorage.rowCount);
  };

  const handleAction = () => {
    if (selectedRow != null) setSelectedEmployee(selectedRow);
    handleEmployeeSelectModal();
  };

  const Search = (e) => {
    e.preventDefault();
    getEmpolyees(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal  m-block" : "modal"} id="selectEmployeeModalLabel" role="dialog" aria-labelledby="selectEmployeeModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Əməkdaş seç
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <form onSubmit={Search}>
                <div className="col-md-6 navbar-form f-right">
                  <div className="form-group form-search">
                    <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                    <span className="material-input" />
                  </div>
                  <button className="btn btn-white btn-round btn-just-icon" type="submit">
                    <i className="material-icons">search</i>
                  </button>
                </div>
              </form>
              <div className="f-left">
                <Link to={`${alias}/employee/add`} target="_blank">
                  <button className="btn btn-info f-right">Əlavə et</button>
                </Link>
              </div>
              <div className="card" data-background-color={state.theme}>
                <div className="card-content">
                  {loading ? (
                    <Loading />
                  ) : employees?.length > 0 ? (
                    <div className="table-responsive">
                      <div className="table-max-height">
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th className="text-center">#</th>
                              <th>Tam adı</th>
                              <th>Əlaqə nömrəsi</th>
                              {/* <th>Email</th>
                            <th>Ünvan</th>
                            <th>Aktivlik</th>
                            <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {employees.map((item, i) => (
                              <tr key={item?.idEmployee} onClick={() => isSelectRow(item?.idEmployee, `${item?.surname} ${item?.name} ${item?.middleName}`)} className={selectedRow?.rowId === item?.idEmployee ? "selectedRow" : ""}>
                                <td>
                                  <button
                                    style={{ margin: 0 }}
                                    className="btn btn-fill btn-success btn-select"
                                    onClick={() => {
                                      setSelectedEmployee({ rowId: item?.idEmployee, fullName: `${item?.surname} ${item?.name} ${item?.middleName}` });
                                      handleEmployeeSelectModal();
                                    }}
                                  >
                                    Seç
                                  </button>
                                </td>
                                <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                                <td>{`${item?.surname} ${item?.name} ${item?.middleName}`}</td>
                                <td>{item?.phoneNumber}</td>
                                {/* <td>{item?.emailAddress}</td>
                              <td>{item?.address}</td>
                              <td>
                                <span className="btn-label">
                                  <i className="material-icons">{item?.isActive == 1 ? "check" : "close"}</i>
                                </span>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={handleAction}>
              Seç
            </button>
            <button
              className="btn btn-fill btn-danger"
              onClick={() => {
                //     setSelectedEmployee(null);
                handleEmployeeSelectModal();
              }}
            >
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
