import React from "react";
import * as util from "../../utils/Util";

export default function TrAttendanceReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.studentFullName}</td>
      <td>{item?.contractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.journalDetailCount}</td>
      <td>{item?.journalDetailParticipateCount}</td>
      <td>{util.FixedNumber(item?.debtCalculation)}</td>
      <td>{util.FixedNumber(item?.paymentSum)}</td>
    </tr>
  ) : idReport == 5 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.studentFullName}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupDaysCount}</td>
      <td>{item?.journalDetailCount}</td>
      <td>{item?.journalDetailParticipateCount}</td>
      <td>{item?.journalDetailNotParticipateCount}</td>
    </tr>
  ): idReport == 6 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.studentFullName}</td>
      <td>{item?.phoneNumber}</td>
      <td>{item?.contractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{util.FixedNumber(item?.debt)}</td> 
    </tr>
  ) : null;
}


