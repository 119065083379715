import { get, post, put, deleteData } from "./core/Requests";

export const getStudentNotes = async (pageIndex = 0, pageSize = 0, studentId) => {
  return await get(`/StudentNote/notes/${studentId}`, { pageIndex, pageSize });
};

// export const getStudentNotesByBranch = async (pageIndex, pageSize, branchId) => {
//   return await get(`/StudentNote/getByBranch/${branchId}`, {
//     pageIndex,
//     pageSize,
//   });
// };

// export const getStudentNote = async (id) => {
//   return await get(`/StudentNote/${id}`);
// };

export const addStudentNote = async (data) => {
  return await post(`/StudentNote`, data);
};

export const updateStudentNote = async (data) => {
  return await put(`/StudentNote`, data);
};

export const deleteStudentNote = async (id) => {
  return await deleteData(`/StudentNote/${id}`);
};
