import { get, post, put, deleteData } from "./core/Requests";

export const getRooms = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Room`, { pageIndex, pageSize });
};

export const getRoomsByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Room/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

// export const getRoomsByOrganization = async (pageIndex, pageSize) => {
//   return await get(`/Room/getByOrganization`, {
//     pageIndex,
//     pageSize,
//   });
// };

export const getRoom = async (id) => {
  return await get(`/Room/${id}`);
};

export const getForSelect = async (branchId) => {
  return await get(`/Room/GetForSelect/${branchId}`);
};

export const addRoom = async (data) => {
  return await post(`/Room`, data);
};

export const updateRoom = async (data) => {
  return await put(`/Room`, data);
};

export const deleteRoom = async (id) => {
  return await deleteData(`/Room/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Room/changeActive/${id}`);
};
