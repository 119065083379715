import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import * as branchTypeService from "./../../../services/BranchTypeService";
import * as helperService from "./../../../services/HelperService";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [branch, setBranch] = useState({});
  const [branchTypes, setBranchTypes] = useState([]);
  const [debtCalculationTypes, setDebtCalculationTypes] = useState([]);
  const [activeTab, setActiveTab] = useState("general");

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idBranch, idOrganization } = useParams();

  useEffect(() => {
    getBranchTypes();
    getDebtCalculationTypes();
    idBranch && getBranch();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getBranch = async () => {
    let branch = await branchService.getBranch(idBranch);
    if (branch.success) {
      setBranch(branch.data);
    } else {
      Alerts.error(branch.message);
    }
  };

  const getBranchTypes = async () => {
    let result = await branchTypeService.getBranchTypes();
    if (result.success) {
      setBranchTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getDebtCalculationTypes = async () => {
    let result = await helperService.getDebtCalculationTypes();
    if (result.success) {
      setDebtCalculationTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateBranch = async () => {
    let branchModel = branch;
    branchModel.fkIdOrganization = idOrganization && idOrganization;
    let result = idBranch ? await branchService.updateBranch(branchModel) : await branchService.addBranch(branchModel);
    if (result.success) {
      Alerts.successWithBack(result.message, idOrganization ? `${alias}/branch/${idOrganization}` : `${alias}/branch`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setBranch({ ...branch, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setBranch({ ...branch, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idBranch ? (isView ? "Filial məlumatları" : "Filial məlumatlarının redaktəsi") : "Yeni filial"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ MƏLUMATLAR
                    </a>
                  </li>
                  <li className={activeTab == "email" ? "active" : ""} onClick={() => setActiveTab("email")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      EMAİL
                    </a>
                  </li>
                  <li className={activeTab == "sms" ? "active" : ""} onClick={() => setActiveTab("sms")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      SMS
                    </a>
                  </li>
                  <li className={activeTab == "other" ? "active" : ""} onClick={() => setActiveTab("other")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      DİGƏR
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <div className="row">
                      <FormInput style={"col-sm-6"} label={"Filialın adı"} name={"branchName"} value={branch?.branchName} onChange={handleInput} disabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Filialın növü"} selectedValue={branch?.fkIdBranchType} changeSelectHandler={(e) => handleSelect(e, "fkIdBranchType")} options={branchTypes.map((branchType) => ({ label: branchType?.description, value: branchType?.idBranchType }))} isDisabled={isView} />
                      <FormInput style={"col-sm-6"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={branch?.phoneNumber} onChange={handleInput} disabled={isView} />
                      <Select placeholder={"Seç"} style={"col-sm-6"} label={"Borc hesablamaq qaydası"} selectedValue={branch?.fkIdDebtCalculationType} changeSelectHandler={(e) => handleSelect(e, "fkIdDebtCalculationType")} options={debtCalculationTypes.map((item) => ({ label: item?.description, value: item?.idDebtCalculationType }))} isDisabled={isView} />
                      <FormInput style={"col-sm-12"} label={"Ünvan"} name={"address"} value={branch?.address} onChange={handleInput} disabled={isView} />
                      <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={branch?.isActive} onChange={() => setBranch({ ...branch, isActive: branch?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                    </div>
                  </div>
                  <div className={"tab-pane " + (activeTab === "email" ? "active" : "")}>
                    <FormInput style={"col-sm-6"} label={"Server Address (host)"} name={"smtpServer"} value={branch?.smtpServer} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Port"} name={"smtpPort"} value={branch?.smtpPort} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"İstifadəçi adı"} name={"smtpUserName"} value={branch?.smtpUserName} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Şifrə"} name={"smtpPassword"} value={branch?.smtpPassword} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Email bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingEmailNotification"} checked={branch?.enableSendingEmailNotification} onChange={() => setBranch({ ...branch, enableSendingEmailNotification: branch?.enableSendingEmailNotification == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className={"tab-pane " + (activeTab === "sms" ? "active" : "")}>
                    <FormInput style={"col-sm-12"} label={"Sender name"} name={"smsSenderName"} value={branch?.smsSenderName} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Sms bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingSmsNotification"} checked={branch?.enableSendingSmsNotification} onChange={() => setBranch({ ...branch, enableSendingSmsNotification: branch?.enableSendingSmsNotification == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className={"tab-pane " + (activeTab === "other" ? "active" : "")}>
                    <FormCheckbox label={"Valideyn məlumatları"} style={"col-sm-12"} name={"appSettingsParentInfo"} checked={branch?.appSettingsParentInfo} onChange={() => setBranch({ ...branch, appSettingsParentInfo: branch?.appSettingsParentInfo == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                </div>
                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateBranch} />}
                  <Link to={`${alias}/branch/${idOrganization ? idOrganization : ""}`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
