import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as studentService from "../../../services/StudentService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import AddOrUpdateModal from "../../review/modal/AddorUpdateModal";
import * as reviewService from "../../../services/ReviewService";
import * as date from "./../../../utils/DateNow";

import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, studentId }) {
  const { state, setState } = useMainContext();
  const [reviews, setReviews] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getReviews(1, localStorage.rowCount);
  }, []);

  const getReviews = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await reviewService.getReviewsByStudent(pageIndex, pageSize, studentId);
    if (result.success) {
      setReviews(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteReview = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await reviewService.deleteReview(id);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getReviews(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const handleAddModal = () => {
    if (isOpenAddModal) getReviews(1, localStorage.rowCount);
    setIsOpenAddModal(!isOpenAddModal);
  };

  const Search = (e) => {
    e.preventDefault();
    getReviews(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    getReviews(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getReviews(PAGE_INDEX, e.value)} />
                </div>
                {loading ? (
                  <Loading />
                ) : reviews?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Təlimçi</th>
                          <th>Tarix</th>
                          <th>Rəyin növü</th>
                          <th>Başlıq</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {reviews.map((item, i) => (
                          <tr key={item?.idReview}>
                            <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                            <td>{item?.employee?.fullName}</td>
                            <td>{item?.insertDate?.split("T")[0]}</td>
                            <td>{item?.reviewType?.description}</td>
                            <td>{item?.title}</td>
                            <td className="td-actions text-right">
                              {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "review/edit") ? (
                                <button
                                  title="Redaktə"
                                  className="btn btn-info"
                                  onClick={(e) => {
                                    setSelected(item);
                                    handleAddModal();
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                </button>
                              ) : null}
                              {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "review/delete") ? (
                                <button
                                  title="Sil"
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    deleteReview(item?.idReview);
                                  }}
                                >
                                  <i className="material-icons">delete</i>
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                  </div>
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn open-blue"
              onClick={(e) => {
                handleAddModal(e);
              }}
            >
              <i className="material-icons">comment</i> Yeni rəy
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenAddModal ? <AddOrUpdateModal isOpen={isOpenAddModal} handleViewModal={handleAddModal} studentId={studentId} selected={selected} /> : null}
    </div>
  );
}
