import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as caseService from "../../services/CaseService";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [cases, setCases] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };


  useEffect(() => {
    getCases(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);


  const getCases = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await caseService.getCasesByBranch(pageIndex, pageSize, localStorage?.branch);
    if (result.success) {
      setCases(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteCase = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await caseService.deleteCase(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getCases(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };


  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getCases(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`case/delete`}
      editPath={`case/edit`} 
      addPath={`case/add`} deleteRow={deleteCase}
       selectedRow={selectedRow} />     

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">currency_exchange</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Kassaların siyahısı</h4>
          <PageSizeSelect onChange={(e) => getCases(PAGE_INDEX, e.value)} />

          {loading ? (
            <Loading />
          ) : cases?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Kassanın adı</th>
                    <th>Ortaq kassa</th>
                    <th>Bank hesabı</th>
                    <th>Valyuta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cases.map((item, i) => (
                    <tr key={item?.idCase} onClick={() => isSelectRow(item?.idCase)} className={selectedRow === item?.idCase ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.description}</td>
                      <td>{item?.common == 1 ? <span class="material-icons color-green">done</span> : <span class="material-icons color-red">close</span>}</td>
                      <td>{item?.bankAccount == 1 ? <span class="material-icons color-green">done</span> : <span class="material-icons color-red">close</span>}</td>
                      <td>{item?.currency}</td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/case/view/${item?.idCase}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
 </div>
  );
}
