import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as assignmentService from "./../../services/AssignmentService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import AssignmentDetailListModal from "./../assignment/modal/AssignmentDetailListModal";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import * as date from "./../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "./../../components/actionButton/Index";
import * as UserTypes from "./../../constants/UserType";
import { URL } from "./../../services/core/Urls";

export default function Index() {
  const { state, setState } = useMainContext();
  const [assignments, setAssignments] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpenAssignmentDetailModal, setIsOpenAssignmentDetailModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleAssignmentDetailModal = () => {
    if (isOpenAssignmentDetailModal) setSelectedRow(0);
    setIsOpenAssignmentDetailModal(!isOpenAssignmentDetailModal);
  };

  useEffect(() => {
    setFilter({ startDate: date.Now(-30), endDate: date.Now() });
    getAssignments(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getAssignments = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await assignmentService.getAssignmentsByBranch(localStorage.branch, pageIndex, pageSize, filter);
      if (result.success) {
        setAssignments(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const deleteAssignment = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await assignmentService.deleteAssignment(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getAssignments(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getAssignments(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getAssignments(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId !=UserTypes.STUDENT_USER && (
      <ActionButtons 
      deletePath={`assignment/delete`}
      editPath={`assignment/edit`} 
      addPath={`assignment/add`}
      assignmentDetailModal={setIsOpenAssignmentDetailModal}
      isOpenAssignmentDetail={isOpenAssignmentDetailModal} 
      deleteRow={deleteAssignment}
       selectedRow={selectedRow} />   
        
        // <>
        //   <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteAssignment(selectedRow)}>
        //     Sil
        //   </button>
        //   <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right" onClick={() => setIsOpenAssignmentDetailModal(!isOpenAssignmentDetailModal)}>
        //     Tapşırığın icra vəziyyəti
        //   </button>
        //   <Link to={`${alias}/assignment/edit`}>
        //     <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
        //       Redaktə et
        //     </button>
        //   </Link>
        //   <Link to={`${alias}/assignment/add`}>
        //     <button className="btn btn-info f-right">Əlavə et</button>
        //   </Link>
        // </>
      )}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Tapşırıqların siyahısı</h4>

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getAssignments(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : assignments?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Qrup</th>
                    <th>Təlimçi</th>
                    <th>Başlıq</th>
                    <th>Tapşırıq tarixi</th>
                    <th>Son icra tarixi</th>
                    <th>Fayl sayı</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {assignments.map((item, i) => (
                    <tr key={item?.idAssignment} onClick={() => isSelectRow(item?.idAssignment)} className={selectedRow === item?.idAssignment ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.group}</td>
                      <td>{item?.trainer}</td>
                      <td>{item?.title}</td>
                      <td>{item?.assignmentDate}</td>
                      <td>{item?.assignmentDeadline}</td>
                      <td>{item?.fileCount}</td>
                      <td className="td-actions text-right">
                      <button disabled={item?.fileCount == 0} className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/assignments/downloadAll/6/${item.idAssignment}`)}>
                          <i className="material-icons">download</i>
                        </button>
                        <Link to={`${alias}${id}/assignment/view/${item?.idAssignment}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
      {isOpenAssignmentDetailModal ? <AssignmentDetailListModal isOpen={isOpenAssignmentDetailModal} handleViewModal={handleAssignmentDetailModal} header={"Tapşırığın icra vəziyyəti"} assignmentId={selectedRow} /> : null}
    </div>
  );
}
