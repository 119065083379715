import React from "react";

export default function Index({ pagination, changePage, rowText="məlumat" }) {
  //  localStorage.setItem("@page", pagination.pageIndex);
  return (
    <div style={{ float: "right" }}>
      <ul class="pagination pagination-info">
        <li className="active">
          <a style={{textTransform:'none'}}>{pagination.totalRecordCount} {rowText}</a>
        </li>
        <li>
          <a href="javascript:void(0)" className={!pagination.hasPreviousPage ? "disabled" : ""} onClick={() => changePage(pagination.pageIndex - 1)}>
            <span class="material-icons">navigate_before</span>
            <div class="ripple-container"></div>
          </a>
        </li>
        {/* <li>
          <a href="#">
            ...<div class="ripple-container"></div>
          </a>
        </li> */}
        {[...Array(pagination.totalPageCount > 0 ? pagination.totalPageCount : 0)].map((e, i) => (
          <li key={i} className={pagination.pageIndex == i + 1 ? "active" : ""}>
            <a href="javascript:void(0)" onClick={() => changePage(i + 1)}>
              {i + 1}
            </a>
          </li>
        ))}
        {/* <li>
          <a href="#">
            ...<div class="ripple-container"></div>
          </a>
        </li> */}
        <li>
          <a href="#" className={!pagination.hasNextPage ? "disabled" : ""} onClick={() => changePage(pagination.pageIndex + 1)}>
            <span class="material-icons">navigate_next</span>
            <div class="ripple-container"></div>
          </a>
        </li>
      </ul>
    </div>
  );
}
