import { get, post, put, deleteData } from "./core/Requests";

export const getExpenseTypes = async () => {
  return await get(`/ExpenseType`);
};

export const getExpenseType = async (id) => {
  return await get(`/ExpenseType/${id}`);
};

export const addExpenseType = async (data) => {
  return await post(`/ExpenseType`, data);
};

