import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";

export default function PieChart({ data, color }) {
  const config = {
    appendPadding: 5,
    height:350,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    color,
    label: {
      type: "spider",
      content: "{percentage}",
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  return <Pie {...config} />;
}
