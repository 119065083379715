import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as employeeService from "./../../services/EmployeeService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import FinanceOperationListModal from "./../financeOperation/modal/ListModal";
import ActionButtons from "./../../components/actionButton/Index";
import FilterTableRow from "./../../components/tableFilterRow/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [employees, setEmployees] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [loading, setLoading] = useState(false);
  const [isOpenFinanceOperationListModal, setIsOpenFinanceOperationListModal] = useState(false);
  const [isFirstRendered, setIsFirstRendered] = useState(true);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleFinanceOperationListModal = (e) => {
    e.stopPropagation();
    if (isOpenFinanceOperationListModal) setSelectedRow(0);
    setIsOpenFinanceOperationListModal(!isOpenFinanceOperationListModal);
  };

  useEffect(() => {
    getEmployees(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  useEffect(() => {
    if (isFirstRendered) {
      setIsFirstRendered(false);
    } else {
      if (state?.employeeFilter != null) {
        const timer = setTimeout(() => {
          getEmployees(PAGE_INDEX, localStorage.rowCount);
        }, 1500);

        return () => clearTimeout(timer);
      }
    }
  }, [state?.employeeFilter]);

  const getEmployees = async (pageIndex, pageSize) => {
    setLoading(true);
    //   let result = await employeeService.getEmployees(pageIndex, pageSize, filter);
    let result = await employeeService.getEmployeesByBranch(pageIndex, pageSize, localStorage.branch, state?.employeeFilter);
    if (result.success) {
      setEmployees(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteEmployee = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await employeeService.deleteEmployee(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getEmployees(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idEmployee) => {
    let result = await employeeService.changeActive(idEmployee);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getEmployees(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getEmployees(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getEmployees(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons deletePath={`employee/delete`} editPath={`employee/edit`} addPath={`employee/add`} exportPath={`employee/export`} deleteRow={deleteEmployee} selectedRow={selectedRow} />

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">badge</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Əməkdaşların siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getEmployees(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={state?.employeeFilter?.common} onChange={(e) => setState({ ...state, employeeFilter: { ...state?.employeeFilter, common: e.target.value } })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>Əlaqə nömrəsi</th>
                      <th>Email</th>
                      <th>Vəzifə</th>
                      <th>Aktivlik</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FilterTableRow page={"employeeFilter"} />
                    {employees?.length > 0 ? (
                      employees.map((item, i) => (
                        <tr key={item?.idEmployee} onClick={() => isSelectRow(item?.idEmployee)} className={selectedRow === item?.idEmployee ? "selectedRow" : ""}>
                          <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                          <td>{item?.surname + " " + item?.name + " " + item?.middleName}</td>
                          <td>{item?.phoneNumber}</td>
                          <td>{item?.emailAddress}</td>
                          <td>{item?.postName}</td>
                          <td>
                            <div className="togglebutton">
                              <label>
                                <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idEmployee)} />
                                <span className="toggle" />
                              </label>
                            </div>
                          </td>
                          <td className="td-actions text-right">
                            <button
                              title="Ödənişlər"
                              className="btn btn-success"
                              onClick={(e) => {
                                setSelectedRow(item?.idEmployee);
                                handleFinanceOperationListModal(e);
                              }}
                            >
                              <i className="material-icons">paid</i>
                            </button>
                            <Link to={`${alias}/employee/view/${item?.idEmployee}`}>
                              <button className="btn btn-info" title="Baxış">
                                <i className="material-icons">visibility</i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={6}>
                        <NotFound />
                      </td>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          )}
        </div>
      </div>
      {isOpenFinanceOperationListModal ? <FinanceOperationListModal isOpen={isOpenFinanceOperationListModal} handleViewModal={handleFinanceOperationListModal} header={"Əməkdaş ödənişləri"} idFinanceOperationType={5} filter={{ idEmployee: selectedRow }} /> : null}
    </div>
  );
}
