import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as groupTrainingPlanService from "./../../../services/GroupTrainingPlanService";
import * as groupService from "./../../../services/GroupService";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormTextareaInput from "./../../../components/formTextareaInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";
import { useStudent } from "../../../helper/useStudent";

export default function GroupTrainingPlanListModal({ isOpen, handleViewModal, groupId }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [groupTrainingPlan, setGroupTrainingPlan] = useState({});
  const [groups, setGroups] = useState([]);

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idGroupTrainingPlan } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idGroupTrainingPlan && getGroupTrainingPlan();
  }, []);

  useEffect(() => {
    getGroups();
  }, [localStorage.branch]);

  const getGroupTrainingPlan = async () => {
    let result = await groupTrainingPlanService.getGroupTrainingPlan(idGroupTrainingPlan);
    if (result.success) {
      setGroupTrainingPlan(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroups = async () => {
    let result = await groupService.getForSelect(localStorage.branch);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16); // format as "YYYY-MM-DDTHH:MM"
  };

  const addGroupTrainingPlan = async () => {
    groupTrainingPlan.fkIdGroup = groupId;
    await groupTrainingPlanService.addGroupTrainingPlan({ ...groupTrainingPlan, fkIdBranch: localStorage.branch });
    handleViewModal();
  };

  const handleInput = (e) => {
    setGroupTrainingPlan({ ...groupTrainingPlan, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setGroupTrainingPlan({ ...groupTrainingPlan, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Yeni qrup təlim planı
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <FormInput style={"col-sm-6"} label={"Başlıq"} name={"title"} required={true} value={groupTrainingPlan?.title} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} min={new Date(date.Now())} label={"Plan tarixi"} required={true} name={"planDate"} type={"date"} value={groupTrainingPlan?.planDate} onChange={handleInput} disabled={isView} />
                  <FormTextareaInput style={"col-sm-12"} rows={5} label={"Məzmun"} name={"description"} value={groupTrainingPlan?.description} onChange={handleInput} disabled={isView} />
                  </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addGroupTrainingPlan} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
