import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as notificationService from "../../services/NotificationService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import * as date from "./../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "./../../components/actionButton/Index";
import * as UserTypes from "./../../constants/UserType";
import AddModal from "./modal/AddModal";

export default function Index() {
  const { state, setState } = useMainContext();
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({ common: null, startDate: date.Now(-30), endDate: date.Now() });
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleAddModal = () => {
    if (isOpenAddModal) getLogs(PAGE_INDEX, localStorage.rowCount);
    setIsOpenAddModal(!isOpenAddModal);
  };

  useEffect(() => {
    getLogs(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getLogs = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await notificationService.getLogsByBranch(pageIndex, pageSize, localStorage.branch);
    if (result.success) {
      setLogs(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const Search = (e) => {
    e.preventDefault();
    getLogs(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getLogs(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <button className="btn btn-info f-right" onClick={handleAddModal}>
        Yeni bildiriş
      </button>
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">notifications_active</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Bildiriş loqlarının siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getLogs(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />

                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : logs?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Şablonun adı</th>
                    <th>Şərt</th>
                    <th>Tarix</th>
                    <th>Qəbul edənlərin sayı</th>
                    <th>İstifadəçi</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((item, i) => (
                    <tr key={item?.idReview} onClick={() => isSelectRow(item?.idBranchNotificationLog)} className={selectedRow === item?.idBranchNotificationLog ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.notificationTemplateType}</td>
                      <td>{item?.notificationCondition}</td>
                      <td>{item?.notificationDate?.split("T")[0]}</td>
                      <td>{item?.recipientCount}</td>
                      <td>{item?.username}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
      {isOpenAddModal ? <AddModal isOpen={isOpenAddModal} handleViewModal={handleAddModal} header={"Rəylər"} studentId={selectedRow} /> : null}
    </div>
  );
}
