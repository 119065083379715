import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as incomeService from "./../../services/IncomeService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
//import IncomeAddModal from "./modal/AddModal";
//import IncomeUpdateModal from "./modal/UpdateModal";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [incomes, setIncomes] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
 // const [isOpenAddModal, setIsOpenAddModal] = useState(false);
 // const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  // const handleAddModal = () => {
  //   if (isOpenAddModal) setSelectedRow(0);
  //   setIsOpenAddModal(!isOpenAddModal);
  // };

  // const handleUpdateModal = () => {
  //   if (isOpenUpdateModal) setSelectedRow(0);
  //   setIsOpenUpdateModal(!isOpenUpdateModal);
  // };

  useEffect(() => {
    getIncomes(state.currentPage, localStorage.rowCount);
  }, []);

  const getIncomes = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await incomeService.getIncomes(pageIndex, pageSize);
    if (result.success) {
      setIncomes(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteIncome = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await incomeService.deleteIncome(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getIncomes(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idIncome) => {
    let result = await incomeService.changeActive(idIncome);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getIncomes(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getIncomes(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`income/delete`}
      editPath={`income/edit`} 
      addPath={`income/add`} deleteRow={deleteIncome}
       selectedRow={selectedRow} /> 
     
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">paid</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Əlavə gəlir adlarının siyahısı</h4>
                    <PageSizeSelect onChange={(e)=> getIncomes(PAGE_INDEX, e.value)} />

          {loading ? (
            <Loading />
          ) : incomes?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Əlavə gəlir adı</th>
                    <th>Aktivlik</th>
                  </tr>
                </thead>
                <tbody>
                  {incomes.map((item, i) => (
                    <tr key={item?.idIncome} onClick={() => isSelectRow(item?.idIncome)} className={selectedRow === item?.idIncome ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.description}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idIncome)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>

      {/* {isOpenAddModal ? <IncomeAddModal isOpen={isOpenAddModal} handleModal={handleAddModal} id={selectedRow} /> : null}
      {isOpenUpdateModal ? <IncomeUpdateModal isOpen={isOpenUpdateModal} handleModal={handleUpdateModal} id={selectedRow} /> : null} */}
    </div>
  );
}
