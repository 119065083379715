import React, { useState, useEffect } from "react";
import FormInput from "./../../../components/formInput/Index";
import * as caseService from "./../../../services/CaseService";
import { PAGE_INDEX, PAGE_SIZE } from "./../../../constants/Pagination";
import Pagination from "./../../../components/pagination/Index";
import * as Alerts from "./../../../utils/Alerts";
import Loading from "./../../../components/loading/Index";
import { useTokenData } from "../../../helper/useTokenData";
import { Link } from "react-router-dom";
import { useOrganization } from "../../../helper/useOrganization";
import { useMainContext } from "./../../../contexts/MainContext";
import NotFound from "./../../../components/notfound/Index";

export default function SelectModal({ isOpen, handleSelectModal, setSelected }) {
  const { state, setState } = useMainContext();

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ common: null });

  const tokenData = useTokenData();
  const alias = useOrganization();

  useEffect(() => {
    getData(1, localStorage.rowCount);
  }, [isOpen]);

  const getData = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await caseService.getCasesByBranch(pageIndex, pageSize, localStorage?.branch);
    if (result.success) {
      setData(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const isSelectRow = (rowId, description) => {
    rowId == selectedRow?.rowId ? setSelectedRow(null) : setSelectedRow({ rowId, description });
  };
  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getData(pageIndex, localStorage.rowCount);
  };

  const handleAction = () => {
    setSelected(selectedRow);
    handleSelectModal();
  };

  const Search = (e) => {
    e.preventDefault();
    getData(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal  m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Kassa seç
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <form onSubmit={Search}>
                <div className="col-md-6 navbar-form f-right">
                  <div className="form-group form-search">
                    <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                    <span className="material-input" />
                  </div>
                  <button className="btn btn-white btn-round btn-just-icon" type="submit">
                    <i className="material-icons">search</i>
                  </button>
                </div>
              </form>
              <div className="card" data-background-color={state.theme}>
                <div className="card-content">
                  {loading ? ( 
                    <Loading />
                  ) : data?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>#</th>
                            <th>Kassa adı</th>
                            <th>Valyuta</th>                            
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, i) => (
                            <tr key={item?.idCase} onClick={() => isSelectRow(item?.idCase, item?.description)} className={selectedRow?.rowId === item?.idCase ? "selectedRow" : ""}>
                              <td>
                                <button
                                  style={{ margin: 0 }}
                                  className="btn btn-fill btn-success btn-select"
                                  onClick={() => {
                                    setSelected({ rowId: item?.idCase, description: item?.description });
                                    handleSelectModal();
                                  }}
                                >
                                  Seç
                                </button>
                              </td>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.description}</td>
                              <td>{item?.currency}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-success" onClick={handleAction}>
              Seç
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleAction}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
