import React from "react";
import * as util from "../../utils/Util";

export default function TrExpenseReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item.expenseType}</td>
      <td>{item.expense}</td>
      <td>{item.count}</td>
      <td>{util.FixedNumber(item.sum)}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.date?.split("T")[0]}</td>
      <td>{item?.count}</td>
      <td>{util.FixedNumber(item?.sum)}</td>
    </tr>
  ) : idReport == 3 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.date?.split("T")[0]}</td>
      <td>{item?.et01_count}</td>
      <td>{util.FixedNumber(item?.et01_sum)}</td>
      <td>{item?.et02_count}</td>
      <td>{util.FixedNumber(item?.et02_sum)}</td>
      <td>{item?.et03_count}</td>
      <td>{util.FixedNumber(item?.et03_sum)}</td>
      <td>{item?.et04_count}</td>
      <td>{util.FixedNumber(item?.et04_sum)}</td>
      <td>{item?.et05_count}</td>
      <td>{util.FixedNumber(item?.et05_sum)}</td>
      <td>{item?.count}</td>
      <td>{util.FixedNumber(item?.sum)}</td>
    </tr>
  )  : null;
}
