import React from "react";
import { useTokenData } from "./../../helper/useTokenData";
import OrganizationDashboard from "./OrganizationDashbard";
import StudentDashboard from "./StudentDashboard";
import TrainerDashboard from "./TrainerDashboard";
import * as UserTypes from "./../../constants/UserType";

export default function Index() {
  const tokenData = useTokenData();
  return tokenData.userTypeId == UserTypes.STUDENT_USER ? <StudentDashboard /> : tokenData.userTypeId == UserTypes.TRAINER_USER ? <TrainerDashboard /> : <OrganizationDashboard />;
}
