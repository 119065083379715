import { get, post, put, deleteData } from "./core/Requests";

export const getPosts = async (pageIndex=0, pageSize=0) => {
  return await get(`/Post`, { pageIndex, pageSize });
};

export const getPostsByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Post/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

export const getForSelect = async () => {
  return await get(`/Post/getForSelect`);
};

export const getPost = async (id) => {
  return await get(`/Post/${id}`);
};

export const addPost = async (data) => {
  return await post(`/Post`, data);
};

export const updatePost = async (data) => {
  return await put(`/Post`, data);
};

export const deletePost = async (id) => {
  return await deleteData(`/Post/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Post/changeActive/${id}`);
};
