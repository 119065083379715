import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as departmentService from "./../../services/DepartmentService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [departments, setDepartments] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };


  useEffect(() => {
    getDepartments(state.currentPage, localStorage.rowCount);
  }, []);


  const getDepartments = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await departmentService.getDepartments(pageIndex, pageSize);
    if (result.success) {
      setDepartments(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteDepartment = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await departmentService.deleteDepartment(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getDepartments(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idDepartment) => {
    let result = await departmentService.changeActive(idDepartment);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getDepartments(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getDepartments(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons
      deletePath={`department/delete`}
      editPath={`department/edit`} 
      addPath={`department/add`} deleteRow={deleteDepartment}
       selectedRow={selectedRow} />       

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">reduce_capacity</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Şöbələrin siyahısı</h4>
          <PageSizeSelect onChange={(e) => getDepartments(PAGE_INDEX, e.value)} />

          {loading ? (
            <Loading />
          ) : departments?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Şöbənin adı</th>
                    <th>Qeyd</th>
                    <th>Aktivlik</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {departments.map((item, i) => (
                    <tr key={item?.idDepartment} onClick={() => isSelectRow(item?.idDepartment)} className={selectedRow === item?.idDepartment ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.departmentName}</td>
                      <td>{item?.description}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idDepartment)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/department/view/${item?.idDepartment}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
 </div>
  );
}
