import React from "react";
import { useMainContext } from "./../../contexts/MainContext";

export default function Index({ style, checked, name, onChange, label, disabled }) {
  const { state, setState } = useMainContext();
  return (
    <div className={"checkbox " + style}>
      <label style={{ color: state.theme == "black" ? "#fff" : "#aaa" }}>
        <input type="checkbox" checked={checked} name={name} onChange={onChange} disabled={disabled} data-color={state.theme} />
        <span className="checkbox-material">
          <span className="check" />
        </span>
        {label}
      </label>
    </div>
  );
}
