import { get, post, put, deleteData } from "./core/Requests";

export const getGroups = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Group`, { pageIndex, pageSize });
};

export const getGroupsByBranch = async (pageIndex = 0, pageSize = 0, branchId, filter) => {
  return await post(`/Group/getByBranch/${branchId !== null && branchId != undefined && branchId !== "null" ? branchId : ""}`, filter, { pageIndex, pageSize });
};

export const getGroupsForStudent = async (pageIndex = 0, pageSize = 0, studentId) => {
  return await get(`/Group/getByStudent/${studentId}`, { pageIndex, pageSize });
};

export const getTrainerGroups = async (pageIndex = 0, pageSize = 0, trainerId) => {
  return await get(`/Group/getTrainerGroups/${trainerId}`, { pageIndex, pageSize });
};

export const getStudentGroups = async (pageIndex = 0, pageSize = 0, studentId) => {
  return await get(`/Group/getStudentGroups/${studentId}`, { pageIndex, pageSize });
};

export const getForSelect = async (branchId) => {
  return await get(`/Group/getForSelect${branchId != null && branchId != undefined ? `?branchId=${branchId}` : ""}`);
};

export const getGroup = async (id) => {
  return await get(`/Group/${id}`);
};

export const getGroupTrainerAndAssisent = async (id) => {
  return await get(`/Group/trainers/${id}`);
};

export const getGroupPlans = async (groupId) => {
  return await get(`/Group/getGroupPlans/${groupId}`);
};

export const addGroup = async (data) => {
  return await post(`/Group`, data);
};

export const updateGroup = async (data) => {
  return await put(`/Group`, data);
};

export const deleteGroup = async (id) => {
  return await deleteData(`/Group/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Group/changeActive/${id}`);
};
