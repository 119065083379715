import { get, post, put, deleteData } from "./core/Requests";

export const getJournals = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Journal`, { pageIndex, pageSize });
};

export const getJournalsByBranch = async (pageIndex = 0, pageSize = 0, branchId, filter) => {
  return await post(`/Journal/getByBranch/${branchId}`, filter, { pageIndex, pageSize });
};

export const getForSelect = async (branchId) => {
  return await get(`/Journal/getForSelect${branchId != null && branchId != undefined ? `?branchId=${branchId}` : ""}`);
};

export const getJournal = async (id) => {
  return await get(`/Journal/${id}`);
};

export const addJournal = async (data) => {
  return await post(`/Journal`, data);
};

export const updateJournal = async (data) => {
  return await put(`/Journal`, data);
};

export const deleteJournal = async (id) => {
  return await deleteData(`/Journal/${id}`);
};

export const getJournalDetails = async (pageIndex = 0, pageSize = 0, journalId) => {
  return await get(`/journal/getJournalDetails/${journalId}`, { pageIndex, pageSize });
};

export const getAttendances = async (pageIndex = 0, pageSize = 0, data) => {
  return await post(`/journal/getAttendances`, data, { pageIndex, pageSize });
};

export const getAttendancesByJournal = async (pageIndex = 0, pageSize = 0, journalId) => {
  return await get(`/journal/getAttendancesByJournal/${journalId}`, { pageIndex, pageSize });
};

export const getStudentAttendances = async (pageIndex = 0, pageSize = 0, studentId) => {
  return await get(`/journal/getAttendances${studentId}`, { pageIndex, pageSize });
};

// export const changeActive = async (id) => {
//   return await get(`/Journal/changeActive/${id}`);
// };
