import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as reviewService from "../../../services/ReviewService";
import * as Alerts from "../../../utils/Alerts";
import Select from "../../../components/select/Index";
import FormInput from "../../../components/formInput/Index";
import FormTextareaInput from "../../../components/formTextareaInput/Index";
import FormCheckbox from "../../../components/formCheckbox/Index";
import CommitButton from "../../../components/commitButton/Index";
import * as date from "../../../utils/DateNow";
import * as UserTypeEnum from "./../../../constants/UserType";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";

export default function AddOrUpdateModal({ isOpen, handleViewModal, studentId, selected }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [review, setReview] = useState({});
  const [reviewtypes, setReviewTypes] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);

  useEffect(() => {
    getReviewTypes();
    if (selected != null && selected != undefined) {
      selected.fkIdReviewType = selected?.reviewType?.idReviewType;
      setReview(selected);
      setSelectedEmployee({ rowId: selected?.employee?.idEmployee, fullName: selected?.employee?.fullName });
    }
  }, []);

  const getReviewTypes = async () => {
    let result = await reviewService.getReviewTypes();
    if (result.success) {
      setReviewTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateReview = async () => {
    if (localStorage.userTypeId == UserTypeEnum.TRAINER_USER) {
      review.fkIdEmployee = localStorage.employeeId;
    } else {
      review.fkIdEmployee = selectedEmployee?.rowId;
    }
    review.fkIdStudent = studentId;
    let result = selected != null && selected != undefined ? await reviewService.updateReview({ ...review, fkIdBranch: localStorage.branch }) : await reviewService.addReview({ ...review, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.success(result.message);
      handleViewModal();
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setReview({ ...review, [name]: e.value });
  };

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Rəy
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <div className="row">
                    {localStorage.userTypeId != UserTypeEnum.TRAINER_USER ? (
                      <>
                        <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Təlimçinin tam adı"} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedEmployee != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                            {"Təlimçi seç"}
                          </button>
                        </div>
                      </>
                    ) : null}

                    <Select placeholder={"Seç"} style={"col-sm-6"} label={"Rəyin növü"} selectedValue={review?.fkIdReviewType} changeSelectHandler={(e) => handleSelect(e, "fkIdReviewType")} options={reviewtypes.map((reviewtype) => ({ label: reviewtype?.description, value: reviewtype?.idReviewType }))} isDisabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Başlıq"} name={"title"} required={true} value={review?.title} onChange={handleInput} disabled={isView} />
                    <FormTextareaInput style={"col-sm-12"} rows={5} label={"Rəy"} name={"review1"} value={review?.review1} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Ümumi rəy"} style={"col-sm-12"} name={"isPublic"} checked={review?.isPublic} onChange={() => setReview({ ...review, isPublic: review?.isPublic == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addOrUpdateReview} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
