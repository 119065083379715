import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as expenseService from "./../../services/ExpenseService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [expenses, setExpenses] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getExpenses(state.currentPage, localStorage.rowCount);
  }, []);

  const getExpenses = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await expenseService.getExpensesByOrganization(pageIndex, pageSize);
    if (result.success) {
      setExpenses(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExpense = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await expenseService.deleteExpense(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getExpenses(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idExpense) => {
    let result = await expenseService.changeActive(idExpense);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getExpenses(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getExpenses(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`expense/delete`}
      editPath={`expense/edit`} 
      addPath={`expense/add`} deleteRow={deleteExpense}
       selectedRow={selectedRow} /> 

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">energy_savings_leaf</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Xərc adlarının siyahısı</h4>
          <PageSizeSelect onChange={(e)=> getExpenses(PAGE_INDEX, e.value)} />
          {loading ? (
            <Loading />
          ) : expenses?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Xərc adı</th>
                    <th>Xərc növü</th>
                    <th>Aktivlik</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.map((item,i) => (
                    <tr key={item?.idExpense} onClick={() => isSelectRow(item?.idExpense)} className={selectedRow === item?.idExpense ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.description}</td>
                      <td>{item?.expenseType}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idExpense)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/expense/view/${item?.idExpense}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
