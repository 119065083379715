import az from './az/az.json'
import en from './en/en.json'
import ru from './ru/ru.json'

const languages = {
    "az": az,
    "en": en,
    // "ru":ru
}

export default languages