import { get, post, put, deleteData } from "./core/Requests";

export const getAssignments = async (pageIndex, pageSize) => {
  return await get(`/Assignment`, { pageIndex, pageSize });
};

export const getAssignmentsByBranch = async (branchId, pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Assignment/getByBranch/${branchId}`,filter, { pageIndex, pageSize });
};

export const getAssignmentDetails = async (assignmentId, pageIndex, pageSize) => {
  return await get(`/Assignment/assignmentDetail/${assignmentId}`, { pageIndex, pageSize });
};

export const updateAssignmentDetails = async (data) => {
  return await put(`/Assignment/assignmentDetail`, data);
};

export const getAssignment = async (id) => {
  return await get(`/Assignment/${id}`);
};

export const addAssignment = async (data) => {
  return await post(`/Assignment`, data);
};

export const updateAssignment = async (data) => {
  return await put(`/Assignment`, data);
};

export const deleteAssignment = async (id) => {
  return await deleteData(`/Assignment/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Assignment/changeActive/${id}`);
};
