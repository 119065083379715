import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as trainingMaterialService from "./../../../services/TrainingMaterialService";
import * as groupService from "./../../../services/GroupService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";
import * as attachmentService from "./../../../services/AttachmentService";
import { useStudent } from "../../../helper/useStudent";
import { URL } from "./../../../services/core/Urls";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [trainingMaterial, setTrainingMaterial] = useState({ trainingMaterialDate: date.Now() });
  const [groups, setGroups] = useState([]);
  const [attachs, setAttachs] = useState([]);
 
  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idTrainingMaterial } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idTrainingMaterial && getTrainingMaterial();
  }, []);

  useEffect(() => {
    getGroups();
  }, [localStorage.branch]);

  const getTrainingMaterial = async () => {
    let result = await trainingMaterialService.getTrainingMaterial(idTrainingMaterial);
    if (result.success) {
      setTrainingMaterial(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroups = async () => {
    let result = await groupService.getForSelect(localStorage.branch);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteExsistAttachment = async (idAttachment) => {
    let result = await attachmentService.deleteAttachment(idAttachment);
    if (result.success) {
      var exsistAttach = trainingMaterial.attachments.filter((x) => x.idAttachment != idAttachment);
      setTrainingMaterial({ ...trainingMaterial, attachments: exsistAttach });
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateTrainingMaterial = async () => {
    let result = idTrainingMaterial ? await trainingMaterialService.updateTrainingMaterial({ ...trainingMaterial, fkIdBranch: localStorage.branch }) : await trainingMaterialService.addTrainingMaterial({ ...trainingMaterial, fkIdBranch: localStorage.branch });
    if (result.success) {
      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 7);
      formData.append("fkIdAttachmentSoruce", result.data?.idTrainingMaterial);
      attachs.forEach((attach) => {
        formData.append("files", attach);
      });
      await attachmentService.addAttachments(formData, "trainingMaterials");

      Alerts.successWithBack(result.message, `${alias}/trainingMaterial`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setTrainingMaterial({ ...trainingMaterial, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setTrainingMaterial({ ...trainingMaterial, [name]: e.value });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAttachs([...attachs, event.target.files[0]]);
    }
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">attach_file</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idTrainingMaterial ? (isView ? "Təlim materialı məlumatları" : "Təlim materialı məlumatlarının redaktəsi") : "Yeni təlim materialı"}</h4>

                <div className="row">
                  <Select placeholder={"Seç"} style={"col-sm-6 z-100"} label={"Qrup"} required={true} selectedValue={trainingMaterial?.fkIdGroup} changeSelectHandler={(e) => handleSelect(e, "fkIdGroup")} options={groups.map((group) => ({ label: group?.groupName, value: group?.idGroup }))} isDisabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Başlıq"} name={"title"} required={true} value={trainingMaterial?.title} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Tarix"} name={"trainingMaterialDate"} required={true} type={"date"} value={trainingMaterial?.trainingMaterialDate} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Məzmun"} name={"description"} value={trainingMaterial?.description} onChange={handleInput} disabled={isView} />
                </div>

                <br />
                {isView ? null : (
                  <div className="row">
                    <span className="btn-file pl-15">
                      <button type="submit" className="btn btn-fill btn-info ">
                        Fayl seç
                      </button>
                      <input type="file" onChange={onImageChange} />
                    </span>
                  </div>
                )}
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Faylın adı</th>
                        <th>Faylın tipi</th>
                        <th>Faylın ölçüsü</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainingMaterial?.attachments?.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{i + 1}</td>
                          <td>{item?.originalFileName}</td>
                          <td>{item?.fileType}</td>
                          <td></td>
                          <td className="td-actions text-right">
                            {isView ?  <button className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/trainingmaterials/${item?.fileName}/download`)}>
                                <i className="material-icons">download</i>
                              </button> : (
                              <button className="btn btn-danger" onClick={() => deleteExsistAttachment(item?.idAttachment)}>
                                <i className="material-icons">delete</i>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}

                      {attachs.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{i + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item?.type}</td>
                          <td>{(item?.size / (1024 * 1024)).toFixed(2)} Mb</td>
                          <td className="td-actions text-right">
                            {isView ? null : (
                              <button className="btn btn-danger" onClick={() => setAttachs(attachs.filter((x) => x != item))}>
                                <i className="material-icons">delete</i>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <br />

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateTrainingMaterial} />}
                  <Link to={`${alias}${id}/trainingMaterial`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
