import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as sysUserService from "./../../../services/SysUserService";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [sysUser, setSysUser] = useState({});
  const [repeatPassword, setRepeatPassword] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { idSysUser } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idSysUser && getSysUser();
  }, []);

  const getSysUser = async () => {
    let result = await sysUserService.getSysUser(idSysUser);
    if (result.success) {
      setSysUser(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateSysUser = async () => {
    if (idSysUser || sysUser?.password === repeatPassword) {
      let result = idSysUser ? await sysUserService.updateSysUser(sysUser) : await sysUserService.addSysUser(sysUser);
      if (result.success) {
        Alerts.successWithBack(result.message, `/sysUser`, navigate);
      } else {
        Alerts.error(result.message);
      }
    } else Alerts.error("Şifrə və təkrar şifrə uyğun deyil!");
  };

  const handleInput = (e) => {
    setSysUser({ ...sysUser, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idSysUser ? (isView ? "İnzibatçı məlumatları" : "İnzibatçı məlumatlarının redaktəsi") : "Yeni inzibatçı"}</h4>

                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-12"} label={"İstifadəçi adı"} name={"userName"} value={sysUser?.userName} onChange={handleInput} disabled={isView} />
                    {!idSysUser && (
                      <>
                        <FormInput style={"col-sm-6"} label={"Şifrə"} name={"password"} value={sysUser?.password} type={"password"} onChange={handleInput} />
                        <FormInput style={"col-sm-6"} label={"Şifrə (təkrar)"} name={"passwordRepeat"} value={repeatPassword} type={"password"} onChange={(e) => setRepeatPassword(e.target.value)} />
                      </>
                    )}
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={sysUser?.isActive} onChange={() => setSysUser({ ...sysUser, isActive: sysUser.isActive == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Admin"} style={"col-sm-12"} name={"isAdmin"} checked={sysUser?.isAdmin} onChange={() => setSysUser({ ...sysUser, isAdmin: sysUser.isAdmin == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && (
                      <button type="button" className="btn btn-fill btn-success" onClick={addOrUpdateSysUser}>
                        Təsdiq et
                      </button>
                    )}
                    <Link to={`/sysUser`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
