import { get, post, put, deleteData } from "./core/Requests";

export const addNotification = async (data) => {
  return await post(`/Notification`, data);
};

export const addNotificationLog = async (data) => {
  return await post(`/Notification/log`, data);
};

export const getLogsByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Notification/logs/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

export const getTemplateTypes = async () => {
  return await get(`/Notification/templateTypes`);
};

export const getTemplateConditions = async (id) => {
  return await get(`/Notification/templateConditions/${id}`);
};

export const getBranchNotificationTemplate = async (notificationTemplateId, branchId) => {
  return await get(`/Notification/branchNotificationTemplate/${notificationTemplateId}/${branchId}`);
};

export const getComboDatas = async (notificationTemplateId, branchId) => {
  return await get(`/Notification/comboDatas/${notificationTemplateId}/${branchId}`);
};

