import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as groupService from "./../../services/GroupService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import GroupViewModal from "./modal/ViewModal";
import GroupMemberListModal from "./../groupMember/modal/ListModal";
import TrainingMaterialListModal from "./../trainingMaterial/modal/ListModal";
import AssignmentListModal from "./../assignment/modal/ListModal";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "./../../components/actionButton/Index";
import * as UserTypes from "./../../constants/UserType";
import GroupTrainingPlanListModal from "../groupTrainingPlan/modal/ListModal";

export default function Index() {
  const { state, setState } = useMainContext();
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();
  const [selectedRow, setSelectedRow] = useState(0);
  const [isOpenViewModal, setIsOpeViewModal] = useState(false);
  const [isOpenGroupMemberListModal, setIsOpenGroupMemberModal] = useState(false);
  const [isOpenTrainingMaterialListModal, setIsOpenTrainingMaterialModal] = useState(false);
  const [isOpenAssignmentListModal, setIsOpenAssignmentModal] = useState(false);
  const [isOpenGroupTrainingPlanListModal, setIsOpenGroupTrainingPlanModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ common: null });

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleViewModal = () => {
    if (isOpenViewModal) setSelectedRow(0);
    setIsOpeViewModal(!isOpenViewModal);
  };

  const handleGroupMemberListModal = (e) => {
    e.stopPropagation();
    if (isOpenGroupMemberListModal) setSelectedRow(0);
    setIsOpenGroupMemberModal(!isOpenGroupMemberListModal);
  };

  const handleTrainingMaterialListModal = (e) => {
    e.stopPropagation();
    if (isOpenTrainingMaterialListModal) setSelectedRow(0);
    setIsOpenTrainingMaterialModal(!isOpenTrainingMaterialListModal);
  };

  const handleGroupTrainingPlanListModal = (e) => {
    e.stopPropagation();
    if (isOpenGroupTrainingPlanListModal) setSelectedRow(0);
    setIsOpenGroupTrainingPlanModal(!isOpenGroupTrainingPlanListModal);
  };

  const handleAssignmentListModal = (e) => {
    e.stopPropagation();
    if (isOpenAssignmentListModal) setSelectedRow(0);
    setIsOpenAssignmentModal(!isOpenAssignmentListModal);
  };

  useEffect(() => {
    getGroups(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getGroups = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = localStorage.branch == null ? await groupService.getGroups(pageIndex, pageSize) : await groupService.getGroupsByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setGroups(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteGroup = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await groupService.deleteGroup(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getGroups(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idGroup) => {
    let result = await groupService.changeActive(idGroup);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getGroups(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getGroups(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getGroups(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`group/delete`} editPath={`group/edit`} addPath={`group/add`} deleteRow={deleteGroup} selectedRow={selectedRow} />}
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">groups</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Qrupların siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getGroups(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : groups?.length > 0 ? (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Qrupun adı</th>
                      <th>Müddət</th>
                      <th>Təlim növü</th>
                      <th>Tələbə sayı</th>
                      <th>Aktivlik</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map((item, i) => (
                      <tr key={item?.idGroup} onClick={() => isSelectRow(item?.idGroup)} className={selectedRow === item?.idGroup ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.groupName}</td>
                        <td>{item?.period}</td>
                        <td>{item?.trainingType?.description}</td>
                        <td>{item?.studentCount}</td>
                        <td>
                          <div className="togglebutton">
                            <label>
                              <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idGroup)} />
                              <span className="toggle" />
                            </label>
                          </div>
                        </td>
                        <td className="td-actions text-right">
                        <button title="Qrup təlim planı"
                            onClick={(e) => {
                              setSelectedRow(item?.idGroup);
                              handleGroupTrainingPlanListModal(e);
                            }}
                            className="btn open-blue"
                          >
                            <i className="material-icons">sticky_note_2</i>
                          </button>
                          <button title="Tapşırıqlar"
                            onClick={(e) => {
                              setSelectedRow(item?.idGroup);
                              handleAssignmentListModal(e);
                            }}
                            className="btn btn-danger"
                          >
                            <i className="material-icons">assignment</i>
                          </button>
                          <button title="Təlim materialları"
                            onClick={(e) => {
                              setSelectedRow(item?.idGroup);
                              handleTrainingMaterialListModal(e);
                            }}
                            className="btn btn-warning"
                          >
                            <i className="material-icons">attach_file</i>
                          </button>
                          <button title="Qrup üzvləri"
                            onClick={(e) => {
                              setSelectedRow(item?.idGroup);
                              handleGroupMemberListModal(e);
                            }}
                            className="btn btn-success"
                          >
                            <i className="material-icons">people</i>
                          </button>
                          <Link to={`${alias}${id}/group/view/${item?.idGroup}`}>
                            <button className="btn btn-info" title="Baxış">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>

      {isOpenViewModal ? <GroupViewModal isOpen={isOpenViewModal} handleViewModal={handleViewModal} group={group} /> : null}
      {isOpenGroupMemberListModal ? <GroupMemberListModal isOpen={isOpenGroupMemberListModal} handleViewModal={handleGroupMemberListModal} groupId={selectedRow} /> : null}
      {isOpenAssignmentListModal ? <AssignmentListModal isOpen={isOpenAssignmentListModal} handleViewModal={handleAssignmentListModal} groupId={selectedRow} /> : null}
      {isOpenTrainingMaterialListModal ? <TrainingMaterialListModal isOpen={isOpenTrainingMaterialListModal} handleViewModal={handleTrainingMaterialListModal} groupId={selectedRow} /> : null}
      {isOpenGroupTrainingPlanListModal ? <GroupTrainingPlanListModal isOpen={isOpenGroupTrainingPlanListModal} handleViewModal={handleGroupTrainingPlanListModal} groupId={selectedRow} /> : null}
    </div>
  );
}
