import React, { useState } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import DatePicker from "react-datepicker";
import az from "date-fns/locale/az";
import { format } from "date-fns";
import MaskedTextInput from "react-text-mask";
import { useEffect } from "react";

export default function Index({ style, colorClass, type, label, name, disabled, value, onChange, onFocus, onBlur, isBtnCopy, required, min }) {
  const { state, setState } = useMainContext();
  const [isCopy, setIsCopy] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (type === "date" && value !== null && value !== "" && value !== undefined) {
      setDate(new Date(value));
    }
  }, [value]);

  const changeCopyStatus = () => {
    setIsCopy(true);
    navigator.clipboard.writeText(value);
    setTimeout(function () {
      setIsCopy(false);
    }, 500);
  };
  const onChangeDate = (name, date) => {
    const event = new Event("input", { bubbles: true });
    let selectedDate = date == null || date == "" || date == undefined ? null : format(date, "yyyy-MM-dd");
    // Set the value property of the event to the new value
    Object.defineProperty(event, "target", {
      value: { value: selectedDate, name: name },
      enumerable: true,
    });

    onChange(event);
    setDate(date);
  };

  return (
    <div className={"form-group is-empty " + style}>
      <label className={`control-label ${required && !value ? "required-red" : ""}`}>
        {label}
        {required && "*"}
      </label>
      {isBtnCopy ? (
        <div className={"row"}>
          <div className="col-sm-10">
            <input type={"text" && type} className="form-control" data-color={state.theme} name={name} value={value || ""} disabled={disabled} onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
          </div>
          <div className="col-sm-2">
            <i className="material-icons" style={{ cursor: "pointer" }} onClick={changeCopyStatus}>
              {isCopy ? "done" : "content_copy"}
            </i>
          </div>
        </div>
      ) : type == "date" ? (
        <DatePicker className={"form-control " + colorClass}  minDate={min} selected={date} dateFormat={"dd/MM/yyyy"} customInput={<MaskedTextInput mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} />} name={name} disabled={disabled} onChange={(date) => onChangeDate(name, date)} />
      ) : (
        <>
          <input type={"text" && type} className={"form-control " + colorClass} data-color={state.theme} name={name} value={value || ""} disabled={disabled} onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
          {/* {required && !value ? <span style={{ color: "red" }}>vacib</span> : null} */}
        </>
      )}
    </div>
  );
}
