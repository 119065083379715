import React, { useState, useEffect } from "react";
import { useTokenData } from "../helper/useTokenData";
import * as userService from "./../services/UserService";
import * as branchService from "./../services/BranchService";
import { useMainContext } from "./../contexts/MainContext";
import { Link } from "react-router-dom";
import Select from "./../components/select/Index";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import languages from "./../translations/index";
import * as UserTypes from "./../constants/UserType";
import US from "./../assets/img/flags/US.png";
import { useOrganization } from "../helper/useOrganization";
import UserChangePasswordModal from "./../pages/user/modal/ChangePasswordModal";

export default function AppBar() {
  const { state, setState } = useMainContext();

  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  //const [isRighMenuToggle, setIsRightMenuToggle] = useState(false);
  const [branchs, setBranchs] = useState([]);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);

  const alias = useOrganization();
  const tokenData = useTokenData();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    menuVisibility();
    getBranchs();
  }, []);

  const getBranchs = async () => {
    let decoded = jwt_decode(localStorage.token);
    if (decoded.isSysUser == 0) {
      let result = await userService.getUserBranchPrivilages();
      if (result?.success) {
        setBranchs(result?.data);
        localStorage.branch = localStorage.branch == undefined || localStorage.branch == null ? result?.data[0]?.fkIdBranch : localStorage.branch;
        //  setState({ ...state, selectedBranch: result?.data[0]?.idBranch });
      } else {
        //  Alerts.error(result.message);
      }
    }
  };

  const handleChangePasswordModal = () => {
    setIsOpenChangePasswordModal(!isOpenChangePasswordModal);
  };

  const isOpenUserDrowdown = () => {
    setOpenUserDropdown(!openUserDropdown);
  };

  const exit = () => {
    localStorage.removeItem("token");
    window.location = tokenData?.isSysUser == "1" ? "/admin/login" : "/login";
  };

  const handleSelect = async (e, name) => {
    setState({ ...state, currentPage: 1 });
    localStorage.branch = e.value;
    await userService.changeActiveBranch(e.value);
    // setExpense({ ...expense, [name]: e.value });
  };

  const menuToggle = async () => {
    let result = await userService.changeMenuVisibility();
    if (result.success) {
      if (result.data == 1) {
        document.body.className = "sidebar-mini";
        setState({ ...state, logoClass: "mini-logo", isRighMenuToggle: false });
      } else {
        document.body.className = "";
        setState({ ...state, logoClass: "big-logo", isRighMenuToggle: true });
      }
    }
  };

  const rightMenuToggle = async () => {
    setState({ ...state, isRighMenuToggle: !state.isRighMenuToggle });
    document.body.className = !state.isRighMenuToggle ? "nav-open" : "";
  };

  const menuVisibility = async () => {
    let result = await userService.menuVisibility();
    if (result.success) {
      if (result.data == 1) {
        document.body.className = "sidebar-mini";
        setState({ ...state, logoClass: "mini-logo" });
      } else {
        document.body.className = "";
        setState({ ...state, logoClass: "big-logo" });
      }
    }
  };

  function renderLanguageChangeButtons() {
    return Object.keys(languages).map((item, index) => (
      <span
        className={`lang mr-5 p-3 ${i18n.language === item ? "selected-lang" : ""}`}
        key={index}
        disabled={i18n.language === item}
        onClick={async () => {
          await i18n.changeLanguage(item);
          localStorage.setItem("lang", item);
        }}
      >
        {item}
        {/* <img src={US} /> */}
      </span>
    ));
  }

  const changeTheme = () => {
    if (state.theme == "black") {
      setState({ ...state, theme: "blue" });
      localStorage.theme = "blue";
    } else {
      setState({ ...state, theme: "black" });
      localStorage.theme = "black";
    }
  };

  return (
    <nav className="navbar navbar-transparent navbar-absolute">
      <div className="container-fluid">
        <div className="navbar-minimize">
          <button id="minimizeSidebar" className="btn btn-round btn-white btn-fill btn-just-icon" onClick={menuToggle}>
            <i className="material-icons visible-on-sidebar-regular">more_vert</i>
            <i className="material-icons visible-on-sidebar-mini">view_list</i>
          </button>
        </div>
        <div className="navbar-header">
          <button type="button" style={{ backgroundColor: "#2899d3" }} className={"navbar-toggle nav-toggle " + (state?.isRighMenuToggle ? "toggled" : "")} data-toggle="collapse" onClick={rightMenuToggle}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <a className="navbar-brand" href="javascript:void(0)" onClick={changeTheme}>
            <span class="material-icons" style={{ fontSize: 30, color: state.theme == "blue" ? "#2899d3" : "#0000009c" }}>
              {state.theme == "black" ? "brightness_4" : "brightness_5"}
            </span>
          </a>
          {/* {alias.toLowerCase() === "/test" ? <div className="navbar-brand">{renderLanguageChangeButtons()}</div> : null} */}
        </div>
        <div className="collapse navbar-collapse">
          {/*    <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="material-icons">notifications</i>
                <span className="notification">5</span>
                <p className="hidden-lg hidden-md">
                  Notifications
                  <b className="caret" />
                </p>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#">Mike John responded to your email</a>
                </li>
                <li>
                  <a href="#">You have 5 new tasks</a>
                </li>
                <li>
                  <a href="#">You're now friend with Andrew</a>
                </li>
                <li>
                  <a href="#">Another Notification</a>
                </li>
                <li>
                  <a href="#">Another One</a>
                </li>
              </ul>
            </li> 
            <li className={`dropdown ${openUserDropdown ? "open" : ""}`}>
              <a
                href="javascript:void(0)"
                onClick={isOpenUserDrowdown}
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="material-icons">person</i>
                <p className="hidden-lg hidden-md">Profile</p>
              </a>
              <ul className="dropdown-menu ">
                <li>
                  <a href="#">Şəxsi kabinet</a>
                </li>
                <li>
                  <a href="javascript:void(0)" onClick={exit}>
                    Çıxış
                  </a>
                </li>
              </ul>
            </li> 
            <li className="separator hidden-lg hidden-md" />
          </ul>*/}

          <form className="navbar-form navbar-right" role="search">
            <div className="form-group form-search is-empty">
              {tokenData?.isSysUser != "1" && tokenData?.userTypeId != UserTypes.STUDENT_USER ? (
                <>
                  <Select
                    isBranchSelect={true}
                    placeholder={"Filial seç"}
                    style={"m-branch"}
                    bgColor={"#f1f1f1"}
                    // label={"Filial"}
                    selectedValue={localStorage.branch != undefined && localStorage.branch != null ? localStorage.branch : branchs[0]?.fkIdBranch}
                    changeSelectHandler={(e) => handleSelect(e, "fkIdBranch")}
                    options={branchs.map((branch) => ({
                      label: branch?.branchName,
                      value: branch?.fkIdBranch,
                    }))}
                  />
                  <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" onClick={handleChangePasswordModal}>
                    <i className="material-icons profile-icon">person</i>
                  </a>
                </>
              ) : null}
            </div>
          </form>
        </div>
      </div>
      {isOpenChangePasswordModal ? <UserChangePasswordModal isOpen={isOpenChangePasswordModal} isRedirect={false} handleChangePasswordModal={handleChangePasswordModal} id={localStorage.userId} /> : null}
    </nav>
  );
}
