import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as assignmentService from "../../../services/AssignmentService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import CommitButton from "./../../../components/commitButton/Index";

import * as Alerts from "../../../utils/Alerts";

export default function AssignmentDetailListModal({ isOpen, handleViewModal, header, assignmentId }) {
  const { state, setState } = useMainContext();
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAssignmentDetails(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getAssignmentDetails = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await assignmentService.getAssignmentDetails(assignmentId, pageIndex, pageSize);
      if (result.success) {
        setAssignmentDetails(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const updateAssignmentDetails = async () => {
    let result = await assignmentService.updateAssignmentDetails(assignmentDetails);
    if (result.success) {
      Alerts.success(result.message);
      handleViewModal();
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    ///setAssignment({ ...assignment, [e.target.name]: e.target.value });
  };

  const changePage = (pageIndex) => {
    getAssignmentDetails(pageIndex, localStorage.rowCount);
  };

  const changeExecutionStatus = (executionStatus) => {
    // getAssignmentDetails(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getAssignmentDetails(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : assignmentDetails?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Tələbə</th>
                            <th>İcra statusu</th>
                            <th>İcra qeydi</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {assignmentDetails.map((item, i) => (
                            <tr key={item?.idAssignmentDetail}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>

                              <td>{item?.student?.fullName}</td>
                              <td>
                                <i
                                  className={`material-icons cursor-pointer ${item.fkIdAssignmentExecutionStatus == 1 && "assign-exec-not"}`}
                                  title="İcra edilməyib"
                                  onClick={(e) => {
                                    item.fkIdAssignmentExecutionStatus = 1;
                                    setAssignmentDetails([...assignmentDetails]);
                                  }}
                                  disabled={item.fkIdAssignmentExecutionStatus == 1}
                                >
                                  do_disturb_on
                                </i>
                                <i
                                  className={`material-icons cursor-pointer ${item.fkIdAssignmentExecutionStatus == 2 && "assign-exec-pending"}`}
                                  title="İcra edilməkdədir"
                                  onClick={(e) => {
                                    item.fkIdAssignmentExecutionStatus = 2;
                                    setAssignmentDetails([...assignmentDetails]);
                                  }}
                                  disabled={item.fkIdAssignmentExecutionStatus == 2}
                                >
                                  pending
                                </i>
                                <i
                                  title="Tamamlanıb"
                                  className={`material-icons cursor-pointer ${item.fkIdAssignmentExecutionStatus == 3 && "assign-exec-ok"}`}
                                  onClick={(e) => {
                                    item.fkIdAssignmentExecutionStatus = 3;
                                    setAssignmentDetails([...assignmentDetails]);
                                  }}
                                  disabled={item.fkIdAssignmentExecutionStatus == 3}
                                >
                                  check_circle
                                </i>
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  data-color={state.theme}
                                  name={"executionNote"}
                                  value={item?.executionNote}
                                  onChange={(e) => {
                                    item.executionNote = e.target.value;
                                    setAssignmentDetails([...assignmentDetails]);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CommitButton onClick={updateAssignmentDetails} />
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
