import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as reviewService from "../../../services/ReviewService";
import * as notificationService from "../../../services/NotificationService";
import * as Alerts from "../../../utils/Alerts";
import Select from "../../../components/select/Index";
import FormInput from "../../../components/formInput/Index";
import FormTextareaInput from "../../../components/formTextareaInput/Index";
import FormCheckbox from "../../../components/formCheckbox/Index";
import CommitButton from "../../../components/commitButton/Index";
import * as date from "../../../utils/DateNow";
import * as UserTypeEnum from "../../../constants/UserType";

export default function AddModal({ isOpen, handleViewModal, studentId, selected }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [log, setLog] = useState({smsNotification:1});
  const [templateConditions, setTemplateConditions] = useState([]);
  const [templatetypes, setTemplateTypes] = useState([]);
  const [branchTemplate, setBranchTemplate] = useState({});
  const [comboDatas, setComboDatas] = useState({});
  const [activeTab, setActiveTab] = useState("sms");

  useEffect(() => {
    getTemplateTypes();
  }, []);

  useEffect(() => {
    log?.fkIdNotificationTemplateType != null && getTemplateConditions(log?.fkIdNotificationTemplateType);
  }, [log?.fkIdNotificationTemplateType]);

  const getTemplateConditions = async (id) => {
    let result = await notificationService.getTemplateConditions(id);
    if (result?.success) {
      setTemplateConditions(result?.data);
      setLog({ ...log, fkIdNotificationTemplateCondition: result?.data[0]?.idNotificationTemplateCondition });
    } else {
      Alerts.error(result?.message);
    }
  };

  const getTemplateTypes = async () => {
    let result = await notificationService.getTemplateTypes();
    if (result?.success) {
      setTemplateTypes(result?.data);
    } else {
      Alerts.error(result?.message);
    }
  };

  const getComboDatas = async (notificationTemplateId) => {
    let result = await notificationService.getComboDatas(notificationTemplateId, localStorage.branch);
    if (result.success) {
      setComboDatas(result?.data);
    } else {
      setComboDatas([]);
    }
  };

  const getBranchNotificationTemplate = async (notificationTemplateId) => {
    let result = await notificationService.getBranchNotificationTemplate(notificationTemplateId, localStorage.branch);
    if (result.success) {
      setBranchTemplate(result?.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addNotification = async () => {
    if (log?.smsNotification == 1 || log?.emailNotification == 1 || log?.pushNotification == 1) {
      await Alerts.confirmForNotify(async function (confirmed) {
        if (confirmed) {
          let result = await notificationService.addNotificationLog({ ...log, smsContent: branchTemplate?.smsNotificationsTemplate, emailContent: branchTemplate?.emailNotificationsTemplate, fkIdBranch: localStorage.branch });
          if (result.success) {
            Alerts.success("Bildirişlərin göndərilməsi prosesi başlanıldı");
            handleViewModal();
          } else {
            Alerts.error(result.message);
          }
        }
      });
    } else {
      Alerts.warning("SMS, email və ya push bildirişdən ən azı biri seçilməlidir");
    }
  };

  const handleInput = (e) => {
    setLog({ ...log, [e.target.name]: e.target.value });
  };

  const handleBranchTemplateInput = (e) => {
    setBranchTemplate({ ...branchTemplate, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setLog({ ...log, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Bildiriş
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <div className="row">
                    <Select
                      placeholder={"Seç"}
                      style={"col-sm-12"}
                      label={"Bildiriş növü"}
                      required={true}
                      selectedValue={log?.fkIdNotificationTemplateType}
                      changeSelectHandler={(e) => {
                        handleSelect(e, "fkIdNotificationTemplateType");
                        if (e.value != null && e.value != undefined) {
                          getBranchNotificationTemplate(e.value);
                          // getTemplateConditions(e.value);
                          getComboDatas(e.value);
                        }
                      }}
                      options={templatetypes.map((item) => ({ label: item?.description, value: item?.idNotificationTemplateType }))}
                      isDisabled={isView}
                    />
                    <Select placeholder={"Seç"} style={"col-sm-12"} label={"Şərt"} selectedValue={log?.fkIdNotificationTemplateCondition} changeSelectHandler={(e) => handleSelect(e, "fkIdNotificationTemplateCondition")} options={templateConditions.map((item) => ({ label: item?.description, value: item?.idNotificationTemplateCondition }))} isDisabled={isView} />

                    {comboDatas.length > 0 ? <Select placeholder={"Seç"} style={"col-sm-12"} label={"Seçim"} selectedValue={log?.selectedObjectId} changeSelectHandler={(e) => handleSelect(e, "selectedObjectId")} options={comboDatas.map((item) => ({ label: item?.item_value, value: item?.id_item }))} isDisabled={isView} /> : null}

                    <ul className="nav nav-pills nav-pills-warning" style={{ paddingLeft: 15 }}>
                      <li style={{ width: "49%" }} className={activeTab == "sms" ? "active" : "notify-tab-noactive"} onClick={() => setActiveTab("sms")}>
                        <a href="javascript:void(0)" className={activeTab == "sms" ? "notify-tab-active" : ""} data-toggle="tab">
                          SMS MƏTN ŞABLONU
                        </a>
                      </li>
                      <li style={{ width: "49%" }} className={activeTab == "email" ? "active" : "notify-tab-noactive"} onClick={() => setActiveTab("email")}>
                        <a href="javascript:void(0)" className={activeTab == "email" ? "notify-tab-active" : ""} data-toggle="tab">
                          EMAİL MƏTN ŞABLONU
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div className={"tab-pane " + (activeTab === "sms" ? "active" : "")}>
                        <FormTextareaInput style={"col-sm-12"} label={"SMS mətn şablonu"} rows={5} name={"smsNotificationsTemplate"} value={branchTemplate?.smsNotificationsTemplate} onChange={handleBranchTemplateInput} disabled={isView} />
                      </div>

                      <div className={"tab-pane " + (activeTab === "email" ? "active" : "")}>
                        <FormTextareaInput style={"col-sm-12"} label={"Email mətn şablonu"} rows={5} name={"emailNotificationsTemplate"} value={branchTemplate?.emailNotificationsTemplate} onChange={handleBranchTemplateInput} disabled={isView} />
                      </div>
                    </div>

                    <FormCheckbox label={"SMS bildiriş"} style={"col-sm-4"} name={"smsNotification"} checked={log?.smsNotification} onChange={() => setLog({ ...log, smsNotification: log?.smsNotification == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Email bildiriş"} style={"col-sm-4"} name={"emailNotification"} checked={log?.emailNotification} onChange={() => setLog({ ...log, emailNotification: log?.emailNotification == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Push bildiriş"} style={"col-sm-4"} name={"pushNotification"} checked={log?.pushNotification} onChange={() => setLog({ ...log, pushNotification: log?.pushNotification == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addNotification} text={"Bildiriş göndər"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
