import { get, post, put, deleteData } from "./core/Requests";

export const getIncomes = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Income`, { pageIndex, pageSize });
};

// export const getForSelect = async (branchId) => {
//   return await get(
//     `/Income/getForSelect${
//       branchId != null && branchId != undefined ? `?branchId=${branchId}` : ""
//     }`
//   );
// };

export const getIncome = async (id) => {
  return await get(`/Income/${id}`);
};

export const addIncome = async (data) => {
  return await post(`/Income`, data);
};

export const updateIncome = async (data) => {
  return await put(`/Income`, data);
};

export const deleteIncome = async (id) => {
  return await deleteData(`/Income/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Income/changeActive/${id}`);
};
