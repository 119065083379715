import { get, post, put, deleteData } from "./core/Requests";

export const getApplications = async (pageIndex, pageSize) => {
  return await get(`/Application`, { pageIndex, pageSize });
};

export const getApplicationsByBranch = async (branchId, pageIndex = 0, pageSize = 0, filter) => {
  return await post(`/Application/getByBranch/${branchId}`, filter, { pageIndex, pageSize });
};

export const exportExcel = async (branchId,  filter) => {
  return await get(`/Application/${branchId}/export`);
};


export const getApplication = async (id) => {
  return await get(`/Application/${id}`);
};

export const addApplication = async (data) => {
  return await post(`/Application`, data);
};

export const updateApplication = async (data) => {
  return await put(`/Application`, data);
};

export const deleteApplication = async (id) => {
  return await deleteData(`/Application/${id}`);
};

export const changeConvertToContract = async (id) => {
  return await get(`/Application/convertToContract/${id}`);
};

export const getApplicationTypes = async () => {
  return await get(`/Application/ApplicationTypes`);
};

export const getReferances = async () => {
  return await get(`/Application/Referances`);
};

export const getApplicationCallBacks = async (applicationId, pageIndex = 0, pageSize = 0) => {
  return await post(`/Application/getApplicationCallBacks/${applicationId}`, { pageIndex, pageSize });
};

export const getApplicationCallBackResults = async () => {
  return await get(`/Application/getApplicationCallBackResults`);
};

export const addApplicationCallBack = async (data) => {
  return await post(`/Application/addApplicationCallBack`, data);
};

export const deleteApplicationCallBack = async (id) => {
  return await deleteData(`/Application/deleteApplicationCallBack/${id}`);
};