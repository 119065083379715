import React from "react";
import * as util from "../../utils/Util";

export default function TrJuridicalReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.juridicalPersonName}</td>
      <td>{item?.contractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.journalDetailCount}</td>
      <td>{item?.journalDetailParticipateCount}</td>
      <td>{util.FixedNumber(item?.debtCalculation)}</td>
      <td>{util.FixedNumber(item?.paymentSum)}</td>
    </tr>
  ) : null;
}


