import { get, post, put, deleteData } from "./core/Requests";

export const getFinanceOperations = async (financeOperationType, pageIndex = 0, pageSize = 0) => {
  return await get(`/FinanceOperation/getList/${financeOperationType}`, { pageIndex, pageSize });
};

export const getFinanceOperationsByBranch = async (financeOperationType, branchId, pageIndex = 0, pageSize = 0, filter) => {
  if (financeOperationType == "1-2") financeOperationType = 1;
  return await post(`/FinanceOperation/getByBranch/${financeOperationType}/${branchId}`, filter, { pageIndex, pageSize });
};

export const getFinanceOperation = async (id) => {
  return await get(`/FinanceOperation/${id}`);
};

export const addFinanceOperation = async (data) => {
  return await post(`/FinanceOperation`, data);
};

export const addFinanceOperationForStudent = async (data) => {
  return await post(`/FinanceOperation/addForStudent`, data);
};

export const updateFinanceOperation = async (data) => {
  return await put(`/FinanceOperation`, data);
};

export const deleteFinanceOperation = async (id) => {
  return await deleteData(`/FinanceOperation/${id}`);
};
