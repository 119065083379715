import { get, post, put, deleteData } from "./core/Requests";

export const getPeriodTypes = async () => {
  return await get(`/PeriodType`);
};

export const getPeriodType = async (id) => {
  return await get(`/PeriodType/${id}`);
};

export const addPeriodType = async (data) => {
  return await post(`/PeriodType`, data);
};

export const updatePeriodType = async (data) => {
    return await put(`/PeriodType`, data);
  };

export const deletePeriodType = async (id) => {
  return await deleteData(`/PeriodType/${id}`);
};
