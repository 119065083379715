import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import * as roomService from "./../../../services/RoomService";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [room, setRoom] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idRoom } = useParams();

  useEffect(() => {
    idRoom && getRoom();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getRoom = async () => {
    let result = await roomService.getRoom(idRoom);
    if (result.success) {
      setRoom(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateRoom = async () => {
    let result = idRoom ? await roomService.updateRoom({ ...room, fkIdBranch: localStorage.branch }) : await roomService.addRoom({ ...room, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/room`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setRoom({ ...room, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">meeting_room</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idRoom ? (isView ? "Otaq məlumatları" : "Otaq məlumatlarının redaktəsi") : "Yeni Otaq"}</h4>

                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-6"} label={"Otaq nömrəsi"} name={"roomNumber"} value={room?.roomNumber} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Qeyd"} name={"description"} value={room?.description} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Mərtəbə"} name={"floor"} value={room?.floor} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Tutumu"} name={"capacity"} value={room?.capacity} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Veb kamera"} style={"col-sm-4"} name={"hasWebcam"} checked={room?.hasWebcam} onChange={() => setRoom({ ...room, hasWebcam: room?.hasWebcam == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Müşahidə kamerası"} style={"col-sm-4"} name={"hasSecurityCam"} checked={room?.hasSecurityCam} onChange={() => setRoom({ ...room, hasSecurityCam: room?.hasSecurityCam == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Projektor"} style={"col-sm-4"} name={"hasProjector"} checked={room?.hasProjector} onChange={() => setRoom({ ...room, hasProjector: room?.hasProjector == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Smart board"} style={"col-sm-4"} name={"hasSmartBoard"} checked={room?.hasSmartBoard} onChange={() => setRoom({ ...room, hasSmartBoard: room?.hasSmartBoard == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"PC"} style={"col-sm-4"} name={"hasPc"} checked={room?.hasPc} onChange={() => setRoom({ ...room, hasPc: room?.hasPc == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"TV"} style={"col-sm-4"} name={"hasTv"} checked={room?.hasTv} onChange={() => setRoom({ ...room, hasTv: room?.hasTv == 1 ? 0 : 1 })} disabled={isView} />
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-4"} name={"isActive"} checked={room?.isActive} onChange={() => setRoom({ ...room, isActive: room?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                  </div>

                  <div className="row align-right">
                    {!isView && (
                      <CommitButton onClick={addOrUpdateRoom} />
                    )}
                    <Link to={`${alias}/room`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
