import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as organizationService from "./../../services/OrganizationService";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { PAGE_SIZE_COUNTS, PAGE_INDEX } from "../../constants/Pagination";
import { useTokenData } from "../../helper/useTokenData";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [organizations, setOrganizations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const org = useOrganization();
  const tokenData = useTokenData();


  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getOrganizations(state.currentPage, localStorage.rowCount);
  }, []);


  const getOrganizations = async (pageIndex, pageSize) => {
    setLoading(true);
    let orgs = tokenData.isSysUser == "1" ? await organizationService.getOrganizations(pageIndex, pageSize) : await organizationService.getOrganizationByUser(pageIndex, pageSize);

    if (orgs.success) {
      tokenData.isSysUser == "1" ? setOrganizations(orgs.data.datas) : setOrganizations([orgs.data]);
      setPagination({
        pageIndex: orgs.data.pageIndex,
        hasNextPage: orgs.data.hasNextPage,
        hasPreviousPage: orgs.data.hasPreviousPage,
        totalPageCount: orgs.data.totalPageCount,
        totalRecordCount: orgs.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(orgs.message);
    }
  };

  const deleteOrganization = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let org = await organizationService.deleteOrganization(id);
        if (org.success) {
          setSelectedRow(0);
          Alerts.success(org.message);
        } else {
          Alerts.error(org.message);
        }
        getOrganizations(PAGE_INDEX, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getOrganizations(pageIndex, localStorage.rowCount);
  };


  return (
    <div className="col-md-12">

      {tokenData.isSysUser == "1" ? (
        <>
          <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteOrganization(selectedRow)}>
            Sil
          </button>

          <Link to={`/organization/edit/${selectedRow}`}>
            <button disabled={selectedRow == 0 ? true : false} className="btn btn-info f-right">
              Redaktə et
            </button>
          </Link>
          <Link to={"/organization/add"}>
            <button className="btn btn-info f-right">Əlavə et</button>
          </Link>
        </>
      ) : null}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">account_balance</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Təşkilatların siyahısı</h4>
          <PageSizeSelect onChange={(e)=> getOrganizations(PAGE_INDEX, e.value)} />
          {loading ? (
            <Loading />
          ) : organizations?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Adı</th>
                    <th>Müştəri kodu</th>
                    <th>Əlaqədar şəxs</th>
                    <th>Əlaqə nömrəsi</th>
                    <th>Aylıq ödəniş</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {organizations.map((item, i) => (
                    <tr key={item?.idOrganization} onClick={() => isSelectRow(item?.idOrganization)} className={selectedRow === item?.idOrganization ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.organizationName}</td>
                      <td>{item?.Code}</td>
                      <td>{item?.contactPerson}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.monthlyPayment}</td>
                      <td className="td-actions text-right">
                        <Link to={`${org}/branch/${item?.idOrganization}`}>
                          <button className="btn btn-success">
                            <i className="material-icons">domain</i>
                          </button>
                        </Link>
                        <Link to={`${org}/organization/view/${item?.idOrganization}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
