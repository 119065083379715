// import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/material-dashboard98f3.css";
import "./assets/css/site.css";
import "./utils/i18n";
import "react-datepicker/dist/react-datepicker.css";

// import './../node_modules/@syncfusion/ej2/material.css';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
