import React from "react";
import { useMainContext } from "../../contexts/MainContext";

export default function Index({ page, isAccessDetail }) {
  const { state, setState } = useMainContext();

  const addFilter = (name, value) => {
    setState({ ...state, [page]: { ...state[page], [name]: value } });
  };

  return page == "studentFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fin} onChange={(e) => addFilter("fin", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.age} onChange={(e) => addFilter("age", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      {isAccessDetail && (
        <td>
          <div class="input-wrapper">
            <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
            <i className="material-icons material-icons-input">search</i>
          </div>
        </td>
      )}
      <td></td>
      <td></td>
    </tr>
  ) : page == "trainerFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.email} onChange={(e) => addFilter("email", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
    </tr>
  ) : page == "employeeFilter" ? (
    <tr>
      <td className="td-actions text-right">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            setState({ ...state, [page]: {} });
          }}
        >
          <i className="material-icons">search_off</i>
        </button>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.fullName} onChange={(e) => addFilter("fullName", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.phone} onChange={(e) => addFilter("phone", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.email} onChange={(e) => addFilter("email", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td>
        <div class="input-wrapper">
          <input type="text" value={state?.[page]?.post} onChange={(e) => addFilter("post", e.target.value)} />
          <i className="material-icons material-icons-input">search</i>
        </div>
      </td>
      <td></td>
      <td></td>
    </tr>
  ) : null;
}
