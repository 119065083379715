import * as organizationService from "./../services/OrganizationService";
import jwt_decode from "jwt-decode";

export const useOrganization = () => {
  var decoded =
    localStorage.token != null ? jwt_decode(localStorage.token) : null;
  if (decoded != null) {
    return decoded?.isSysUser=='0' ? `/${decoded?.alias}` : "";
  } else return "";

  //   let result = await organizationService.getOrg();
  //   console.log(result);
  //   if (result.success) return `/${result.data}`;
  //   else return "";
};
