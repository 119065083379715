import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import * as postService from "./../../../services/PostService";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [post, setPost] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idPost } = useParams();

  useEffect(() => {
    idPost && getPost();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getPost = async () => {
    let result = await postService.getPost(idPost);
    if (result.success) {
      setPost(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdatePost = async () => {
    let result = idPost ? await postService.updatePost(post) : await postService.addPost(post);
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/post`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setPost({ ...post, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">paid</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idPost ? (isView ? "Vəzifə məlumatları" : "Vəzifə məlumatlarının redaktəsi") : "Yeni vəzifə"}</h4>
                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-12"} label={"Vəzifənin adı"} name={"postName"} value={post?.postName} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-12"} label={"Qeyd"} name={"description"} value={post?.description} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={post?.isActive} onChange={() => setPost({ ...post, isActive: post?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && <CommitButton onClick={addOrUpdatePost} />}
                    <Link to={`${alias}/post`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
