import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as branchService from "./../../../services/BranchService";
import * as helperService from "./../../../services/HelperService";
import * as Alerts from "./../../../utils/Alerts";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import Pagination from "../../../components/pagination/Index";
import NotFound from "../../../components/notfound/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [branchSettings, setBranchSettings] = useState({});
  const [activeTab, setActiveTab] = useState("general");
  const [providers, setProviders] = useState([]);
  const [notifyTemplates, setNotifyTemplates] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedRow, setSelectedRow] = useState(0);

  const alias = useOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    getSmsProviders();
    getNotifyTemplates();
    getBranchSettings();
  }, [localStorage.branch]);

  const getBranchSettings = async () => {
    let result = await branchService.getBranchSettings(localStorage.branch);
    if (result.success) {
      if (result.data?.fkIdSmsProvider == null) result.data.fkIdSmsProvider = 1;
      setBranchSettings(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getSmsProviders = async () => {
    let result = await helperService.getSmsProviders();
    if (result.success) {
      setProviders(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const getNotifyTemplates = async () => {
    let result = await branchService.getNotifyTemplates(localStorage.branch);
    if (result.success) {
      setNotifyTemplates(result.data?.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getNotifyTemplates(pageIndex, localStorage.rowCount);
  };

  const updateBranchSettings = async () => {
    let result = await branchService.updateBranchSettings(branchSettings, localStorage.branch);
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/branch/settings`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const changeEmailEnable = async (id) => {
    let result = await branchService.changeEmailEnable(id);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getNotifyTemplates(pagination.pageIndex, localStorage.rowCount);
  };

  const changeSmsEnable = async (id) => {
    let result = await branchService.changeSmsEnable(id);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getNotifyTemplates(pagination.pageIndex, localStorage.rowCount);
  };

  const handleInput = (e) => {
    setBranchSettings({ ...branchSettings, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setBranchSettings({ ...branchSettings, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{"Filial parametrləri"}</h4>

                <ul className="nav nav-pills nav-pills-warning">
                  <li className={activeTab == "general" ? "active" : ""} onClick={() => setActiveTab("general")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      ÜMUMİ
                    </a>
                  </li>
                  <li className={activeTab == "sms" ? "active" : ""} onClick={() => setActiveTab("sms")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      SMS PARAMETRLƏRİ
                    </a>
                  </li>
                  <li className={activeTab == "notify" ? "active" : ""} onClick={() => setActiveTab("notify")}>
                    <a href="javascript:void(0)" data-toggle="tab">
                      BİLDİRİŞ ŞABLONLARI
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className={"tab-pane " + (activeTab === "general" ? "active" : "")}>
                    <FormCheckbox label={"Email bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingEmailNotification"} checked={branchSettings?.enableSendingEmailNotification} onChange={() => setBranchSettings({ ...branchSettings, enableSendingEmailNotification: branchSettings?.enableSendingEmailNotification == 1 ? 0 : 1 })} />
                    <FormCheckbox label={"Sms bildirişdən istifadənin statusu"} style={"col-sm-12"} name={"enableSendingSmsNotification"} checked={branchSettings?.enableSendingSmsNotification} onChange={() => setBranchSettings({ ...branchSettings, enableSendingSmsNotification: branchSettings?.enableSendingSmsNotification == 1 ? 0 : 1 })} />
                    <FormCheckbox label={"Valideyn məlumatları"} style={"col-sm-12"} name={"appSettingsParentInfo"} checked={branchSettings?.appSettingsParentInfo} onChange={() => setBranchSettings({ ...branchSettings, appSettingsParentInfo: branchSettings?.appSettingsParentInfo == 1 ? 0 : 1 })} />
                  </div>
                  <div className={"tab-pane " + (activeTab === "sms" ? "active" : "")}>
                    <div className="row">
                      <Select
                        placeholder={"Seç"}
                        style={"col-sm-6"}
                        label={"Provayderlər"}
                        selectedValue={branchSettings?.fkIdSmsProvider}
                        changeSelectHandler={(e) => {
                          handleSelect(e, "fkIdSmsProvider");
                        }}
                        options={providers?.map((item) => ({ label: item?.providerName, value: item?.idSmsProvider }))}
                      />
                      {branchSettings?.fkIdSmsProvider != 1 && branchSettings?.fkIdSmsProvider!=null? (
                        <>
                          <FormInput style={"col-sm-6"} label={"Sender adı"} name={"smsSenderName"} value={branchSettings?.smsSenderName} onChange={handleInput} />
                          <FormInput style={"col-sm-6"} label={"İstifadəçi adı"} name={"smsUserName"} value={branchSettings?.smsUserName} onChange={handleInput} />
                          <FormInput style={"col-sm-6"} type={"password"} label={"Şifrə"} name={"smsPassword"} value={branchSettings?.smsPassword} onChange={handleInput} />
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className={"tab-pane " + (activeTab === "notify" ? "active" : "")}>
                    {notifyTemplates?.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center">#</th>
                              <th>Şablonun adı</th>
                              <th>Sms bildirişi aktivləşdir</th>
                              <th>Email bildirişi aktivləşdir</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {notifyTemplates.map((item, i) => (
                              <tr key={item?.idBranchNotificationTemplate} onClick={() => isSelectRow(item?.idBranchNotificationTemplate)} className={selectedRow === item?.idBranchNotificationTemplate ? "selectedRow" : ""}>
                                <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                                <td>{item?.notificationTemplateType}</td>
                                <td>
                                  <div className="togglebutton">
                                    <label>
                                      <input type="checkbox" checked={item?.enableSmsNotification} onChange={() => changeSmsEnable(item?.idBranchNotificationTemplate)} />
                                      <span className="toggle" />
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="togglebutton">
                                    <label>
                                      <input type="checkbox" checked={item?.enableEmailNotification} onChange={() => changeEmailEnable(item?.idBranchNotificationTemplate)} />
                                      <span className="toggle" />
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                      </div>
                    ) : (
                      <NotFound />
                    )}
                  </div>
                </div>

                <div className="row align-right">
                  <CommitButton onClick={updateBranchSettings} />
                  <Link to={`${alias}/dashboard`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
