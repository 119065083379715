import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as trainingMaterialService from "./../../services/TrainingMaterialService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import * as date from "./../../utils/DateNow";
import { URL } from "./../../services/core/Urls";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "./../../components/actionButton/Index";
import * as UserTypes from "./../../constants/UserType";

export default function Index() {
  const { state, setState } = useMainContext();
  const [trainingMaterials, setTrainingMaterials] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getTrainingMaterials(state.currentPage, localStorage.rowCount);
  }, []);

  const getTrainingMaterials = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await trainingMaterialService.getTrainingMaterialsByBranch(localStorage.branch, pageIndex, pageSize, filter);
    if (result.success) {
      setTrainingMaterials(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteTrainingMaterial = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await trainingMaterialService.deleteTrainingMaterial(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getTrainingMaterials(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getTrainingMaterials(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getTrainingMaterials(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId !=UserTypes.STUDENT_USER && (
      <ActionButtons 
      deletePath={`trainingMaterial/delete`}
      editPath={`trainingMaterial/edit`} 
      addPath={`trainingMaterial/add`} deleteRow={deleteTrainingMaterial}
       selectedRow={selectedRow} />  
      )}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">attach_file</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Təlim materiallarının siyahısı</h4>

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getTrainingMaterials(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />

                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : trainingMaterials?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Qrup</th>
                    <th>Başlıq</th>
                    <th>Tarix</th>
                    <th>Fayl sayı</th> 
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {trainingMaterials.map((item, i) => (
                    <tr key={item?.idTrainingMaterial} onClick={() => isSelectRow(item?.idTrainingMaterial)} className={selectedRow === item?.idTrainingMaterial ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.group}</td>
                      <td>{item?.title}</td>
                      <td>{item?.trainingMaterialDate}</td>
                      <td>{item?.fileCount}</td>
                      <td className="td-actions text-right">
                        <button disabled={item?.fileCount == 0} className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/trainingmaterials/downloadAll/7/${item.idTrainingMaterial}`)}>
                          <i className="material-icons">download</i>
                        </button>
                        <Link to={`${alias}${id}/trainingMaterial/view/${item?.idTrainingMaterial}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
