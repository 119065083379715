import { get, post, put, deleteData } from "./core/Requests";

export const getCases = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Case/getList`, { pageIndex, pageSize });
};

export const getCasesByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Case/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};


export const getCase = async (id) => {
  return await get(`/Case/${id}`);
};

export const addCase = async (data) => {
  return await post(`/Case`, data);
};

export const updateCase = async (data) => {
  return await put(`/Case`, data);
};

export const deleteCase = async (id) => {
  return await deleteData(`/Case/${id}`);
};


