import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as groupTrainingPlanService from "../../../services/GroupTrainingPlanService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import AddModal from "./../modal/AddModal";

import * as Alerts from "../../../utils/Alerts";

export default function GroupTrainingPlanListModal({ isOpen, handleViewModal, groupId }) {
  const { state, setState } = useMainContext();
  const [groupTrainingPlans, setGroupTrainingPlans] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  useEffect(() => {
    getGroupTrainingPlans(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getGroupTrainingPlans = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await groupTrainingPlanService.getGroupTrainingPlansByBranch(localStorage.branch, pageIndex, pageSize, { idGroup: groupId });
    if (result.success) {
      setGroupTrainingPlans(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleAddModal = () => {
    isOpenAddModal && getGroupTrainingPlans(state.currentPage, localStorage.rowCount);
    setIsOpenAddModal(!isOpenAddModal);
  };

  const changePage = (pageIndex) => {
    getGroupTrainingPlans(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Qrup planları
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getGroupTrainingPlans(PAGE_INDEX, e.value)} />

                  {loading ? (
                    <Loading />
                  ) : groupTrainingPlans?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Təlimçi</th>
                            <th>Başlıq</th>
                            <th>Məzmun</th>
                            <th>Plan tarixi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupTrainingPlans.map((item, i) => (
                            <tr key={item?.idGroupTrainingPlan}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.trainer?.fullName}</td>
                              <td>{item?.title}</td>
                              <td>{item?.description}</td>
                              <td>{item?.planDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "trainingmaterial/add") ? (
              <button
                className="btn open-blue"
                onClick={(e) => {
                  handleAddModal(e);
                }}
              >
                <i className="material-icons">app_registration</i> Yeni təlim planı
              </button>
            ) : null}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenAddModal ? <AddModal isOpen={isOpenAddModal} handleViewModal={handleAddModal} groupId={groupId} /> : null}
    </div>
  );
}
