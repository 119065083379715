import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as groupService from "./../../../services/GroupService";
import * as paymentPeriodService from "./../../../services/PaymentPeriodService";
import * as groupMemberService from "./../../../services/GroupMemberService";
import * as Alerts from "./../../../utils/Alerts";
//import * as paymentPeriodService from "./../../../services/PaymentPeriodService";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import StudentSelectModal from "./../../student/modal/SelectModal";
import { useParams, useLocation, useNavigate } from "react-router";
import * as date from "./../../../utils/DateNow";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();

  const [groupMember, setGroupMember] = useState({ startDate: date.Now()});
  // const [branchs, setBranchs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isOpenStudentSelectModal, setIsOpenStudentSelectModal] = useState(false);
  const [isView, setIsView] = useState(false);

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idGroupMember } = useParams();

  useEffect(() => {
    // getBranchs();
    getGroups();
    idGroupMember && getGroupMember();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getGroups = async () => {
    let result = await groupService.getGroups();
    if (result.success) {
      setGroups(result.data.datas);
    } else {
      Alerts.error(result.message);
    }
  };


  const addOrUpdateGroupMember = async () => {
    groupMember.fkIdStudent = selectedStudent?.rowId;
    let result = idGroupMember ? await groupMemberService.updateGroupMember({ ...groupMember, fkIdBranch: localStorage.branch }) : await groupMemberService.addGroupMember({ ...groupMember, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/groupMember`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroupMember = async () => {
    let result = await groupMemberService.getGroupMember(idGroupMember);
    if (result.success) {
      setGroupMember(result.data);
      console.log(result.data);
      setSelectedStudent({
        rowId: result?.data?.fkIdStudent,
        fullName: result?.data?.studentFullName,
      });
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setGroupMember({ ...groupMember, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setGroupMember({ ...groupMember, [name]: e.value });
  };

  const handleStudentSelectModal = () => {
    setIsOpenStudentSelectModal(!isOpenStudentSelectModal);
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idGroupMember ? (isView ? "Qrup üzvü məlumatları" : "Qrup üzvü məlumatlarının redaktəsi") : "Yeni qrup üzvü"}</h4>

                <div className="row">
                  <>
                    <FormInput style={"col-sm-7"} label={"Tələbənin tam adı"} value={selectedStudent != null ? selectedStudent.fullName : ""} disabled={true} />
                    <div className="form-group col-sm-5">
                      <label className="label-control w-100"></label>
                      {selectedStudent != null ? (
                        <button className="btn btn-danger" disabled={isView} onClick={() => setSelectedStudent(null)}>
                          <span class="material-icons">autorenew</span>
                        </button>
                      ) : null}
                      <button className="btn btn-info" disabled={isView} onClick={handleStudentSelectModal}>
                        Tələbə seç
                      </button>
                    </div>
                  </>

                  <Select placeholder={"Seç"} style={"col-sm-6"} label={"Qrup"} selectedValue={groupMember?.fkIdGroup} changeSelectHandler={(e) => handleSelect(e, "fkIdGroup")} options={groups.map((group) => ({ label: group?.groupName, value: group?.idGroup }))} isDisabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Başlama tarixi"} name={"startDate"} type={"date"} value={groupMember?.startDate?.split("T")[0]} onChange={handleInput} disabled={isView} />
                  <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} onChange={() => setGroupMember({ ...groupMember, isActive: groupMember.isActive == 1 ? 0 : 1 })} disabled={isView} />
                </div>

                <div className="row align-right">
                  {!isView && (
                    <CommitButton onClick={addOrUpdateGroupMember} />
                  )}
                  <Link to={`${alias}/groupMember`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null}
    </div>
  );
}
