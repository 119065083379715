import React from "react";
import * as util from "../../utils/Util";

export default function TrAttendanceReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.groupName}</td>
      <td>{item?.trainingType}</td>
      <td>{item?.trainerFullName}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.groupPlan}</td>
      <td>{item?.trainingCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalDetailCount}</td>
      <td>{item?.journalDetailParticipateCount}</td>
    </tr>
  ):  idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.groupName}</td>
      <td>{item?.startDate?.split("T")[0]}</td>
      <td>{item?.trainingType}</td>
      <td>{item?.trainerFullName}</td>
      <td>{item?.memberCount}</td>
      <td>{util.FixedNumber(item?.debtCalculation)}</td>
      <td>{util.FixedNumber(item?.paymentSum)}</td>
    </tr>
  ) : null;
}
