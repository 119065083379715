import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as organizationService from "./../../../services/OrganizationService";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import * as Alerts from "./../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import CommitButton from "./../../../components/commitButton/Index";
import defaultImage from "./../../../assets/img/logo.webp";
import { BaseAttachURL, FileRoutes } from "./../../../services/core/Urls";
import * as attachmentService from "./../../../services/AttachmentService";
import FormCheckbox from "../../../components/formCheckbox/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [organization, setOrganization] = useState({});
  const [image, setImage] = useState(defaultImage);
  const [attach, setAttach] = useState();

  const { idOrganization } = useParams();
  const location = useLocation();
  const alias = useOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    idOrganization && getOrganization();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getOrganization = async () => {
    let result = await organizationService.getOrganization(idOrganization);
    setOrganization(result.data);
    result.data?.logo && setImage(BaseAttachURL + FileRoutes.OrganizationRoute + result.data?.logo);
  };

  const addOrUpdateOrganization = async () => {
    console.log(organization);

    let result = idOrganization ? await organizationService.updateOrganization(organization) : await organizationService.addOrganization(organization);
    if (result.success) {

      const formData = new FormData();
      formData.append("fkIdAttachmentSourceType", 8);
      formData.append("fkIdAttachmentSoruce", result.data?.idOrganization);
      formData.append("files", attach);
      await attachmentService.addAttachments(formData, "organizations");

      Alerts.successWithBack(result.message, "/organization", navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setAttach(event.target.files[0]);
    }
  };

  const handleInput = (e) => {    
    setOrganization({ ...organization, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">account_balance</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idOrganization ? (isView ? "Təşkilat məlumatları" : "Təşkilat məlumatlarının redaktəsi") : "Yeni təşkilat"}</h4>

                <form method="#" action="#">
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <span className="btn-file">
                        <div className="thumbnail">
                          <img src={image} style={{ minWidth: 70, maxWidth: 70, maxHeight: 70 }} />
                        </div>
                        <input type="file" accept=".jpg,.jpeg,.png,.tiff" onChange={onImageChange} disabled={isView} />
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group is-empty col-sm-6">
                      <label className="control-label">Təşkilatın adı*</label>
                      <input type="text" className="form-control" name="organizationName" value={organization?.organizationName} onChange={handleInput} disabled={isView} />
                    </div>
                    <div className="form-group   is-empty  col-sm-6">
                      <label className="control-label">Əlaqədar şəxs</label>
                      <input type="text" className="form-control" name="contactPerson" value={organization?.contactPerson} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group is-empty col-sm-6">
                      <label className="control-label">Əlaqə nömrəsi</label>
                      <input type="text" className="form-control" name="phoneNumber" value={organization?.phoneNumber} onChange={handleInput} disabled={isView} />
                    </div>
                    <div className="form-group is-empty  col-sm-6">
                      <label className="control-label">Aylıq ödəniş</label>
                      <input type="number" className="form-control" name="monthlyPayment" value={organization?.monthlyPayment} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group is-empty col-sm-6">
                      <label className="control-label">Ünvan</label>
                      <input type="text" className="form-control" name="address" value={organization?.address} onChange={handleInput} disabled={isView} />
                    </div>
                    <div className="form-group is-empty  col-sm-6">
                      <label className="control-label">Müqavilə</label>
                      <input type="text" className="form-control" name="contract" value={organization?.contract} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group  col-sm-6">
                      <label className="control-label">Qeydlər</label>
                      <input type="text" className="form-control" name="note" value={organization?.note} onChange={handleInput} disabled={isView} />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="control-label">Alias*</label>
                      <input type="text" className="form-control" name="alias" value={organization?.alias} onChange={handleInput} disabled={isView} />
                    </div>
                  </div>

                  <div className="row">
                  <div className="form-group col-sm-6">
                      <label className="control-label">Müştəri kodu</label>
                      <input type="text" className="form-control" name="id" value={organization?.id} onChange={handleInput} disabled={isView} />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="control-label">Ətraflı məlumat</label>
                      <input type="text" className="form-control" name="description" value={organization?.description} onChange={handleInput} disabled={isView} />
                    </div>
                    <FormCheckbox label={"Demo istifadə"} style={"col-sm-12"} name={"isDemo"}  checked={organization?.isDemo == 1 ? true : false} onChange={() => setOrganization({ ...organization, isDemo: organization?.isDemo == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && <CommitButton onClick={addOrUpdateOrganization} />}
                    <Link to={`${alias}/organization`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
