import React, { useState, useEffect, useRef } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import { useOrganization } from "../../helper/useOrganization";
import * as groupService from "./../../services/GroupService";
import * as roomService from "./../../services/RoomService";
import * as employeeService from "./../../services/EmployeeService";
import * as helperService from "./../../services/HelperService";
import Select from "./../../components/select/Index";

import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject, Print } from "@syncfusion/ej2-react-schedule";

export default function Index() {
  const { state, setState } = useMainContext();

  const [groups, setGroups] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [calendarInfos, setCalendarInfos] = useState([]);
  const [calendarInfoFilter, setCalendarInfoFilter] = useState({ GroupId: null, EmployeeId: null, RoomId: null });

  const alias = useOrganization();

  const calendarElement = useRef();

  useEffect(() => {
    getGroups();
    getEmployees();
    getRooms();
    getCalendarInfoByFilter();
  }, [localStorage.branch]);

  useEffect(() => {
    getCalendarInfoByFilter();
  }, [calendarInfoFilter]);

  const getGroups = async () => {
    let result = await groupService.getGroupsByBranch(0, 0, localStorage.branch, {});
    if (result.success) {
      setGroups(result.data.datas);
    } else {
      // Alerts.error(result.message);
    }
  };

  const getCalendarInfoByFilter = async () => {
    console.log(calendarInfoFilter);
    let result = await helperService.getCalendarInfoByFilter(calendarInfoFilter, localStorage.branch);
    if (result.success) {
      setCalendarInfos(result.data);
    } else {
      // Alerts.error(result.message);
    }
  };

  const getRooms = async () => {
    let result = await roomService.getForSelect(localStorage.branch);
    if (result.success) {
      setRooms(result.data);
    } else {
      //  Alerts.error(result.message);
    }
  };

  const getEmployees = async () => {
    let result = await employeeService.getForSelect();
    if (result.success) {
      setEmployees(result.data);
    } else {
      // Alerts.error(result.message);
    }
  };

  const onEventRendered = (args) => {
    let categoryColor = args.data.CategoryColor;
    if (!args.element || !categoryColor) {
      return;
    }
    args.element.style.backgroundColor = categoryColor;
  };

  const handleSelect = (e, name) => {
    setCalendarInfoFilter({ ...calendarInfoFilter, [name]: e.value });
  };

  const printCalendar = () => {
    //console.log(calendarElement);
    //  calendarElement.print();

    var content = document.getElementById("divcontents");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <div className="col-md-12">
      <button className="btn btn-success f-right" onClick={printCalendar}>
        Çap et
      </button>
      <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: "absolute" }}></iframe>

      <div className="card" style={{minHeight:'79vh'}} data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">event</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Təqvim</h4>
          <div className="row">
            <Select placeholder={"Seç"} style={"col-sm-4"} label={"Qruplar"} selectedValue={calendarInfoFilter?.GroupId} changeSelectHandler={(e) => handleSelect(e, "GroupId")} options={groups.map((group) => ({ label: group?.groupName, value: group?.idGroup }))} />
            <Select placeholder={"Seç"} style={"col-sm-4"} label={"Təlimçilər"} selectedValue={calendarInfoFilter?.EmployeeId} changeSelectHandler={(e) => handleSelect(e, "EmployeeId")} options={employees.map((emp) => ({ label: `${emp?.name} ${emp.surname}`, value: emp?.idEmployee }))} />
            <Select placeholder={"Seç"} style={"col-sm-4"} label={"Otaqlar"} selectedValue={calendarInfoFilter?.RoomId} changeSelectHandler={(e) => handleSelect(e, "RoomId")} options={rooms.map((room) => ({ label: room?.roomNumber, value: room?.idRoom }))} />
          </div>

          <div id="divcontents">
            <ScheduleComponent
              ref={calendarElement}
              cellClick={false}
              cellDoubleClick={false}
              //moreEventsClick={false}
              rowAutoHeight={true}
              height={'500'}
              timeFormat={"HH:mm"}
              // dateFormat={"dd.MM.yyyy"}
              eventRendered={onEventRendered}
              currentView="Month"
              
              eventSettings={{
                dataSource: calendarInfos,
              }}
            >
              <Inject services={[Day, Week, Month, Print]} />
            </ScheduleComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
