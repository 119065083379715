import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import * as studentNoteService from "../../../services/StudentNoteService";
import * as helperService from "../../../services/HelperService";
import * as Alerts from "../../../utils/Alerts";
import Select from "../../../components/select/Index";
import FormInput from "../../../components/formInput/Index";
import FormTextareaInput from "../../../components/formTextareaInput/Index";
import CommitButton from "../../../components/commitButton/Index";
import * as date from "../../../utils/DateNow";

export default function AddModal({ isOpen, handleViewModal, studentId, selectedRow }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [studentNote, setStudentNote] = useState({ noteDate: date.Now() });
  const [colors, setColors] = useState([]);

  useEffect(() => {
    getColors();
    if (selectedRow != null && selectedRow != undefined) {
      setStudentNote(selectedRow);
    }
  }, []);

  const getColors = async () => {
    let result = await helperService.getColors(1);
    if (result.success) {
      setColors(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addorUpdateStudentNote = async () => {
    studentNote.fkIdStudent = studentId;
    if (selectedRow != null && selectedRow != undefined) {
      await studentNoteService.updateStudentNote({ ...studentNote, fkIdBranch: localStorage.branch });
    } else {
      await studentNoteService.addStudentNote({ ...studentNote, fkIdBranch: localStorage.branch });
    }
    handleViewModal();
  };

  const handleInput = (e) => {
    setStudentNote({ ...studentNote, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setStudentNote({ ...studentNote, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Tələbə qeydi
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <FormInput style={"col-sm-6"} type={"text"} label={"Başlıq"} name={"title"} required={true} value={studentNote?.title} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Qeyd tarixi"} name={"noteDate"} type={"date"} value={studentNote?.noteDate} onChange={handleInput} disabled={isView} />
                  <FormTextareaInput style={"col-sm-12"} rows={5} label={"Qeyd"} name={"note"} value={studentNote?.note} onChange={handleInput} disabled={isView} />
                  <Select
                    placeholder={"Seç"}
                    style={"col-sm-6"}
                    label={"Rəng"}
                    required={true}
                    selectedValue={studentNote?.color}
                    changeSelectHandler={(e) => handleSelect(e, "color")}
                    options={colors.map((item) => ({
                      label: (
                        <p>
                          {item?.description} <span className="select-color-ribbon" style={{ backgroundColor: item?.code }}></span>
                        </p>
                      ),
                      value: item?.code,
                    }))}
                    isDisabled={isView}
                  />

                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addorUpdateStudentNote} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
