import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as employeeService from "../../../services/EmployeeService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import * as debtCalculationTrainerService from "./../../../services/DebtCalculationTrainerService";
import AddDebtModal from "./../../debtCalculationTrainer/modal/AddModal";
import * as Alerts from "../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, trainerId }) {
  const { state, setState } = useMainContext();
  const [debts, setDebts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenAddDebtModal, setIsOpenAddDebtModal] = useState(false);
  const [selectedDebt, setSelectedDebt] = useState(null);

  useEffect(() => {
    getDebts(1, localStorage.rowCount);
  }, []);

  const getDebts = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await employeeService.getTrainerDebts(pageIndex, pageSize, trainerId);
    if (result.success) {
      setDebts(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    getDebts(pageIndex, localStorage.rowCount);
  };

  const deleteTrainerDebtCalculation = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await debtCalculationTrainerService.deleteDebtCalculationTrainer(id);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getDebts(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const handleAddDebtModal = () => {
    if (isOpenAddDebtModal) getDebts(1, localStorage.rowCount);
    setIsOpenAddDebtModal(!isOpenAddDebtModal);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getDebts(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : debts?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Təlim</th>
                            <th>Müqavilə nömrəsi</th>
                            <th>Borc</th>
                            <th>Hesablanma tarixi</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {debts.map((item, i) => (
                            <tr key={item}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.contractTrainer?.trainingType?.description}</td>
                              <td>{item?.contractTrainer?.contractNo}</td>
                              <td>{item?.debt} AZN</td>
                              <td>{item?.calculationDate?.split("T")[0]}</td>
                              <td className="td-actions text-right">
                                {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "trainerdebt/edit") ? (
                                  <button
                                    title="Redaktə"
                                    className="btn btn-info"
                                    onClick={(e) => {
                                      setSelectedDebt(item);
                                      handleAddDebtModal();
                                    }}
                                  >
                                    <i className="material-icons">edit</i>
                                  </button>
                                ) : null}
                                {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "trainerdebt/delete") ? (
                                  <button
                                    title="Sil"
                                    className="btn btn-danger"
                                    onClick={(e) => {
                                      deleteTrainerDebtCalculation(item?.idDebtCalculationTrainer);
                                    }}
                                  >
                                    <i className="material-icons">delete</i>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "trainerdebt/add") ? (
              <button
                className="btn open-blue"
                onClick={(e) => {
                  handleAddDebtModal(e);
                }}
              >
                <i className="material-icons">paid</i> Yeni borc
              </button>
            ) : null}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenAddDebtModal ? <AddDebtModal isOpen={isOpenAddDebtModal} handleViewModal={handleAddDebtModal} trainerId={trainerId} selectedDebt={selectedDebt} /> : null}
    </div>
  );
}
