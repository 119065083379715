import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import * as departmentService from "./../../../services/DepartmentService";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [department, setDepartment] = useState({});

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idDepartment } = useParams();

  useEffect(() => {
    idDepartment && getDepartment();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getDepartment = async () => {
    let result = await departmentService.getDepartment(idDepartment);
    if (result.success) {
      setDepartment(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateDepartment = async () => {
    let result = idDepartment ? await departmentService.updateDepartment(department) : await departmentService.addDepartment(department);
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/department`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setDepartment({ ...department, [e.target.name]: e.target.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">reduce_capacity</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idDepartment ? (isView ? "Şöbə məlumatları" : "Şöbən məlumatlarının redaktəsi") : "Yeni şöbə"}</h4>
                <form method="#" action="#">
                  <div className="row">
                    <FormInput style={"col-sm-12"} label={"Şöbə adı"} name={"departmentName"} value={department?.departmentName} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-12"} label={"Qeyd"} name={"description"} value={department?.description} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={department?.isActive} onChange={() => setDepartment({ ...department, isActive: department?.isActive == 1 ? 0 : 1 })} disabled={isView} />
                  </div>
                  <div className="row align-right">
                    {!isView && (
                      <CommitButton onClick={addOrUpdateDepartment} />
                    )}
                    <Link to={`${alias}/department`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
