import swal from "./../assets/js/sweetalert2";

export const success = (message) => {
  swal({
    title: "Uğurlu əməliyyat",
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "btn btn-success",
    customClass: "swal-wide",
    type: "success",
    timer: 1500,
    showConfirmButton: false,
  }).catch(swal.noop);
};

export const error = (message) => {
  swal({
    title: "Uğursuz əməliyyat",
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "btn btn-success",
    customClass: "swal-wide",
    type: "error",
    //  timer: 3000,
  }).catch(swal.noop);
};

export const warning = (message) => {
  swal({
    title: "Diqqət",
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "btn btn-success",
    customClass: "swal-wide",
    type: "warning",
    //  timer: 3000,
  }).catch(swal.noop);
};

export const successWithBack = (message, url, navigate) => {
  swal({
    title: "Uğurlu əməliyyat",
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "btn btn-success",
    customClass: "swal-wide",
    type: "success",
    timer: 1500,
    showConfirmButton: false,
  })
    .then(
      function () {
        //window.location.pathname = url;
      },
      function (dismiss) {
        navigate(url);
      }
    )
    .catch(swal.noop);
};

export const error401 = (callback) => {
  swal({
    title: "Diqqət",
    text: "Sessiya müddəti bitdiyi üçün giriş səhifəsinə yönləndiriləcəksiniz",
    buttonsStyling: false,
    confirmButtonClass: "btn btn-warning",
    customClass: "swal-wide",
    type: "warning",
    timer: 3000,
    showConfirmButton: false,
  }).then(function (confirmed) {
    callback(confirmed);
  })
    .catch(swal.noop);
};

export const errorWithBack = (message, url, navigate) => {
  swal({
    title: "Uğursuz əməliyyat",
    text: message,
    buttonsStyling: false,
    confirmButtonClass: "btn btn-success",
    customClass: "swal-wide",
    type: "error",
    // timer: 3000,
  })
    .then(function () {
      navigate(url);
      //window.location = url;
    })
    .catch(swal.noop);
};

export const confirmForChange = (callback) => {
  swal({
    title: "Diqqət",
    text: "Müraciətin uğurlu olub olmaması statusu dəyişiləcək",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Təsdiq et",
    cancelButtonText: "Bağla",
    buttonsStyling: false,
    // onOpen: () => swal.getConfirmButton(),
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};

export const confirmForDelete = (callback) => {
  swal({
    title: "Silinsin?",
    text: "Silinən məlumatı geri qaytarmaq mümkün deyil",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Təsdiq et",
    cancelButtonText: "Bağla",
    buttonsStyling: false,
    // onOpen: () => swal.getConfirmButton(),
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};

export const confirmForNotify = (callback) => {
  swal({
    title: "Diqqət",
    text: "Bildirişləri göndərməyə əminsiniz?",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Təsdiq et",
    cancelButtonText: "Bağla",
    buttonsStyling: false,
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};

export const confirmForStudentDelete = (callback) => {
  swal({
    title: "Silinsin?",
    text: "Tələbə və ona bağlı bütün müqavilələr silinəcək",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Təsdiq et",
    cancelButtonText: "Bağla",
    buttonsStyling: false,
    // onOpen: () => swal.getConfirmButton(),
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};

export const confirmForExit = (callback) => {
  swal({
    title: "Çıxmaq istədiyinizə əminsiniz?",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Bəli",
    cancelButtonText: "Xeyr",
    buttonsStyling: false,
    // onOpen: () => swal.getConfirmButton().focus(),
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};

export const confirmForImport = (callback) => {
  swal({
    title: "İmport",
    text: "Seçilən fayldakı məlumatlar import edilsin?",
    type: "warning",
    showCancelButton: true,
    customClass: "swal-wide",
    confirmButtonClass: "btn btn-success",
    cancelButtonClass: "btn btn-danger",
    confirmButtonText: "Təsdiq et",
    cancelButtonText: "Bağla",
    buttonsStyling: false,
  })
    .then(function (confirmed) {
      callback(confirmed);
    })
    .catch(swal.noop);
};