import React from "react";
import Select from "react-select";

const ReactSelect = ({ placeholder, options, value, selectedValue, changeSelectHandler, className, required, isDisabled, label, style, bgColor, isBranchSelect }) => {
  const styles = {
    option: (provided, state) => ({
      padding: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#26c6da",
        color: "white",
      },
      backgroundColor: state.isFocused && "#26c6da",
      color: state.isFocused && "white",
    }),
    control: (provided) => ({
      ...provided,
      paddingTop: "0.3rem",
      paddingBottom: "0.3rem",
      boxShadow: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      // backgroundColor: bgColor,
    }),
  };

  return (
    <div className={"form-group is-empty " + style}>
      {label != null ? (
        <label className={`control-label ${required && !selectedValue ? "required-red" : ""}`}>
          {label}
          {required && "*"}
        </label>
      ) : null}
      <Select
        placeholder={placeholder}
        options={isBranchSelect ? options : [{ label: "Seç", value: null, disabled: true }, ...options]}
        // options={options}
        value={selectedValue != null ? options.filter((option) => option.value == selectedValue) : value}
        onChange={changeSelectHandler}
        styles={styles}
        className={className}
        isDisabled={isDisabled || false}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />
    </div>
  );
};

export default ReactSelect;
