import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as organizationPaymentService from "./../../services/OrganizationPaymentService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [organizationPayments, setOrganizationPayments] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getOrganizationPayments(state.currentPage, localStorage.rowCount);
  }, []);

  const getOrganizationPayments = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await organizationPaymentService.getOrganizationPayments(pageIndex, pageSize);
    if (result.success) {
      setOrganizationPayments(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteOrganizationPayment = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await organizationPaymentService.deleteOrganizationPayment(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getOrganizationPayments(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getOrganizationPayments(pageIndex, localStorage.rowCount);
  };


  return (
    <div className="col-md-12">
      <button className="btn btn-danger f-right" disabled={selectedRow == 0 ? true : false} onClick={() => deleteOrganizationPayment(selectedRow)}>
        Sil
      </button>
      <Link to={`/organizationPayment/edit`}>
        <button className="btn btn-info f-right" disabled={selectedRow == 0 ? true : false}>
          Redaktə et
        </button>
      </Link>
      <Link to={`/organizationPayment/add`}>
        <button className="btn btn-info f-right">Əlavə et</button>
      </Link>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">payments</i>
        </div>
        <div className="card-content">
          <div>
            <h4 className="card-title">Təşkilat ödənişlərinin siyahısı</h4>
            <PageSizeSelect onChange={(e)=> getOrganizationPayments(PAGE_INDEX, e.value)} />
          </div>
          {loading ? (
            <Loading />
          ) : organizationPayments?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Təşkilatın adı</th>
                    <th>Ödəniş</th>
                    <th>Qeyd</th>
                    <th>Tarix</th>
                  </tr>
                </thead>
                <tbody>
                  {organizationPayments.map((item, i) => (
                    <tr key={item?.idOrganizationPayment} onClick={() => isSelectRow(item?.idOrganizationPayment)} className={selectedRow === item?.idOrganizationPayment ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.organization?.organizationName}</td>
                      <td>{item?.paid}</td>
                      <td>{item?.note}</td>
                      <td>{item?.insertDate?.split(".")[0].replace("T", " ")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
