import React from "react";
import { PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import { useMainContext } from "../../contexts/MainContext";
import { useTokenData } from "./../../helper/useTokenData";
//import Select from "./../select/Index";
import Select from "react-select";
import * as userService from "./../../services/UserService";
import * as sysUserService from "./../../services/SysUserService";

export default function Index({ title, onChange }) {
  const { state, setState } = useMainContext();

  const tokenData = useTokenData();

  const handleSelect = async (e) => {
    localStorage.rowCount = e.value;
    tokenData.isSysUser == "1" ? await sysUserService.changeTableRowCount(e.value) : await userService.changeTableRowCount(e.value);
    onChange(e);
  };

  const styles = {
    option: (provided, state) => ({
      padding: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#26c6da",
        color: "white",
        with: 20,       
      },
      backgroundColor: state.isFocused && "#26c6da",
      color: state.isFocused && "white",      
    }),
    control: (provided) => ({
      ...provided,
      paddingTop: "0.3rem",
      paddingBottom: "0.3rem",
      // boxShadow: "none",
      //  borderTop: "none",
      //  borderLeft: "none",
      //  borderRight: "none",
      borderRadius: 0,
      width: "auto",
      //marginLeft:20,
      textAlign: "center",
      fontSize: 16,
      fontWeight: "400",
      borderColor: "#f2f2f2f2",
      borderBottomColor: "#cdcdcd",
      // backgroundColor: "white",
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  return (
    <div style={{ width: 100 }}>
      <Select
        placeholder={"sətir sayı"}
        options={PAGE_SIZE_COUNTS.map((size) => ({ label: size, value: size }))}
        value={{ label: localStorage.rowCount, value: localStorage.rowCount }}
        onChange={handleSelect}
        styles={styles}
        //  className={className}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        menuPortalTarget={document.body} 
      />

      {/* <Select style={"col-sm-2"}  changeSelectHandler={onChange} selectedValue={localStorage.rowCount} options={PAGE_SIZE_COUNTS.map((size) => ({ label: size, value: size }))} /> */}
    </div>
  );
}
