import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../../contexts/MainContext";
import Pagination from "../../../../components/pagination/Index";
import * as contractService from "../../../../services/ContractService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../../constants/Pagination";
import Loading from "../../../../components/loading/Index";
import NotFound from "../../../../components/notfound/Index";
import PageSizeSelect from "../../../../components/pageSizeSelect/Index";
import { URL } from "../../../../services/core/Urls";
import * as Alerts from "../../../../utils/Alerts";
import ContractTrainerAddModal from "./../../../contract/trainer/modal/ContractTrainerAddModal";

export default function ListModal({ isOpen, handleViewModal, header, trainerId }) {
  const { state, setState } = useMainContext();
  const [contractTrainers, setContractTrainers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenContractTrainerAddModal, setIsOpenContractTrainerAddModal] = useState(false);

  useEffect(() => {
    getContractTrainers(1, localStorage.rowCount);
  }, []);

  const getContractTrainers = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await contractService.getContractTrainersByTrainer(pageIndex, pageSize, trainerId);
    if (result.success) {
      setContractTrainers(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleContractTrainerAddModal = () => {
    isOpenContractTrainerAddModal && getContractTrainers(state.currentPage, localStorage.rowCount);
    setIsOpenContractTrainerAddModal(!isOpenContractTrainerAddModal);
  };

  const changePage = (pageIndex) => {
    getContractTrainers(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog"  aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getContractTrainers(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : contractTrainers?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Müqavilə tarixi</th>
                            <th>Təlim növü</th>
                            <th>Məbləğ</th>
                            <th>Endirim</th>
                            <th>Müqavilə statusu</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {contractTrainers.map((item, i) => (
                            <tr key={item?.idContractTrainer}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.contractDate?.split("T")[0]}</td>
                              <td>{item?.trainingType?.description}</td>
                              <td>{item?.contractAmount ?? 0} ₼</td>
                              <td>{item?.discountAmount ?? 0} ₼</td>
                              <td>
                                <span className={`contract-status-${item?.contractStatus?.idContractStatus}`}>{item?.contractStatus?.description}</span>
                              </td>
                              <td></td>
                              <td></td>
                              <td className="td-actions text-right">
                                <button className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/contractTrainers/downloadAll/11/${item.idContractTrainer}`)}>
                                  <i className="material-icons">download</i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
          <button
              className="btn open-blue"
              onClick={(e) => {
               // setSelectedRow(item?.idStudent);
                handleContractTrainerAddModal(e);
              }}
            >
              <i className="material-icons">receipt_long</i> Yeni müqavilə
            </button>
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenContractTrainerAddModal ? <ContractTrainerAddModal isOpen={isOpenContractTrainerAddModal} handleViewModal={handleContractTrainerAddModal} trainerId={trainerId}  /> : null}

    </div>
  );
}
