import { get, post, put, deleteData } from "./core/Requests";

export const getOrganizations = async (pageIndex, pageSize) => {
  return await get(`/Organization`, { pageIndex, pageSize });
};

export const getOrganization = async (id) => {
  return await get(`/Organization/${id}`);
};

export const getOrganizationByUser = async () => {
  return await get(`/Organization/GetByUser`);
};

export const getOrganizationForSelect = async () => {
  return await get(`/Organization/GetOrganizationForSelect`);
};

export const addOrganization = async (data) => {
  return await post(`/Organization`, data);
};

export const updateOrganization = async (data) => {
    return await put(`/Organization`, data);
  };

  export const addLogo = async (data, idOrganization) => {
    return await post(`/Organization/logo/${idOrganization}`, data);
  };

export const deleteOrganization = async (id) => {
  return await deleteData(`/Organization/${id}`);
};

export const getOrg = async () => {
  return await get(`/Organization/org`);
};

export const checkAlias = async (alias) => {
  return await get(`/Organization/checkAlias?alias=${alias}`);
};