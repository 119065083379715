import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as debtCalculationStudentService from "./../../../services/DebtCalculationStudentService";
import * as groupService from "./../../../services/GroupService";
import * as contractService from "./../../../services/ContractService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormTextareaInput from "./../../../components/formTextareaInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";
import { useStudent } from "../../../helper/useStudent";

export default function AddModal({ isOpen, handleViewModal, studentId, selectedDebt }) {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [studentDebtCalculation, setStudentDebtCalculation] = useState({ calculationDate: date.Now() });
  const [studentContracts, setStudentContracts] = useState([]);

  useEffect(() => {
    getStudenContracts();
    if (selectedDebt != null && selectedDebt != undefined) {
      selectedDebt.fkIdContractStudent = selectedDebt?.contractStudent?.idContractStudent;
      setStudentDebtCalculation(selectedDebt);
    }
  }, []);

  const getStudenContracts = async () => {
    let result = await contractService.getContractStudentsByStudent(0, 0, studentId);
    if (result.success) {
      setStudentContracts(result.data?.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const addDebtCalculationStudent = async () => {
    studentDebtCalculation.fkIdStudent = studentId;
    if (selectedDebt != null && selectedDebt != undefined) {
      await debtCalculationStudentService.updateDebtCalculationStudent({ ...studentDebtCalculation, fkIdBranch: localStorage.branch });
    } else {
      await debtCalculationStudentService.addDebtCalculationStudent({ ...studentDebtCalculation, fkIdBranch: localStorage.branch });
    }
    handleViewModal();
  };

  const handleInput = (e) => {
    setStudentDebtCalculation({ ...studentDebtCalculation, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setStudentDebtCalculation({ ...studentDebtCalculation, [name]: e.value });
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} role="dialog" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Yeni tələbə borcu
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <Select placeholder={"Seç"} style={"col-sm-12"} label={"Tələbə müqaviləsi"} required={true} selectedValue={studentDebtCalculation?.fkIdContractStudent} changeSelectHandler={(e) => handleSelect(e, "fkIdContractStudent")} options={studentContracts.map((item) => ({ label: item?.contractNo, value: item?.idContractStudent }))} isDisabled={isView} />
                  <FormInput style={"col-sm-6"} type={"number"} label={"Borc"} name={"debt"} required={true} value={studentDebtCalculation?.debt} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Borc tarixi"} required={true} name={"calculationDate"} type={"date"} value={studentDebtCalculation?.calculationDate} onChange={handleInput} disabled={isView} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isView && <CommitButton onClick={addDebtCalculationStudent} text={"Yadda saxla"} />}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
