import { get, post, put, deleteData } from "./core/Requests";

export const getJuridicalPersons = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/JuridicalPerson`, { pageIndex, pageSize });
};

export const getJuridicalPersonsByBranch = async ( pageIndex = 0, pageSize = 0, branchId, filter) => {
  return await post(`/JuridicalPerson/getByBranch/${branchId}`, filter, { pageIndex, pageSize });
};


export const getJuridicalPerson = async (id) => {
  return await get(`/JuridicalPerson/${id}`);
};

export const addJuridicalPerson = async (data) => {
  return await post(`/JuridicalPerson`, data);
};

export const updateJuridicalPerson = async (data) => {
  return await put(`/JuridicalPerson`, data);
};

export const deleteJuridicalPerson = async (id) => {
  return await deleteData(`/JuridicalPerson/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/JuridicalPerson/changeActive/${id}`);
};
