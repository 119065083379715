import React, { useState, useEffect } from "react";
import { useMainContext } from "../../../contexts/MainContext";
import Pagination from "../../../components/pagination/Index";
import * as contractService from "../../../services/ContractService";
import Loading from "../../../components/loading/Index";
import NotFound from "../../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import * as Alerts from "../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../../components/pageSizeSelect/Index";
import { URL } from "../../../services/core/Urls";
import { useTranslation } from "react-i18next";
import { useTokenData } from "../../../helper/useTokenData";
import { useStudent } from "../../../helper/useStudent";
import ActionButtons from "./../../../components/actionButton/Index";
import * as UserTypes from "./../../../constants/UserType";

export default function Index() {
  const { state, setState } = useMainContext();
  const [contractTrainers, setContractTrainers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();
  const { t, i18n } = useTranslation();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getContractTrainers(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getContractTrainers = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await contractService.getContractTrainersByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setContractTrainers(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteContractTrainer = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await contractService.deleteContractTrainer(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getContractTrainers(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getContractTrainers(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getContractTrainers(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`contract/trainer/delete`} editPath={`contract/trainer/edit`} addPath={`contract/trainer/add`} exportPath={`contract/trainer/export`} deleteRow={deleteContractTrainer} selectedRow={selectedRow} />}
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">receipt_long</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{t("contract.trainerContractList")}</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getContractTrainers(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : contractTrainers?.length > 0 ? (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>{t("contract.trainerFullname")}</th>
                      <th>{t("contract.contractDate")}</th>
                      <th>{t("contract.trainingType")}</th>
                      <th>Müqavilə nömrəsi</th>
                      <th>{t("contract.contractStatus")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {contractTrainers.map((item, i) => (
                      <tr key={item?.idContractTrainer} onClick={() => isSelectRow(item?.idContractTrainer)} className={selectedRow === item?.idContractTrainer ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.trainer?.fullName}</td>
                        <td>{item?.contractDate?.split("T")[0]}</td>
                        <td>{item?.trainingType?.description}</td>
                        <td>{item?.contractNo}</td>
                        <td>
                          <span className={`contract-status-${item?.contractStatus?.idContractStatus}`}>{item?.contractStatus?.description}</span>
                        </td>
                        <td className="td-actions text-right">
                          {item?.isExsistAttachment && (
                            <button className="btn btn-warning" onClick={() => (window.location = URL.BaseURL + `/file/contractTrainers/downloadAll/9/${item.idContractTrainer}`)}>
                              <i className="material-icons">download</i>
                            </button>
                          )}
                          <Link to={`${alias}${id}/contract/trainer/view/${item?.idContractTrainer}`}>
                            <button className="btn btn-info">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} rowText={t("tableRow")} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
