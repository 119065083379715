import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as trainingMaterialService from "./../../../services/TrainingMaterialService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";

import * as Alerts from "./../../../utils/Alerts";

export default function TraininMaterialListModal({ isOpen, handleViewModal, groupId }) {
  const { state, setState } = useMainContext();
  const [trainingMaterials, setTrainingMaterials] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTrainingMaterials(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getTrainingMaterials = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await trainingMaterialService.getTrainingMaterialsByBranch(localStorage.branch, pageIndex, pageSize, { idGroup: groupId });
    if (result.success) {
      setTrainingMaterials(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const changePage = (pageIndex) => {
    getTrainingMaterials(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }}>
              Təlim materialları
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getTrainingMaterials(PAGE_INDEX, e.value)} />

                  {loading ? (
                    <Loading />
                  ) : trainingMaterials?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>Qrup</th>
                            <th>Başlıq</th>
                            <th>Tarix</th>
                            <th>Fayl sayı</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trainingMaterials.map((item, i) => (
                            <tr key={item?.idTrainingMaterial}>
                              <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                              <td>{item?.group}</td>
                              <td>{item?.title}</td>
                              <td>{item?.trainingMaterialDate}</td>
                              <td>{item?.fileCount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
