import { get, post, put, deleteData } from "./core/Requests";

export const getBranchs = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Branch`, { pageIndex, pageSize });
};

export const getBranchsByOrganization = async (pageIndex, pageSize) => {
  return await get(`/Branch/getByOrganization`, {
    pageIndex,
    pageSize,
  });
};

export const getBranchsByOrganizationForSysUser = async (pageIndex, pageSize, organizationId) => {
  return await get(`/Branch/getByOrganizationForSysUser/${organizationId}`, {
    pageIndex,
    pageSize,
  });
};

export const getBranchsByOrganizationForSelect = async (organizationId) => {
  return await get(`/Branch/getByOrganizationForSelect/${organizationId}`);
};

export const getBranch = async (id) => {
  return await get(`/Branch/${id}`);
};

export const addBranch = async (data) => {
  return await post(`/Branch`, data);
};

export const updateBranch = async (data) => {
  return await put(`/Branch`, data);
};

export const deleteBranch = async (id) => {
  return await deleteData(`/Branch/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Branch/changeActive/${id}`);
};

export const isActiveParentInfoAdd = async (id) => {
  return await get(`/Branch/isActiveParentInfoAdd/${id}`);
};

export const updateBranchSettings = async (data, id) => {
  return await put(`/Branch/settings/${id}`,data);
};

export const getBranchSettings = async (id) => {
  return await get(`/Branch/settings/${id}`);
};

export const getNotifyTemplates = async (branchId) => {
  return await get(`/Branch/notifyTemplate/${branchId}`);
};

export const changeSmsEnable = async (id) => {
  return await get(`/Branch/changeNotifySms/${id}`);
};

export const changeEmailEnable = async (id) => {
  return await get(`/Branch/changeNotifyEmail/${id}`);
};